import React, { createContext, useContext } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children, session, appliedTargetSelection, sites, newest_encounter_date, handleSetPDFReferences, handleNavChange }) => {
  // Just pass the props through to the context without managing state here
  return (
    <AppContext.Provider value={{ session, appliedTargetSelection, sites, newest_encounter_date, handleSetPDFReferences, handleNavChange }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);