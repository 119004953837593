import Plot from 'react-plotly.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardContent, Box, Tooltip } from '@mui/material';
import Flex from '../_global/Flex';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import no_data_img from '../_assets/no_data.png';
import Legend from './Legend';
import { upperCaseFirst, surgicalMethodAlias } from '../helpers';
import { colorMap } from '../theme.js';

const titleMap = {
    procedure_time_avg: 'Average Procedure Time (mins)',
    los_avg: 'Average Length of Stay (days)',
    readmissions_percentage: 'Percentage of Readmission (%)',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge (%)'
};

const cardTitleMap = {
    procedure_time_avg: 'Average Procedure Time',
    los_avg: 'Average Length of Stay',
    readmissions_percentage: 'Percentage of Readmission',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge'
};


export default function ({ 
    loading, 
    appliedTargetSelection: ats, 
    data, 
    volumes
}) {    
    const [chartData, setChartData] = useState(null);
    const [maxRange, setMaxRange] = useState(0);

    useEffect(() => {
        if (!data) return;
        if (ats.comparison_group_uuids.length > 0 && ats.target_type === 'site') return buildMultipleChartComparison({ 
            overview_aggregation: data.overview_aggregation, 
            site_uuid_individual: ats?.target_uuid, 
            outcome: ats?.outcome 
        });
        return buildIndividualChartComparison({ ets: data.ets, outcome: ats?.outcome });
    }, [data, ats]);
 

    function buildIndividualChartComparison({ ets, outcome }) {
        if (!ets) return;
        const fixed = outcome === 'procedure_time_avg' ? 0 : 1;
        const outcomeModifier = ['readmissions_percentage', 'non_home_discharge_percentage'].includes(outcome) ? 100 : 1;
        const y = outcome === 'procedure_time_avg' ? {
            individual: {
                robotic: (ets.individual.robotic[outcome] * outcomeModifier).toFixed(fixed),
                laparoscopic: (ets.individual.laparoscopic[outcome] * outcomeModifier).toFixed(fixed),
                open: (ets.individual.open[outcome] * outcomeModifier).toFixed(fixed),
                other: (ets.individual.other[outcome] * outcomeModifier).toFixed(fixed),
            },
            population: {
                robotic: (ets.population.robotic[outcome] * outcomeModifier).toFixed(fixed),
                laparoscopic: (ets.population.laparoscopic[outcome] * outcomeModifier).toFixed(fixed),
                open: (ets.population.open[outcome] * outcomeModifier).toFixed(fixed),
                other: (ets.population.other[outcome] * outcomeModifier).toFixed(fixed),
            }
        } : {
            individual: {
                robotic: (ets.individual.robotic?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                laparoscopic: (ets.individual.laparoscopic?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                open: (ets.individual.open?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                other: (ets.individual.other?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
            },
            population: {
                robotic: (ets.population.robotic?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                laparoscopic: (ets.population.laparoscopic?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                open: (ets.population.open?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
                other: (ets.population.other?.inpatient[outcome] * outcomeModifier).toFixed(fixed),
            }
        }
        let maxY = 0;
        Object.values(y).forEach(scope => 
            Object.values(scope).forEach(sm => {
                if (parseFloat(sm) > parseFloat(maxY)) maxY = sm;
            })
        );
        setMaxRange(maxY);
        if (ats?.surgical_method === 'all') {
            setChartData([
                buildBarPair('Robotic', outcome,
                    { 
                        x: [ats?.target_name, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'], 
                        y: [y.individual.robotic, y.population.robotic],
                        colors: [colorMap.individual.robotic, colorMap.population.robotic]
                    }
                ),
                buildBarPair(surgicalMethodAlias(ats?.service_line, 'laparoscopic'), outcome,
                    { 
                        x: [ats?.target_name, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'], 
                        y: [y.individual.laparoscopic, y.population.laparoscopic],
                        colors: [colorMap.individual.laparoscopic, colorMap.population.laparoscopic]
                    }
                ),
                buildBarPair('Open', outcome,
                    { 
                        x: [ats?.target_name, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'], 
                        y: [y.individual.open, y.population.open],
                        colors: [colorMap.individual.open, colorMap.population.open]
                    }
                ),
                buildBarPair('Other', outcome,
                    { 
                        x: [ats?.target_name, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'], 
                        y: [y.individual.other, y.population.other],
                        colors: [colorMap.individual.other, colorMap.population.other]
                    }
                )
            ]);
        } else {
            const sm = surgicalMethodAlias(ats?.service_line, ats?.surgical_method);
            setChartData([
                buildBarPair(sm, outcome,
                    { 
                        x: [ats?.target_name],           
                        y: [y.individual[ats?.surgical_method]], 
                        colors: [colorMap.individual[ats?.surgical_method]]         
                    }
                ),
                buildBarPair(sm, outcome,
                    { 
                        x: [upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'],           
                        y: [y.population[ats?.surgical_method]], 
                        colors: [colorMap.population[ats?.surgical_method]]         
                    }
                ),
            ]);
        }
    }

    function buildMultipleChartComparison({ outcome, overview_aggregation, site_uuid_individual }) {
        if (outcome === 'non_home_discharge_percentage') outcome = 'non_home_discharge'; // Name mismatch between endpoints
        if (!overview_aggregation || overview_aggregation.length === 0) return;
        let maxY = 1;
        const x = {};
        const y = {};
        let targetIndex = null;
        overview_aggregation.some(({ site_uuid }, i) => {
            if (site_uuid === site_uuid_individual) {
                targetIndex = i;
                return true;
            }
            return false;
        });
        const legendVolumes = {};
        const target = [...overview_aggregation].splice(targetIndex, 1);
        legendVolumes.individual = target[0].values;
        overview_aggregation
            .sort(a => a.site_name === target[0].site_name ? -1 : 1)
            .forEach(({ site_name: targetName, values }) => {
                Object.entries(values).map(([sm, valueObj]) => {
                    /* los_avg, procedure_time_avg, readmissions_avg, non_home_discharge */
                    if (ats?.surgical_method !== 'all' && ats?.surgical_method !== sm) return;
                    if (!x[sm]) x[sm] = [];
                    if (!y[sm]) y[sm] = [];
                    x[sm].push(targetName);
                    y[sm].push(valueObj[outcome] || 0);
                    if (parseFloat(valueObj[outcome]) > parseFloat(maxY)) maxY = valueObj[outcome];
                });
            });
        setMaxRange(maxY);
        const fixed = outcome === 'procedure_time_avg' ? 0 : 1;
        if (ats?.surgical_method === 'all') {
            setChartData([
                buildBarPair('Robotic', outcome,
                    { 
                        x: x.robotic || 0, 
                        y: y.robotic.map(yVal => yVal.toFixed(fixed)) || 0,
                        colors: x.robotic.map((v, i) => i === 0 ? colorMap.individual.robotic : colorMap.population.robotic) 
                    }
                ),
                buildBarPair(surgicalMethodAlias(ats?.service_line, 'laparoscopic'), outcome,
                    { 
                        x: x.laparoscopic || 0, 
                        y: y.laparoscopic.map(yVal => yVal.toFixed(fixed)) || 0,
                        colors: x.laparoscopic.map((v, i) => i === 0 ? colorMap.individual.laparoscopic : colorMap.population.laparoscopic) 
                    }
                ),
                buildBarPair('Open', outcome,
                    { 
                        x: x.open || 0, 
                        y: y.open.map(yVal => yVal.toFixed(fixed)) || 0,
                        colors: x.open.map((v, i) => i === 0 ? colorMap.individual.open : colorMap.population.open) 
                    }
                ),
                buildBarPair('Other', outcome,
                    { 
                        x: x.other || 0, 
                        y: y.other.map(yVal => yVal.toFixed(fixed)) || 0,
                        colors: x.other.map((v, i) => i === 0 ? colorMap.individual.other : colorMap.population.other) 
                    }
                ),
            ]);
        } else {
            setChartData([
                buildBarPair(surgicalMethodAlias(ats?.service_line, ats?.surgical_method), outcome,
                    { 
                        x: x[ats?.surgical_method] || 0, 
                        y: y[ats?.surgical_method].map(yVal => yVal.toFixed(fixed)) || 0,
                        colors: x[ats?.surgical_method].map((v, i) => i === 0 ? colorMap.individual[ats?.surgical_method] : colorMap.population[ats?.surgical_method]) 
                    }
                ),
            ])
        }
    }

    function buildBarPair(name, outcome, { x, y, colors }) {
        return {
            x,
            y,
            type: 'bar',
            width: .2,
            marker: { color: colors },
            name,
            // hovertext: y.map((yval, i) => `${name}<br>${outcomeNicenameMap[outcome]}: ${yval.toFixed(fixed)}`),
            // hoverinfo: 'text',
            text: y,
            textposition: 'outside',
            textfont: { size: 10, color: 'black' }  
        }
    }
    
    return (
        <Card style={styles.card}>
            <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '.5vw', overflow: 'hidden' }}>
                <Flex jcsb style={{ flexWrap: 'wrap' }}>
                    <Flex>
                        <div style={{ height: '20px', fontWeight: 'bold' }}>{cardTitleMap[ats?.outcome]}</div>
                        {ats?.outcome === 'procedure_time_avg' && <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
                    </Flex>                    
                    {/* <div>{service_line} {dateString}</div> */}
                </Flex>
                {/* WEB LAYOUT */}
                <Box sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '-70px', marginRight: '-100px', justifyContent: 'center' }}>
                    {loading ? <LoadingChart /> : 
                        <Chart 
                            data={chartData} 
                            maxRangeY={maxRange} 
                            isMobile={false} 
                            yTitle={titleMap[ats?.outcome]} 
                        />
                        }
                </Box>
                {/* MOBILE LAYOUT */}
                <Box sx={{ display: { xs: 'flex', md: 'none' }, marginTop: '-70px', marginRight: '-100px', justifyContent: 'center' }}>
                    {loading ? <LoadingChart /> : 
                        <Chart 
                            data={chartData} 
                            maxRangeY={maxRange} 
                            isMobile={true} 
                            yTitle={titleMap[ats?.outcome]} 
                        />
                    }
                </Box>
                <Legend loading={loading} volumes={volumes} appliedTargetSelection={ats}></Legend>
            </CardContent>
        </Card>
    )
}

function LoadingChart() {
    return (
        <Flex f={1} alss aic style={{ width: 700, height: 320, marginTop: 80 }}>
            <Flex className={'load-placeholder'} style={{ width: 20, height: 180, marginRight: 10, marginBottom: 20 }} />
            <Flex column aic style={{ width: 600, height: 250 }}>
                <Flex className={'load-placeholder'} f={1} alss/>
                <Flex style={{ width: 200, height: 20, marginTop: 10 }} />
            </Flex>
        </Flex>
    );
}

function Chart({ data, isMobile, yTitle, maxRangeY }) {
    if (!data) return <Flex column jcc aic style={{ width: 700, height: 330, fontSize: 20, marginTop: '70px', marginRight: '100px' }}>
        <span style={{ marginBottom: 20 }}>No data for current configuration</span>
        <img src={no_data_img} style={{ height: '50%', width: 'auto', opacity: .5 }} alt="no_data" />
    </Flex>
    return <Plot 
        data={data} 
        layout={{   
            barmode: 'group',
            bargroupgap: 0.2,
            bargap: 0.2,
            font: {
                size: isMobile ? 9 : 10
            },
            width: 700,
            height: 400,
            tickfont: {
                family: 'Roboto',
                size: isMobile ? 8 : 10,
            },
            xaxis: {
            },
            yaxis: {
                title: yTitle,
                range: [0, parseFloat(maxRangeY) + parseFloat(maxRangeY) * .2],
            },
            hovermode: false,
            showlegend: false,
            plot_bgcolor: 'rgba(0,0,0,0)',
            paper_bgcolor: 'rgba(0,0,0,0)',
        }} 
        config={{ displayModeBar: false }} 
        style={{ transform: 'translate(-35px, 0px)' }}
    />
}

const styles = {
    card: { 
        flex: 1,
        margin: '.5vw', 
        fontSize: '12px', 
        flexWrap: 'wrap', 
        overflowWrap: 'normal',
        minWidth: 650,
        maxWidth: 750
    },
    circle: {
        width: '11px',
        height: '11px',
        borderRadius: '50%'
    },
    legendText: {
        marginLeft: 3
    }
}