import { useEffect, useState } from 'react'
import { cacheAppliedTargetSelection, getCachedAppliedTargetSelection, clearCache } from './_api/sessions.js';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import analytics from './_api/analytics';
import Header from './_global/Header.js'
import api from './_api/api.js';
import Flex from './_global/Flex.js';
import { getDateRange } from './helpers.js';
import FilterMenu from './1_filter_menu/FilterMenu.js';
import LandingPage from './0_landing/Page.js';
import Demographics from './2_demographics/Page.js';
import DownloadPDF from './_global/DownloadPDF.js';
import { AppProvider } from './AppContext';
import PracticeOverview from './3_practice_overview/Page.js';
import SurgeonOverview from './4_surgeon_overview/Page.js';
import ProcedureOverview from './5_procedure_overview/Page.js';
import RiskAnalysis from './6_risk_analysis/Page.js';

const specialtyNicenameMap = {
  general_surgery: 'General Surgery',
  gynecology: 'OB/GYN',
  urology: 'Urology',
  thoracic: 'Thoracic'
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/demographics",
    element: <Demographics />,
  },
  {
    path: "/procedures",
    element: <ProcedureOverview />,
  },
  {
    path: "/surgeons",
    element: <SurgeonOverview />,
  },
  {
    path: "/outcomes",
    element: <PracticeOverview />,
  },
  {
    path: "/risk",
    element: <RiskAnalysis />,
  },
]);

export default ({ session, handleToggleDataReady, logout }) => {
  const [page, setPage] = useState('Home');
  const [sites, setSites] = useState(null);
  const [maxRangeDates, setMaxRangeDates] = useState(null);
  const [appliedTargetSelection, setAppliedTargetSelection] = useState({ 
    target_type: 'site',
    target_uuid: null,
    target_name: null,
    surgeon_sites: [],
    surgeon_states: [],
    procedure_identifiers: [],
    comparison_group_uuids: [],
    comparison_group_name: null,
    encounter_type: null,
    encounter_type_identifier: 'ALL',
    admit_type: null, // dictated by encounter_type_identifier
    service_line: null, 
    surgical_method: undefined,
    model_name: 'thirty_day_readmission', // risk analysis only
    outcome: 'procedure_time_avg',         //practice over view only
    timeframe: 'prev_quarter',
    start_date: null,
    end_date: null
  });
  const [PDFReferences, setPDFReferences] = useState([]);

  useEffect(() => {
    (async function () {
      if (session.role_access === 'global') await initializeGlobal();
      else if (session.role_access === 'personal') await initializePersonal();
      else {
        console.log('Error resolving access');
        logout();
      }
      analytics.initialize(session);
      handleToggleDataReady(true);
    }());
  }, []);

  async function initializeGlobal() {
    try {
      const maxRangeDatesResponse = await api.getMaxRangeDates();
      const [year, month] = maxRangeDatesResponse.end_date.split('-');
      setMaxRangeDates(maxRangeDatesResponse);
      const siteRes = await api.getSitesV2();
      if (!siteRes || siteRes.length === 0) throw Error('Error getting inital hospitals');
      setSites(siteRes);
      const [{ name: site_name, nicename: site_nicename, uuid: target_uuid }] = siteRes;
      const cached = getCachedAppliedTargetSelection();
      if (cached) return setAppliedTargetSelection({
        ...cached,
        start_date: `${year}-${month}-01`,
        end_date: maxRangeDatesResponse.end_date
      });
      setAppliedTargetSelection(prev => ({
        ...prev,
        target_type: 'site',
        target_uuid,
        target_name: site_nicename || site_name,
        service_line: Object.keys(specialtyNicenameMap).find(s => siteRes[0].service_lines.includes(s)),
        start_date: `${year}-${month}-01`,
        end_date: maxRangeDatesResponse.end_date
      }));
    } catch(err) {
      console.log('Error in initialize', err);
      await logout();
      clearCache();
    }
  }

  async function initializePersonal() {
    try {
      const surgeonInfo = await api.getPersonalInfo();
      const maxRangeDatesResponse = await api.getMaxRangeDates();
      const [year, month] = maxRangeDatesResponse.end_date.split('-');
      setMaxRangeDates(maxRangeDatesResponse);
      const siteRes = await api.getSitesV2();
      setSites(siteRes);
      const [{ name: site_name, nicename: site_nicename, uuid: target_uuid }] = siteRes;
      const { start_date, end_date } = getDateRange(maxRangeDatesResponse.end_date, appliedTargetSelection.timeframe);
      const cached = getCachedAppliedTargetSelection();
      if (cached) return setAppliedTargetSelection({
        ...cached,
        start_date: `${year}-${month}-01`,
        end_date: maxRangeDatesResponse.end_date
      });
      setAppliedTargetSelection(prev => ({
        ...prev,
        target_type: 'site',
        target_uuid,
        target_name: site_nicename || site_name,
        surgeon_sites: surgeonInfo.site_uuids,
        surgeon_states: surgeonInfo.site_states,
        service_line: surgeonInfo.service_line,
        comparison_group_name: 'department',
        start_date,
        end_date    
      }));
    } catch(err) {
      console.log('Error in initialize', err);
      await logout();
      clearCache();
    }
  }

  function handleSetAppliedTargetSelection(stagedSelection) {
    if (stagedSelection.timeframe || stagedSelection.start_date || stagedSelection.end_date) stagedSelection = handleNewTimeframe(stagedSelection);
    const newApplied = {  ...appliedTargetSelection, ...stagedSelection };
    analytics.newEvent({ category: 'Apply', action: 'Applied selection', label: newApplied });
    setAppliedTargetSelection(prev => ({ ...prev, ...newApplied }));
    cacheAppliedTargetSelection({ ...appliedTargetSelection, ...newApplied });
  }

  function handleNewTimeframe(stagedSelection) {
    const resolvedDateRange = {};
    const { start_date, end_date } = getDateRange(maxRangeDates.end_date, stagedSelection.timeframe, { start_date: stagedSelection.start_date, end_date: stagedSelection.end_date });
    resolvedDateRange.start_date = start_date;
    resolvedDateRange.end_date = end_date;
    return { ...stagedSelection, ...resolvedDateRange };
  }

  async function handleNavChange(page_name) {
    analytics.changedPage(page_name);
    if (['Practice Overview', 'General Analysis'].includes(page_name)) handleSetAppliedTargetSelection({ surgical_method: 'all' });
    if (['Risk Analysis'].includes(page_name)) handleSetAppliedTargetSelection({ surgical_method: 'robotic' });
    if (['Surgeon Overview'].includes(page_name)) handleSetAppliedTargetSelection({ surgical_method: undefined });
    setPage(page_name);
  }

  async function handleLogout() {
    await api.logout();
    logout();
  }

  function handleSetPDFReferences(refs) {
    setPDFReferences(refs);
  }

  if (!appliedTargetSelection.target_uuid) return null;
  return (
    <>
      <Flex column style={{ width: '100vw', position: 'relative' }} f={1} alss>
        <Header page={page} sites={sites} role_access={session.role_access} appliedTargetSelection={appliedTargetSelection} handleLogout={handleLogout} handleNavChange={handleNavChange} />
        <Flex row alss f={1} style={{ position: 'relative' }}>
          <FilterMenu
            role_access={session.role_access}
            page={page} 
            sites={sites} 
            specialtyNicenameMap={specialtyNicenameMap} 
            appliedTargetSelection={appliedTargetSelection} 
            maxRangeDates={maxRangeDates}
            handleSetAppliedTargetSelection={handleSetAppliedTargetSelection}
          />

          <AppProvider 
            session={session} 
            appliedTargetSelection={appliedTargetSelection} 
            sites={sites} 
            newest_encounter_date={maxRangeDates.end_date}
            handleSetPDFReferences={handleSetPDFReferences}
            handleNavChange={handleNavChange}
          >
            {['Home'].includes(page) && <LandingPage />}
            {['Demographics'].includes(page) && <Demographics />}
            {['General Analysis', 'Practice Overview'].includes(page) && <PracticeOverview />}
            {['Surgeon Overview'].includes(page) && <SurgeonOverview />}
            {['Procedure Tables'].includes(page) && <ProcedureOverview />}
            {['Risk Analysis'].includes(page) && <RiskAnalysis />}
          </AppProvider>

          <DownloadPDF
            appliedTargetSelection={appliedTargetSelection} 
            page={page} 
            references={PDFReferences} 
          />
        </Flex>
      </Flex>
    </>
  );
}


/*
          <Flex row alss f={1} style={{ position: 'relative' }}>
            {page === 'Demographics' && <Demographics session={session} sites={sites} appliedTargetSelection={appliedTargetSelection} handleSetPDFReferences={handleSetPDFReferences}/>}
            {page === 'Risk Analysis' && <RiskAnalysis session={session} sites={sites} appliedTargetSelection={appliedTargetSelection} handleSetPDFReferences={handleSetPDFReferences}/>}
            {['General Analysis', 'Practice Overview'].includes(page) && <PracticeOverview session={session} sites={sites} appliedTargetSelection={appliedTargetSelection} handleSetPDFReferences={handleSetPDFReferences}/>}
            {page === 'Procedure Tables' && <ProcedureOverview session={session} sites={sites} appliedTargetSelection={appliedTargetSelection} />}
            {page === 'Surgeon Overview' && <SurgeonOverview session={session} sites={sites} appliedTargetSelection={appliedTargetSelection} />}
          </Flex>
*/