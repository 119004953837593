import { useState, useEffect } from 'react';
import Flex from '../_global/Flex.js';
import { Card, CardContent, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { upperCaseFirst } from '../helpers.js';

const roomForWrapping = '28px';

const comorbidityTrimMap = {
  'Peripheral Vascular Disease': 'PVD',
  'Congestive Heart Failure': 'CHF'
}

export default function ({ loading: l, appliedTargetSelection: ats, data }) {
  const [presentationType, setPresentationType] = useState('percentage');
  const [dataViews, setDataViews] = useState({ i: {}, p: {} });
  
  useEffect(() => {
    if (!data?.individual) return;
    setDataViews({
      i: handleDataViewCreation(data.individual),
      p: handleDataViewCreation(data.population),
  });
  }, [presentationType, data]);

  function handleDataViewCreation(d) {
    return {
      age: {
        ['18-29']:                  formatValueView(d.total_count, d.age['18-29']),
        ['30-50']:                  formatValueView(d.total_count, d.age['30-50']),
        ['>50']:                    formatValueView(d.total_count, d.age['>50'])
      },
      race: {
        white:                      formatValueView(d.total_count, d.race.white),
        black_or_african_american:  formatValueView(d.total_count, d.race.black_or_african_american),
        asian_or_pacific_islander:  formatValueView(d.total_count, d.race.asian_or_pacific_islander),
        other:                      formatValueView(d.total_count, d.race.other)
      },
      sex: {
        male:                       formatValueView(d.total_count, d.sex.male),
        female:                     formatValueView(d.total_count, d.sex.female),
        other:                      formatValueView(d.total_count, d.sex.other)
      },
      smoker: {
        yes:                        formatValueView(d.total_count, d.smoker.yes)
      },
      comorbidities: d.comorbidities.map(({ key, value }) => ({
        key,
        values: formatValueView(d.total_count, value)
      }))
    }
  }

  function formatValueView(totalCount, value) {
    if (presentationType === 'count') return { count: value };
    if (presentationType === 'percentage') return { percentage: `${value ? (value / totalCount * 100).toFixed(1) : '0.0'}%`};
    if (presentationType === 'count_percentage') return { 
      count: value, 
      percentage: `${value ? (value / totalCount * 100).toFixed(1) : (0).toFixed(1)}%` 
    };
  }

  return (
    <Card style={styles.card}>
      <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '.5vw' }}>
        <Flex jcsb style={{ flexWrap: 'wrap', marginBottom: '20px'  }}>
          <div style={{ height: '20px' }}><b>Table 1.</b> Characteristics of Patient Population</div>
          <Flex>
            <BootstrapButton onClick={() => {setPresentationType('percentage')}} style={{ backgroundColor: presentationType === 'percentage' ? '#2198F3' : '#D6DBE0', height: 24, fontSize: 10, fontFamily: 'Roboto', color: presentationType === 'percentage' ? 'white' : 'black', borderColor: 'white', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} size="small" variant='contained'>%</BootstrapButton>
            <BootstrapButton onClick={() => {setPresentationType('count')}} style={{ backgroundColor: presentationType === 'count' ? '#2198F3' : '#D6DBE0', height: 24, fontSize: 10, fontFamily: 'Roboto', color: presentationType === 'count' ? 'white' : 'black', borderColor: 'white', transform: 'translateX(-1px)'}} size="small" variant='contained'>#</BootstrapButton>
            <BootstrapButton onClick={() => {setPresentationType('count_percentage')}} style={{ backgroundColor: presentationType === 'count_percentage' ? '#2198F3' : '#D6DBE0', height: 24, fontSize: 10, fontFamily: 'Roboto', color: presentationType === 'count_percentage' ? 'white' : 'black', borderColor: 'white', borderTopRightRadius: 20, borderBottomRightRadius: 20, transform: 'translateX(-2px)'}} size="small" variant='contained'>#%</BootstrapButton>
          </Flex>
        </Flex>
        <Flex row f={1}>
          <Flex f={1.2} column style={{ marginRight: '10px', minWidth: '140px' }}>
            <Heading bold style={{ minHeight: roomForWrapping }}>Characteristics</Heading>
            <Heading bold>Total Number of Patients</Heading>
            <Heading bold>Demographics (N, %)</Heading>
            <Heading bold p_value={data?.p_value?.age}>Age</Heading>
            <Heading indent>{'18-29'}</Heading>
            <Heading indent>{'30-50'}</Heading>
            <Heading indent>{'>50'}</Heading>
            <Heading bold p_value={data?.p_value?.race}>Race</Heading>
            <Heading indent>{'White'}</Heading>
            <Heading indent>{'Black'}</Heading>
            <Heading indent>{'Asian/Pacific Islander'}</Heading>
            <Heading indent>{'Other'}</Heading>
            <Heading bold p_value={data?.p_value?.sex}>Sex</Heading>
            <Heading indent>{'Male'}</Heading>
            <Heading indent>{'Female'}</Heading>
            <Heading indent>{'Other'}</Heading>
            <Heading bold p_value={data?.p_value?.smoker}>Smoker</Heading>
            <Heading indent>{'Yes'}</Heading>
            <Heading bold>Comorbidities</Heading>
            {(dataViews?.i?.comorbidities || []).map(({ key }, i) => <Heading compressable p_value={data?.p_value?.comorbidities?.[i]?.value ? data?.p_value?.comorbidities?.[i]?.value.toFixed(3) : null} key={i}>{key.replace(/\b\w/g, char => char.toUpperCase())}</Heading>)}
          </Flex>
          <Flex column f={.9} style={{ marginRight: '10px' }}>
            <Heading bold style={{ minHeight: roomForWrapping }}>{ats?.target_name}</Heading>
            <Cell values={{ count: data?.individual?.total_count }} l={l} />
            <Cell spacer />
            <Cell spacer />
            <Cell values={dataViews.i?.age?.['18-29']} l={l} />
            <Cell values={dataViews.i?.age?.['30-50']} l={l} />
            <Cell values={dataViews.i?.age?.['>50']}   l={l} />
            <Cell spacer />
            <Cell values={dataViews.i?.race?.white} l={l} />
            <Cell values={dataViews.i?.race?.black_or_african_american} l={l} />
            <Cell values={dataViews.i?.race?.asian_or_pacific_islander} l={l} />
            <Cell values={dataViews.i?.race?.other} l={l} />
            <Cell spacer />
            <Cell values={dataViews.i?.sex?.male} l={l} />
            <Cell values={dataViews.i?.sex?.female} l={l} />
            <Cell values={dataViews.i?.sex?.other} l={l} />
            <Cell spacer />
            <Cell values={dataViews.i?.smoker?.yes} l={l} />
            <Cell spacer />
            {(dataViews?.i?.comorbidities || []).map(({ values }, i) => <Cell key={i} values={values} l={l} />)}
          </Flex>
          <Flex column f={.9}>
            <Heading bold style={{ minHeight: roomForWrapping }}>{upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'}</Heading>
            <Cell values={{ count: data?.population?.total_count }} l={l} />
            <Cell spacer />
            <Cell spacer />
            <Cell values={dataViews.p?.age?.['18-29']} l={l} />
            <Cell values={dataViews.p?.age?.['30-50']} l={l} />
            <Cell values={dataViews.p?.age?.['>50']}   l={l} />
            <Cell spacer />
            <Cell values={dataViews.p?.race?.white} l={l} />
            <Cell values={dataViews.p?.race?.black_or_african_american} l={l} />
            <Cell values={dataViews.p?.race?.asian_or_pacific_islander} l={l} />
            <Cell values={dataViews.p?.race?.other} l={l} />
            <Cell spacer />
            <Cell values={dataViews.p?.sex?.male} l={l} />
            <Cell values={dataViews.p?.sex?.female} l={l} />
            <Cell values={dataViews.p?.sex?.other} l={l} />
            <Cell spacer />
            <Cell values={dataViews.i?.smoker?.yes} l={l} />
            <Cell spacer />
            {(dataViews?.p?.comorbidities || []).map(({ values }, i) => <Cell key={i} values={values} l={l} />)}
          </Flex>
        </Flex>
      </CardContent>
    </Card>
  )
}

function Heading({ children, indent, p_value, bold, style }) {
  const pValue = !Number.isNaN(p_value) ? p_value < .001 ? '<0.001' : parseFloat(p_value).toFixed(3) : 'N/A';
  return <Flex f={1} jcsb alss style={{
    fontWeight: bold ? 'bold' : null,
    marginLeft: indent ? '10px' : null,
    ...styles.cell,
    ...style,
  }}>
    <Box sx={{ display: { xs: 'none', md: 'block', xl: 'block' } }}>{children}</Box>
    <Box sx={{ display: { xs: 'block', md: 'none', xl: 'none' } }}>{comorbidityTrimMap[children] ? comorbidityTrimMap[children] : children}</Box>
    {p_value ? <Box sx={{ fontWeight: 'normal', fontSize: '10px' }}>p = {pValue}</Box> : ''}
  </Flex>
}

function Cell({ values, l, spacer }) {
  if (l) return <Flex f={1} alss className='load-placeholder' style={{ ...styles.cell, maxWidth: /* p_value ? '60px' : */ null, minWidth: 100 }}><span style={{ opacity: 0 }}>|</span></Flex>
  if (values?.percentage || ![null, undefined].includes(values?.count)) return (
    <Flex row f={1} alss jcsb style={styles.cell}>
      {values.count && <span>{values.count}</span>}
      {values.percentage && <Box sx={![null, undefined].includes(values?.count) ? { marginRight: { xs: '30px', md: '70px' }, paddingLeft: '10px'} : {}}>{values.percentage}</Box>}
    </Flex>
  );
  if (spacer) return <Flex f={1} alss style={{ ...styles.cell, opacity: 0 }}>br</Flex>
  return <Flex f={1} alss style={{ ...styles.cell, opacity: 1 }}>-</Flex>
}

const styles = {
  card: { 
    flex: 1,
    display: 'flex', 
    alignSelf: 'stretch',
    margin: '.5vw', 
    fontSize: '12px', 
    flexWrap: 'wrap', 
    overflowWrap: 'normal'
  },
  pvalue: {
    marginLeft: '12px',
    fontSize: '10px',
    minWidth: '50px'
  },
  cell: {
    marginBottom: '15px'
  }
}

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  // backgroundColor: 'grey',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
