import { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import ContributingRisk from './ContributingRisk.js';
import api from '../_api/api.js';
import Flex from '../_global/Flex.js';
import { useAppContext } from '../AppContext';


export default function() {
  const { session, appliedTargetSelection: ats, sites, handleSetPDFReferences } = useAppContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const pdfRef1 = useRef();
  useEffect(() => { handleSetPDFReferences([pdfRef1]) }, []);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await api.getShapAggregationsV2(ats, sites, session.role_access);
        setData(response);
        setLoading(false);
      } catch(err) {
        console.log(err);
        setData(null);
      }
      }());
    }, [ats]);

  return (
    <Box sx={{ marginTop: { xs: '100px', md: '70px', xl: '70px' }, marginLeft: { xs: 0, md: '18.5rem', xl: '18.5rem' }, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <Flex reference={pdfRef1} column jcc aic>
        <ContributingRisk
          loading={loading}
          data={data}
          appliedTargetSelection={ats}
        />
      </Flex>
    </Box>
  );
}
