import { useEffect, useState, useRef } from "react";
import { Box, Card } from "@mui/material";
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import Flex from '../_global/Flex.js';
import api from '../_api/api.js';
import { useAppContext } from '../AppContext';
import SurgeonTable from "./SurgeonTable.js";


const outcomeAlias = {
  procedure_time_avg: 'procedure time',
  los_avg: 'LoS',
  readmission_count: 'readmission',
  non_home_discharge_count: 'non home discharge',
}


export default function(){
  const { session, appliedTargetSelection: ats, sites, newest_encounter_date, handleSetPDFReferences, handleNavChange } = useAppContext();
  const [loading, setLoading] = useState({});
  const [data, setData] = useState(null);
  const [topThreeProcedures, setTopThreeProcedures] = useState([]);
  const [totals, setTotals] = useState({})
  const [emptyState, setEmptyState] = useState(false);
  
  const pdfRef1 = useRef();
  useEffect(() => {
    // handleNavChange(location.pathname);
    handleSetPDFReferences([pdfRef1]);
  }, []);
  
  useEffect(() => {
    if (!ats) return;
    (async function () {
      setLoading({ counts: true, comparison: true, surgeons: true });
      setEmptyState(false);
      try {
        const [year, month] = newest_encounter_date.split('-');
        const start_date = `${year}-${month}-01`;
        ats.end_date = newest_encounter_date;
        ats.start_date = start_date;
        ats.comparison_group_name = 'enterprise';
        const proceduresTopThreeResponse = await fetchCounts();
        if (proceduresTopThreeResponse.length === 0) return setEmptyState(true);
        await fetchComparisons(proceduresTopThreeResponse);
        if (session.role_access === 'global') await fetchSurgeons(proceduresTopThreeResponse);
      } catch(err) {
        console.log(err);
        setData(null);
      }
    }());
  }, [ats]);

  async function fetchCounts() {
    const proceduresTopThreeResponse = await api.getProceduresTopThree(ats, sites, session.role_access);
    const procedureCountsResponse = await api.getProcedureCounts(ats, sites, session.role_access);
    const totals = createTotals(procedureCountsResponse, proceduresTopThreeResponse);
    setTotals(totals);
    setTopThreeProcedures(proceduresTopThreeResponse);
    setData(prev => ({ 
      ...prev, 
      procedureVolume: procedureCountsResponse 
    }));
    setLoading(prev => ({ ...prev, counts: false }));
    return proceduresTopThreeResponse;
  }

  async function fetchComparisons(proceduresTopThreeResponse) {
    const comparisonDateRange = [{ start_date: ats.start_date, end_date: ats.end_date }, getPreviousMonthRange(ats.start_date)];
    const procedureOutcomesComparisonResponse = await api.getProcedureOutcomesComparison(ats, proceduresTopThreeResponse, comparisonDateRange, sites, session.role_access);
    setData(prev => ({ 
      ...prev, 
      comparison: procedureOutcomesComparisonResponse,
    }));
    setLoading(prev => ({ ...prev, comparison: false }));
  }

  async function fetchSurgeons(proceduresTopThreeResponse) {
    const surgeonTableResponse = (await api.getSurgeonOverview(ats, sites, session.role_access)).find(({ identifier }) => identifier === proceduresTopThreeResponse[0]);
    setData(prev => ({ 
      ...prev, 
      surgeonTable: surgeonTableResponse
    }));
    setLoading(prev => ({ ...prev, surgeons: false }));
  }

  function createTotals(data, topThree) {
    return data.reduce((acc, { date, procedures }) => {
      procedures.forEach(({ identifier, nicename, count }) => {
        // if (!topThree.includes(identifier) && identifier !== 'OTHER') return acc;
        identifier = topThree.includes(identifier) ? identifier : 'OTHER'; 
        nicename = topThree.includes(identifier) ? nicename : 'All Others'; 
        if (!acc[identifier]) acc[identifier] = { 
          nicename,
          count: { all: 0, robotic: 0, laparoscopic: 0, open: 0, other: 0  }
        };
        acc[identifier] = {
          ...acc[identifier],
          count: {
            all: acc[identifier].count.all + count.all,
            robotic: acc[identifier].count.robotic + count.robotic,
            laparoscopic: acc[identifier].count.laparoscopic + count.laparoscopic,
            open: acc[identifier].count.open + count.open,
            other: acc[identifier].count.other + count.other
          }
        }
      });
      return acc;
    }, {});
  }

  return (
    <Box sx={styles.container} ref={pdfRef1}>
      <Flex column f={1}>
        {emptyState && <EmptyState />}
        {!emptyState && <ProcedureVolumesCard 
          loading={loading.counts}
          data={data?.procedureVolume} 
          topThreeProcedures={topThreeProcedures} 
          totals={totals} 
          handleNavChange={handleNavChange}
        />}
        {!emptyState && <ChangesOverMonthCard 
          loading={loading.comparison}
          data={data?.comparison} 
          totals={totals} 
          handleNavChange={handleNavChange}
          emptyState={emptyState}
        />}
        {session.role_access === 'global' && !emptyState && <SurgeonTable 
          procedure_name={data?.surgeonTable?.nicename} 
          rows={data?.surgeonTable?.surgeons} 
          selected_surgeon_uuid={ats.target_type === 'surgeon' ? ats.target_uuid : null} 
          loading={loading.surgeons}
          handleNavChange={handleNavChange}
        />}
      </Flex>
    </Box>
  );
}

const colorBySurgicalMethod = {
  robotic:      '#C34A84',
  laparoscopic: '#DB86AA',
  open:         '#EFBFD1',
  other:        '#DBC3CC'
}

const procedureLineChartColorsByIndex = ['#F5DABE', '#EBABAB', '#BED5EA', '#ADADAD']; 

function ProcedureVolumesCard({ loading, data, topThreeProcedures, totals, emptyState, handleNavChange }) {
  const [donutData, setDonutData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);

  useEffect(() => {
    if (!data) return;
    const donutChartData = createDonutChartData(totals);
    const volumeDataByWeek = bundleVolumeDataByWeek(data, topThreeProcedures);
    setDonutData(donutChartData);
    setDateRanges(volumeDataByWeek.map(({ dateRange }) => dateRange).sort((a, b) => a < b ? -1 : 1));
    setVolumeData(volumeDataByWeek.sort((a, b) => a.dateRange < b.dateRange ? -1 : 1));
  }, [data]);

  function bundleVolumeDataByWeek(series = [], topThreeProcedures = []) {
    const dataByWeek = series
      .sort((a, b) => a.date < b.date ? -1: 1)
      .reduce((acc, { date, procedures }) => {
        const dayOfWeek = dayjs(date).week();
        if (Number.isNaN(dayOfWeek)) return acc; // Not sure what this was
        if (!acc[dayOfWeek]) acc[dayOfWeek] = []; 
        acc[dayOfWeek].push({
          date: new Date(date).toISOString().split('T')[0],
          procedures
        });
        return acc;
    }, {});
    return Object.values(dataByWeek).map(week => {
      const [_, ...start] = new Date(week[0].date).toISOString().split('T')[0].split('-');
      const [__, ...end] = new Date(week[week.length - 1].date).toISOString().split('T')[0].split('-');
      return {
        dateRange: `${start.join('/')}-${end.join('/')}`,
        volumeByProcedure: week.reduce((acc, { procedures }) => {
          procedures.forEach(({ identifier, count }) => {
            // The response gives us other as just what is not recognized procedure wise. This compiles those those that arent top 3 also into other
            identifier = topThreeProcedures.includes(identifier) ? identifier : 'OTHER'; 
            if (!acc[identifier]) acc[identifier] = 0;
            acc[identifier] += count.all;
          });
          return acc;
        }, {})
      };
    });
  }

  function createDonutChartData(totals) {
    return Object.entries(totals)
      .filter(([identifier]) => identifier !== 'OTHER') // NO donut for other
      .sort((a, b) => a.all > b.all ? 1 : -1) // order highest volume to top
      .map(([_, { count, nicename }], i) => ({ 
        nicename,
        count
      }));
  }

  return (
    <Card style={styles.card}>
      <Flex f={1}>

        {/* CHART CONTAINER */}
        <Flex aic column>
          <Flex style={{ fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', alignSelf: 'flex-start' }}>Top 3 General Surgery Procedure Count</Flex>
            {emptyState ? 
              <Flex>
                hello
              </Flex>
            : null}
            {!loading ? <Plot 
              data={
                [{ identifier: 'OTHER', color: '#ADADAD' }, ...topThreeProcedures.map((identifier, i) => ({ identifier, color: procedureLineChartColorsByIndex[i] }))]
                  .map(({ identifier, color }) => {
                    console.log(volumeData);
                    return { 
                        x: dateRanges,
                        y: volumeData.map(({ volumeByProcedure }) => volumeByProcedure[identifier]),
                        name: identifier,
                        type: 'bar',
                        marker: { color },
                        width: 0.5,
                        hovertemplate: `${totals[identifier]?.nicename}<br>%{y}<extra></extra>`, // Custom hover label
                    }})
              }
              layout={{ 
                barmode: 'stack',
                margin: { l: 80, r: 0, t: 0, b: 40 },
                showlegend: false, 
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                yaxis: {
                  title: 'Number of Cases', // Y-axis title
                  tickformat: ',d' // formats ticks as integers
                }
              }} 
              style={{ width: 700, height: 300, marginTop: 30 }}
            /> : <Flex className='load-placeholder' style={{ width: 700, height: 280, marginTop: 30, marginLeft: 50, marginBottom: 20  }} />}
          <Flex alss jcsb style={{ marginLeft: 50, flexWrap: 'wrap' }}>
            {[...topThreeProcedures, 'OTHER'].map((identifier, key) => {
              return (
                <Flex key={key} style={{ marginRight: 20, marginTop: 5 }}>
                  <Flex style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: identifier === 'OTHER' ? '#ADADAD' : procedureLineChartColorsByIndex[key], marginRight: 4 }}/>
                  {loading ? 
                    <Flex style={{ height: 12, width: 130 }} className={'load-placeholder'} /> :  
                    <Flex style={{ fontSize: 12, transform: 'translateY(-2px)'}}>
                      {totals?.[identifier]?.nicename || 'All Others'} = {totals?.[identifier]?.count?.all || 0}
                    </Flex>}
                </Flex>
              )
            })}
          </Flex>
        </Flex>

        <Flex alss column  style={{ height: 400, paddingLeft: 100 }}>
            {!loading ? donutData?.length > 0 && donutData.map((({ nicename, count }, i) => {
              const keys = ['Robotic', 'Lap', 'Open', 'Other'].filter(key => {
                key = key === 'Lap' ? 'laparoscopic' : key;
                if (count[key.toLowerCase()] > 0) return true;
                return false;
              });
              return (
                <Flex style={{ overflow: 'visible', position: 'relative' }} key={i}>
                    <Plot
                      data={[
                        {
                          values: [
                            count.robotic, 
                            count.laparoscopic, 
                            count.open, 
                            count.other
                          ].filter(val => val > 0),
                          labels: keys,
                          type: 'pie',
                          hole: .5,
                          hoveron: false,
                          textinfo: 'text+percent',
                          texttemplate: '%{label}<br>%{percent:.1%}',
                          textposition: 'outside',
                          insidetextorientation: 'radial',
                          marker: { colors: keys.map(key => key === 'Lap' ? colorBySurgicalMethod.laparoscopic : colorBySurgicalMethod[key.toLowerCase()]) },
                          outsidetextfont: { size: 8, color: '#000' },
                          sort: false
                        }
                      ]}
                      layout={{
                        showlegend: false, 
                        annotations: [], 
                        margin: { l: 70, r: 50, t: 0, b: 0 },
                        paper_bgcolor: 'rgba(0,0,0,0)', // Set the background surrounding the plot to be transparent
                        plot_bgcolor: 'rgba(0,0,0,0)', // Set the actual plot background to be transparent
                      }}
                      style={{ 
                        width: 200, 
                        height: 200, 
                        transform: `translateY(-${i * 80}px)`
                      }}
                  />
                  <span style={{ fontSize: 12, position: 'absolute', left: -40, top: 50, transform: `translateY(-${i * 80}px)` }}>{nicename}</span>
              </Flex>
              )
            })) : [1,2,3].map((n, i) => (
              <Flex key={i} style={{ overflow: 'visible', position: 'relative', marginTop: 20, marginLeft: 20, transform: 'translateY(20px)' }}>
                <Flex className='load-placeholder' style={{ width: 90, height: 90, borderRadius: 100 }}/>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex f={.3} style={{ position: 'relative' }}>
          <Flex style={{ fontSize: 16, color: 'black', textWrap: 'nowrap', position: 'absolute', top: 0, right: 0, fontSize: 14 }}><div to='/procedures' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleNavChange('Procedure Tables')}>See All Procedures</div></Flex>
        </Flex>
    </Card>
  )
}

function ChangesOverMonthCard({ loading, data = [], totals, handleNavChange }) { 
  function resolveComparison({ outcome_diff }, i) {
    const final = {};
    const sortedEntries = Object.entries(outcome_diff)
      .filter(([key, value]) => ![-1, 0].includes(value))
      .sort((a, b) => Math.abs(a[1]) > Math.abs(b[1]) ? -1 : 1);

    if (sortedEntries.length === 0) return null;
    if (sortedEntries[0][1] !== sortedEntries?.[1]?.[1]) {
      const rawPercent = Math.round(sortedEntries[0][1] * 100, 1);
      final.icon = <div style={{ 
        width: 35, height: 30, 
        backgroundColor: procedureLineChartColorsByIndex[i], 
        ...(rawPercent > 0 ? {
          clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)', 
          WebkitClipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)'
        } : {
          clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)', 
          WebkitClipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)'
        })
      }}/>
      final.percentage = Math.abs(rawPercent);
      final.description = `${rawPercent > 0 ? 'Increase' : 'Decrease'} in ${outcomeAlias[sortedEntries[0][0]]} compared to previous month`
    }
    return final;
  }

  return (
    <Card style={{ ...styles.card, position: 'relative', maxWidth: 1200 }}>
      <Flex f={1} jcsa style={{ padding: 40 }}>
        {!loading ? data?.map(({ procedure_identifier, outcome_diff }, i) => {
          const results = resolveComparison({ outcome_diff }, i);
          // const { icon, percentage, description } = comparisonResults;
          return (
            <Flex column key={i}>
              <div>{totals[procedure_identifier]?.nicename}</div>
              <Flex style={{ marginTop: 10, alignItems: 'center' }}>
                {results?.icon || <div style={{ width: 28, height: 28, backgroundColor: procedureLineChartColorsByIndex[i], marginRight: 5 }}/>}
                <div style={{ fontWeight: 'bold', fontSize: 24 }}>{results ? `${results.percentage}%` : 'N/A'}</div>
                <div style={{ marginLeft: 5, fontSize: 12, flexWrap: 'wrap', width: 150 }}>{results ? results.description : 'No noteable comparison from previous month'}</div>
              </Flex>
            </Flex>
          );
        }): (
          [1,2,3].map((n, i) => (
            <Flex key={i} column>
              <Flex className='load-placeholder' style={{ width: 140, height: 20, marginBottom: 10 }}/>
              <Flex className='load-placeholder' style={{ width: 230, height: 50 }}/>
            </Flex>
          ))
        )}
      </Flex>
      <div style={{ position: 'absolute', top: 25, right: 20, fontSize: 14 }}><div to='/outcomes' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleNavChange('General Analysis')}>See All Outcomes</div></div>
    </Card>
  );
}

function EmptyState() {
  return (
    <Card style={{ ...styles.card, height: 600 }}>
      <Flex f={1} column aic jcc style={{ transform: 'translateY(-200px)' }}>
        <div style={{ fontWeight: 'bold', fontSize: 20 }}>Oops.. Not enough data found</div>
        <div>Please try again with a different filter configuration</div>
      </Flex>
    </Card>
  )
}

const styles = {
  container: { 
    marginTop: { xs: '100px', md: '70px', xl: '70px' }, 
    marginLeft: { xs: 0, md: '18.5rem', xl: '18.5rem' }, 
    display: 'flex', 
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    flex: 1, 
    paddingBottom: 1
  },
  card:{
    maxWidth: 1200,
    display: 'flex',
    alignSelf: 'stretch',
    margin: '8px 10px 0 0',
    padding: 15,
    paddingTop: 25
  }
}

function getPreviousMonthRange(date) {
  // Get the first day of the month for the given date
  const startOfGivenMonth = dayjs(date).startOf('month');
  
  // Get the first and last day of the previous month
  const startOfPreviousMonth = startOfGivenMonth.subtract(1, 'month');
  const endOfPreviousMonth = startOfGivenMonth.subtract(1, 'day');

  return {
    start_date: startOfPreviousMonth.format('YYYY-MM-DD'),
    end_date: endOfPreviousMonth.format('YYYY-MM-DD')
  };
}