import { ListItem, Checkbox, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material/';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Flex from '../_global/Flex.js';


export default function ({ label, options, expanded, handleExpansion, onClick, selection, preListJSX, emptyStateJSX, listItemStyles, appliedValueText, noCollapseOnSelection, multiselect, maxHeight }) {
  return (
    <ListItem onClick={() => !noCollapseOnSelection ? handleExpansion() : null} divider sx={{ position: 'relative', flexDirection: 'column', alignItems: 'flex-start', marginTop: 0, paddingLeft: 2, paddingBottom: .5, marginBottom: 0, paddingTop: 0 }}>
      <Accordion expanded={expanded} sx={{ width: '100%' }} /* onChange={(_, isExpanded) => setExpanded(isExpanded)} */ disableGutters={true} elevation={0}>
        <AccordionSummary onClick={() => noCollapseOnSelection ? handleExpansion() : null} expandIcon={<ExpandMoreIcon />}>
        <Typography typography={'accordion'}>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ transform: 'translateY(-10px)'}}>
          <Flex column style={{ maxHeight: maxHeight || 400, overflow: 'scroll' }}>
            {preListJSX || null}
            {options.length === 0 ? emptyStateJSX : null}
            {!multiselect && options.map(({ value, nicename, disabled, selected, itemSubText, tooltip }, index) => (
              <Flex column key={index} alss jcsb
                onClick={() => !disabled && onClick(value, nicename)}>
                <Flex style={{ cursor: 'pointer' }} alss>
                  {!selected && !disabled ? <Typography style={{ ...styles.available, ...listItemStyles }}>{nicename}</Typography> : null}
                  {selected && !disabled ? <Typography style={{ ...styles.selected, ...listItemStyles }}>{nicename}</Typography> : null}
                  {disabled ? <Typography style={{ ...styles.disabled, ...listItemStyles }}>{nicename}</Typography> : null}
                  {tooltip || null}
                </Flex>
                {itemSubText ? 
                  <div style={{ marginLeft: 10, fontSize: 12, transform: 'translateY(0px)'}}>{itemSubText}</div> 
                : null}
              </Flex>
            ))}
            {multiselect && options.map(({ value, nicename, selected }, index) => (
              <Flex column key={index} alss jcsb
                onClick={() => onClick(value)}>
                <Flex jcsb style={{ cursor: 'pointer' }} alss>
                  {!selected ? <Typography style={{ ...styles.available, ...listItemStyles }}>{nicename}</Typography> : null}
                  {selected ? <Typography style={{ ...styles.selected, ...listItemStyles }}>{nicename}</Typography> : null}
                  <Checkbox checked={selected} inputProps={{ 'aria-label': 'primary checkbox' }} />           
                  </Flex>
              </Flex>
            ))}
          </Flex>
        </AccordionDetails>
      </Accordion>
      {!expanded && selection ? <span style={{ position: 'absolute', bottom: 3, left: 40, fontSize: 10, fontStyle: 'italic', color: '#6e6e6e' }}>{selection}</span> : null}
      <div style={{ marginLeft: 15, transform: 'translateY(-6px)', fontSize: 12, fontStyle: 'italic', color: '#6e6e6e', opacity: .7 }}>{appliedValueText}</div>
    </ListItem>
  )    
}

const styles = {
  available: {
    padding: 6, 
    display: 'flex',
    color: '#333333',
    fontWeight: 400, 
    fontSize: '14px',
  },
  selected: { 
    padding: 6, 
    display: 'flex',
    color: '#0084ff',
    fontWeight: 700, 
    fontSize: '14px',
  },
  disabled: {
    padding: 6, 
    cursor: null,
    display: 'flex',
    color: '#D3D3D3',
    fontWeight: 400, 
    fontSize: '14px',
  }
}