import { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BeatLoader } from 'react-spinners';
import logo from '../_assets/logo-color.png';
import { Box } from '@mui/material/';
import analytics from '../_api/analytics';

const pdfDimensions = {
  logo: {
    x: 2,
    y: 2,
    width: 60,
    height: 10
  },
  0: {
    x: 35,
    y: 15,
    xOffset: -80,
    yOffset: -160
  },
  1: {
    x: 25,
    y: 5,
    xOffset: -40,
    yOffset: -80
  }
}

export default function({ page, references, appliedTargetSelection: ats }) {
  const [isDownloading, toggleDownloading] = useState(false);

  async function handleDownloadPDF() {
    analytics.newEvent({ category: 'Click', action: 'PDF Download' });
    toggleDownloading(true);
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const { logo: logoDim } = pdfDimensions;
    pdf.addImage(logo, 'PNG', logoDim.x, logoDim.y, logoDim.width, logoDim.height);
    pdf.setFontSize(8);
    pdf.text(`Confidential`,  pdfWidth / 2 - 10, 8);
    pdf.text(`Created on: ${new Date().toISOString().split('T')[0]}`,  pdfWidth - 40, 8);
    pdf.text(`Data displayed from: `, pdfWidth - 40, 13);
    pdf.text(`${ats.start_date} - ${ats.end_date}`, pdfWidth - 40, 18);

    if (page === 'Demographics') {
      // Column 1
      const data1 = (await html2canvas(references[0].current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      })).toDataURL('image/png');
      const imgProperties1 = pdf.getImageProperties(data1);
      const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
      pdf.addImage(data1, 'PNG', 
        5,             // x
        25,             // y
        pdfWidth - 110, // width
        pdfHeight1 - 160 // height
      );

      // Column 2
      const data2 = (await html2canvas(references[1].current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      })).toDataURL('image/png');
      const imgProperties2 = pdf.getImageProperties(data2);
      const pdfHeight2 = (imgProperties2.height * pdfWidth) / imgProperties2.width;
      pdf.addImage(data2, 'PNG', 
        100,             // x
        25,             // y
        pdfWidth - 100, // width
        pdfHeight2 - 160 // height
      );
    }

    if (page === 'Risk Analysis') {
      // Column 1
      const data1 = (await html2canvas(references[0].current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      })).toDataURL('image/png');
      const imgProperties1 = pdf.getImageProperties(data1);
      const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
      pdf.addImage(data1, 'PNG', 
        15,             // x
        25,             // y
        pdfWidth - 20, // width
        pdfHeight1 - 10// height
      );
    }

    if (['Practice Overview', 'General Analysis'].includes(page)) {
      // Column 1
      const data1 = (await html2canvas(references[0].current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      })).toDataURL('image/png');
      const imgProperties1 = pdf.getImageProperties(data1);
      const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
      pdf.addImage(data1, 'PNG', 
        5,             // x
        25,             // y
        pdfWidth - 20, // width
        pdfHeight1 - 8 // height
      );
    }

    if (['Home'].includes(page)) {
      // Column 1
      const data1 = (await html2canvas(references[0].current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      })).toDataURL('image/png');
      const imgProperties1 = pdf.getImageProperties(data1);
      const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
      pdf.addImage(data1, 'PNG', 
        15,             // x
        25,             // y
        pdfWidth, // width
        pdfHeight1 // height
      );
    }
    
    pdf.save('kelaHealth_report.pdf');
    toggleDownloading(false);
  };

  return (
    !isDownloading ? 
      <Box onClick={handleDownloadPDF} sx={{ ...styles.container, position: { xs: 'static', md: 'absolute', xl: 'absolute' }, margin: { xs: '10px', md: '0px', xl: '0px' } }}>
        <span>Download PDF</span>
      </Box>
      : 
      <Box sx={{ ...styles.container, position: { xs: 'static', md: 'absolute', xl: 'absolute' } }}>
        <span style={{ display: 'flex', flex: 1 }}></span><BeatLoader size={10} color="white" />
      </Box> 
  );
}

const styles = {
  container: {
    background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)',
    bottom: 20,
    right: 20,
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '2px 2px 2px grey',
    color: 'white', 
    fontWeight: 700,
    cursor: 'pointer',
    padding: '15px',
    paddingTop: '5px',
    paddingBottom: '6px',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center' 
  }
}
