export function getSession() {
  return {
    organization_identifier: localStorage.getItem('organization_identifier'),
    user_identifier: localStorage.getItem('user_identifier'),
    email: localStorage.getItem('email'),
    portal: localStorage.getItem('portal'),
    role_access: localStorage.getItem('role_access')
  }
}

export function setSession({ 
    organization_identifier, 
    user_identifier, 
    email,
    portal,
    access_token,
    refresh_token,
    role_access
  }) {
  localStorage.setItem('organization_identifier', organization_identifier);
  localStorage.setItem('user_identifier', user_identifier);
  localStorage.setItem('email', email);
  localStorage.setItem('portal', portal);
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);
  localStorage.setItem('role_access', role_access);
}

export function closeSession() {
  localStorage.clear();
}

export function cacheAppliedTargetSelection(appliedTargetSelection) {
  localStorage.setItem('config', JSON.stringify(appliedTargetSelection));
}

export function getCachedAppliedTargetSelection() {
  const json = localStorage.getItem('config');
  return JSON.parse(json);
}

export function clearCache() {
  localStorage.clear();
}