import { useState } from 'react';
import Flex from '../_global/Flex.js'
import { Card, CardContent, Button, Tooltip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { surgicalMethodAlias, upperCaseFirst } from '../helpers.js';


export default function ({ loading: l, data, appliedTargetSelection: ats }) {
  const [encounterTypeLocal, setSettingLocal] = useState('inpatient');
  const inpatientTable = (ats?.encounter_type_identifier === 'ALL' && encounterTypeLocal === 'inpatient') || (ats?.encounter_type_identifier !== 'ALL' && ats?.encounter_type === 'inpatient');
  return (
    <Card style={styles.card}>
      <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '.5vw' }}>
        <Flex jcsb style={{ flexWrap: 'wrap', marginBottom: '20px'  }}>
          <Flex column f={1}>
            <div style={{ height: '20px' }}><b>Table 2.</b> {(ats?.encounter_type || encounterTypeLocal)?.charAt(0).toUpperCase() + (ats?.encounter_type || encounterTypeLocal)?.slice(1)} Outcomes</div>
          </Flex>
          {ats?.encounter_type_identifier === 'ALL' ? <Flex style={{ transform: 'translateY(-5px)'}}>
            <BootstrapButton onClick={() => setSettingLocal('inpatient')} style={{ backgroundColor: inpatientTable ? '#2198F3' : '#D6DBE0', width: 75, height: 24, fontSize: 10, fontFamily: 'Roboto', color: inpatientTable ? 'white' : 'black', borderColor: 'white', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} size="small" variant='contained'>Inpatient</BootstrapButton>
            <BootstrapButton onClick={() => setSettingLocal('outpatient')} style={{ backgroundColor: !inpatientTable ? '#2198F3' : '#D6DBE0', width: 75, height: 24, fontSize: 10, fontFamily: 'Roboto', color: !inpatientTable ? 'white' : 'black', borderColor: 'white', borderTopRightRadius: 20, borderBottomRightRadius: 20, transform: 'translateX(-4px)'}} size="small" variant='contained'>Outpatient</BootstrapButton>
          </Flex> : null}
        </Flex>
        {
         inpatientTable ?
            <InpatientTable 
              l={l} 
              data={{ 
                individual: {
                  robotic: data?.individual?.robotic?.inpatient,
                  laparoscopic: data?.individual?.laparoscopic?.inpatient,
                  open: data?.individual?.open?.inpatient,
                  other: data?.individual?.other?.inpatient
                },
                population: {
                  robotic: data?.population?.robotic?.inpatient,
                  laparoscopic: data?.population?.laparoscopic?.inpatient,
                  open: data?.population?.open?.inpatient,
                  other: data?.population?.other?.inpatient
                }
              }}
              comparison_group={upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'} 
              service_line={ats?.service_line} 
              targetName={ats.target_name}
            /> :
          <OutpatientTable 
            l={l} 
            data={{ 
              individual: {
                robotic: data?.individual?.robotic?.outpatient,
                laparoscopic: data?.individual?.laparoscopic?.outpatient,
                open: data?.individual?.open?.outpatient,
                other: data?.individual?.other?.outpatient
              },
              population: {
                robotic: data?.population?.robotic?.outpatient,
                laparoscopic: data?.population?.laparoscopic?.outpatient,
                open: data?.population?.open?.outpatient,
                other: data?.population?.other?.outpatient
              }
            }}
            comparison_group={upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'} 
            service_line={ats?.service_line}
            targetName={ats.target_name}
          />
        }
      </CardContent>
    </Card>
  );
}

function InpatientTable({ l, data, targetName, comparison_group, service_line }) {
  const { individual: i, population: p } = data;
  return (
    <Flex column f={1} style={{ transform: 'translateY(-20px)' }}>
      <Section 
        label='Number of Patients' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: i?.robotic?.total_count,
          laparoscopic: i?.laparoscopic?.total_count,
          open: i?.open?.total_count,
          other: i?.other?.total_count,
        }}
        population={{
          robotic: p?.robotic?.total_count,
          laparoscopic: p?.laparoscopic?.total_count,
          open: p?.open?.total_count,
          other: p?.other?.total_count,
        }}
      />
      <Section 
        label='Avg Procedure Time (mins)' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: i?.robotic?.procedure_time_avg.toFixed(1),
          laparoscopic: i?.laparoscopic?.procedure_time_avg.toFixed(1),
          open: i?.open?.procedure_time_avg.toFixed(1),
          other: i?.other?.procedure_time_avg.toFixed(1),
        }}
        population={{
          robotic: p?.robotic?.procedure_time_avg.toFixed(1),
          laparoscopic: p?.laparoscopic?.procedure_time_avg.toFixed(1),
          open: p?.open?.procedure_time_avg.toFixed(1),
          other: p?.other?.procedure_time_avg.toFixed(1),
        }}
      />
      <Section 
        label='Avg LOS (days)' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: i?.robotic?.los_avg.toFixed(2),
          laparoscopic: i?.laparoscopic?.los_avg.toFixed(2),
          open: i?.open?.los_avg.toFixed(2),
          other: i?.other?.los_avg.toFixed(2),
        }}
        population={{
          robotic: p?.robotic?.los_avg.toFixed(2),
          laparoscopic: p?.laparoscopic?.los_avg.toFixed(2),
          open: p?.open?.los_avg.toFixed(2),
          other: p?.other?.los_avg.toFixed(2),
        }}
      />
      <Section 
        label='Readmission' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: `${(i?.robotic?.readmissions_percentage * 100).toFixed(1)}%`,
          laparoscopic: `${(i?.laparoscopic?.readmissions_percentage * 100).toFixed(1)}%`,
          open: `${(i?.open?.readmissions_percentage * 100).toFixed(1)}%`,
          other: `${(i?.other?.readmissions_percentage * 100).toFixed(1)}%`,
        }}
        population={{
          robotic: `${(p?.robotic?.readmissions_percentage * 100).toFixed(1)}%`,
          laparoscopic: `${(p?.laparoscopic?.readmissions_percentage * 100).toFixed(1)}%`,
          open: `${(p?.open?.readmissions_percentage * 100).toFixed(1)}%`,
          other: `${(p?.other?.readmissions_percentage * 100).toFixed(1)}%`,
        }}
      />
      <Section 
        label='Home Discharge' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: `${(i?.robotic?.discharge_destination?.home / i?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(i?.laparoscopic?.discharge_destination?.home / i?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(i?.open?.discharge_destination?.home / i?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(i?.other?.discharge_destination?.home / i?.other?.total_count * 100).toFixed(1)}%`,
        }}
        population={{
          robotic: `${(p?.robotic?.discharge_destination?.home / p?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(p?.laparoscopic?.discharge_destination?.home / p?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(p?.open?.discharge_destination?.home / p?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(p?.other?.discharge_destination?.home / p?.other?.total_count * 100).toFixed(1)}%`,
        }}
      />
      <Section 
        label='Home Health' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: `${(i?.robotic?.discharge_destination?.home_health / i?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(i?.laparoscopic?.discharge_destination?.home_health / i?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(i?.open?.discharge_destination?.home_health / i?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(i?.other?.discharge_destination?.home_health / i?.other?.total_count * 100).toFixed(1)}%`,
        }}
        population={{
          robotic: `${(p?.robotic?.discharge_destination?.home_health / p?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(p?.laparoscopic?.discharge_destination?.home_health / p?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(p?.open?.discharge_destination?.home_health / p?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(p?.other?.discharge_destination?.home_health / p?.other?.total_count * 100).toFixed(1)}%`,
        }}
      />
      <Section 
        label='SNF / Other' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: `${((i?.robotic?.discharge_destination?.snf + i?.robotic?.discharge_destination?.other) / i?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${((i?.laparoscopic?.discharge_destination?.snf + i?.laparoscopic?.discharge_destination?.other) / i?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${((i?.open?.discharge_destination?.snf + i?.open?.discharge_destination?.other) / i?.open?.total_count * 100).toFixed(1)}%`,
          other: `${((i?.other?.discharge_destination?.snf + i?.other?.discharge_destination?.other) / i?.other?.total_count * 100).toFixed(1)}%`,
        }}
        population={{
          robotic: `${((p?.robotic?.discharge_destination?.snf + p?.robotic?.discharge_destination?.other) / p?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${((p?.laparoscopic?.discharge_destination?.snf + p?.laparoscopic?.discharge_destination?.other) / p?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${((p?.open?.discharge_destination?.snf + p?.open?.discharge_destination?.other) / p?.open?.total_count * 100).toFixed(1)}%`,
          other: `${((p?.other?.discharge_destination?.snf + p?.other?.discharge_destination?.other) / p?.other?.total_count * 100).toFixed(1)}%`,
        }}
      />
      <Section 
        label='Expired' 
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: `${(i?.robotic?.discharge_destination?.expired / i?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(i?.laparoscopic?.discharge_destination?.expired / i?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(i?.open?.discharge_destination?.expired / i?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(i?.other?.discharge_destination?.expired / i?.other?.total_count * 100).toFixed(1)}%`,
        }}
        population={{
          robotic: `${(p?.robotic?.discharge_destination?.expired / p?.robotic?.total_count * 100).toFixed(1)}%`,
          laparoscopic: `${(p?.laparoscopic?.discharge_destination?.expired / p?.laparoscopic?.total_count * 100).toFixed(1)}%`,
          open: `${(p?.open?.discharge_destination?.expired / p?.open?.total_count * 100).toFixed(1)}%`,
          other: `${(p?.other?.discharge_destination?.expired / p?.other?.total_count * 100).toFixed(1)}%`,
        }}
      />
    </Flex>
  )
}

function OutpatientTable({ l, data, targetName, comparison_group, service_line }) {
  const { individual: i, population: p } = data;
  return (
    <Flex column style={{ paddingRight: 20, transform: 'translateY(-20px)' }}>
      <Section 
        label='Number of Patients'
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: i?.robotic?.total_count,
          laparoscopic: i?.laparoscopic?.total_count,
          open: i?.open?.total_count,
          other: i?.other?.total_count,
        }}
        population={{
          robotic: p?.robotic?.total_count,
          laparoscopic: p?.laparoscopic?.total_count,
          open: p?.open?.total_count,
          other: p?.other?.total_count,
        }}
      />
      <Section 
        label='Avg Procedure Time (mins)'
        l={l} service_line={service_line} targetName={targetName} comparison_group={comparison_group}
        individual={{
          robotic: i?.robotic?.procedure_time_avg.toFixed(1),
          laparoscopic: i?.laparoscopic?.procedure_time_avg.toFixed(1),
          open: i?.open?.procedure_time_avg.toFixed(1),
          other: i?.other?.procedure_time_avg.toFixed(1),
        }}
        population={{
          robotic: p?.robotic?.procedure_time_avg.toFixed(1),
          laparoscopic: p?.laparoscopic?.procedure_time_avg.toFixed(1),
          open: p?.open?.procedure_time_avg.toFixed(1),
          other: p?.other?.procedure_time_avg.toFixed(1),
        }}
      />
    </Flex>
  )
}

function Section({ l, service_line, label, targetName, comparison_group, individual: i, population: p }) {
  return (
    <Flex f={1} alss style={{ marginTop: 20 }}>
      <Flex column f={.3} style={{ marginRight: 20 }}>
        <Flex style={{ fontWeight: 'bold', fontSize: 12 }}>{label}</Flex>
        <Flex alss style={{ marginLeft: 5, marginTop: 5, minWidth: 50, minHeight: 14 }}>{targetName}</Flex>
        <Flex alss style={{ marginLeft: 5, marginTop: 5, minWidth: 50, minHeight: 14 }}>{comparison_group}</Flex>
      </Flex>
      <Flex f={.65} jcsb>
        <Flex column f={1} style={{ marginRight: 20 }}>
          <Flex style={{ fontWeight: 'bold', fontSize: 12 }}>Robotic</Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{i?.robotic}</span></Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{p?.robotic}</span></Flex>
        </Flex>
        <Flex column f={1} style={{ marginRight: 20 }}>
          <Flex style={{ fontWeight: 'bold', fontSize: 12 }}>{surgicalMethodAlias(service_line, 'laparoscopic', { Laparoscopic: 'Lap' })}</Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{i?.laparoscopic}</span></Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{p?.laparoscopic}</span></Flex>
        </Flex>
        <Flex column f={1} style={{ marginRight: 20 }}>
          <Flex style={{ fontWeight: 'bold', fontSize: 12 }}>Open</Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{i?.open}</span></Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{p?.open}</span></Flex>
        </Flex>
        <Flex column f={1}>
          <Flex style={{ fontWeight: 'bold', fontSize: 12 }}>Other</Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{i?.other}</span></Flex>
          <Flex alss className={l ? 'load-placeholder' : ''} style={{ marginTop: 5, minWidth: 50, minHeight: 14 }}><span style={{ opacity: l ? 0 : 1}}>{p?.other}</span></Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const styles = {
  card: { 
    display: 'flex', 
    flex: 1,
    alignSelf: 'stretch',
    margin: '.5vw', 
    fontSize: '12px',
    flexWrap: 'wrap', 
    overflowWrap: 'normal'
  },
  pvalue: {
    marginLeft: '12px',
    fontSize: '10px',
    minWidth: '50px'
  },
  cell: {
    marginBottom: '9px'
  }
}

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  // backgroundColor: 'grey',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
