export default {
  hospitals: [
    { 
      uuid: '0e5023d5-c355-459d-8c9c-9eaeaf48a6ee', 
      name: 'Advocate South Suburban',
      state_region_province: 'IL',
      service_lines: [
        'general_surgery',
        'gynecology',
        'urology',
        'gastroenterology'
      ],
    },
    { 
      uuid: '0f37171e-71f6-4058-a2a9-5ff97e07275a', 
      name: 'Advocate Condell Medical Center',
      state_region_province: 'IL',
      service_lines: [
        'gastroenterology',
        'general_surgery'
      ],
    },
    { 
      uuid: '795e6e14-6e77-4a64-872f-23cb390247a5',
      name: 'Illinois Masonic Medical Center',
      state_region_province: 'WI',
      service_lines: [
        'urology',
        'gynecology'
      ],
    }
  ],
  surgeons: [
    { 
      uuid: '1e5023d5-c355-459d-8c9c-9eaeaf48a6ee', 
      name: 'Brooks Pike',
      site_uuids: [
        '0e5023d5-c355-459d-8c9c-9eaeaf48a6ee'
      ],
      service_line: 'general_surgery'
    },
    { 
      uuid: '2e5023d5-c355-459d-8c9c-9eaeaf48a6ee', 
      name: 'Blah Blah',
      site_uuids: [
        '0f37171e-71f6-4058-a2a9-5ff97e07275a'
      ],
      service_line: 'gynecology'
    },
    { 
      uuid: '3e5023d5-c355-459d-8c9c-9eaeaf48a6ee', 
      name: 'Mc Hammer',
      site_uuids: [
        '0f37171e-71f6-4058-a2a9-5ff97e07275a'
      ],
      service_line: 'urology',
    },
    { 
      uuid: '4e5023d5-c355-459d-8c9c-9eaeaf48a6ee', 
      name: 'CHIP EDWARD BECK SMITH',
      site_uuids: [
        '0f37171e-71f6-4058-a2a9-5ff97e07275a'
      ],
      service_line: 'urology',
    },
  ],
  demographics: { 
    individual: {
      total_encounters: 80,
      age: {
        '18-29' : 2000, 
        '30-50': 5000, 
        '>50': 2400
      },
      race: {
        white: 20,                                
        black_or_african_american: 15,
        asian_or_pacific_islander: 25,
        other: 20
      },
      sex: {
        male: 10, 
        female: 22, 
        other: 7
      },
      bmi: {
        '<25': 10, 
        '25-30': 20, 
        '>30': 40,
        missing: 10
      },
      smoker: {
        yes: 10
      },
      comorbidities: [
        { key: 'peripheral vascular disease', value: 21 },
        { key: 'congestive heart failure', value: 10 },
        { key: 'ascites', value: 10 },
      ]
    },
    population: {
      total_encounters: 200,
      age: {
        '18-29' : 43, 
        '30-50': 135, 
        '>50': 400
      },
      race: {
        asian: 92, 
        american_indian_or_alaska_native: 42, 
        black_or_african_american: 40, 
        white: 80,
        native_hawaiian_or_pacific_islander: 72,
        other: 20
      },
      sex: {
        male: 89, 
        female: 120, 
        other: 10
      },
      bmi: {
        '<25': 100, 
        '25-30': 200, 
        '>30': 80
      },
      smoker: {
        yes: 80
      },
      comorbidities: [
        { key: 'peripheral vascular disease', value: 38 },
        { key: 'congestive heart failure', value: 29 },
        { key: 'ascites', value: 10 }
      ]
    },
    p_value: {
      age: 0.012322,
      race: 'NaN\n',
      sex: 0.2323,
      smoker: 0.232423,
      comorbidities: [
        { key: 'peripheral vascular disease', value: .0121 },
        { key: 'congestive heart failure', value: .0321 },
        { key: 'ascites', value: .0321 }
      ]
    }
  },
  encounterTimeStatistics: {
      individual: {
          open: {
              total_encounters: 3,
              length_of_stay_days_avg: 2.3333333333333335,
              procedure_time_avg: 111,
              readmissions_percentage: 0.6666666666666666
          },
          laparoscopic: {
              total_encounters: 9,
              length_of_stay_days_avg: 0,
              procedure_time_avg: 76.44444444444444,
              readmissions_percentage: 0.52
          },
          robotic: {
              total_encounters: 36,
              length_of_stay_days_avg: 9.263157894736842,
              procedure_time_avg: 143.6451612903226,
              readmissions_percentage: 0.2
          }
      },
      population: {
          open: {
              total_encounters: 48,
              length_of_stay_days_avg: 7.979166666666667,
              procedure_time_avg: 200.55882352941177,
              readmissions_percentage: 0.0625
          },
          laparoscopic: {
              total_encounters: 109,
              length_of_stay_days_avg: 8.285714285714286,
              procedure_time_avg: 88.02857142857142,
              readmissions_percentage: 0.009174311926605505
          },
          robotic: {
              total_encounters: 300,
              length_of_stay_days_avg: 4.024271844660194,
              procedure_time_avg: 152.76616915422886,
              readmissions_percentage: 0.029197080291970802
          }
      },
      p_value: {
          length_of_stay_days_avg: 0.40309343646605755,
          procedure_time_avg: 0.4722720255341504,
          readmissions_percentage: 0.44488480033468886
      },
  },
  dischargeDisposition: {
    individual: {
      open: {
        total_encounters: 100,
        home: 10,
        home_health: 20,
        hospice_or_home_hospice: 30,
        snf: 15,
        expired: 20,
        other: 5
      },
      laparoscopic: {
        total_encounters: 100,
        home: 10,
        home_health: 20,
        hospice_or_home_hospice: 30,
        snf: 15,
        expired: 20,
        other: 5
      },
      robotic: {
        total_encounters: 100,
        home: 10,
        home_health: 20,
        hospice_or_home_hospice: 30,
        snf: 15,
        expired: 20,
        other: 5
      },
    },
    population: {
      open: {
        total_encounters: 200,
        home: 15,
        home_health: 45,
        hospice_or_home_hospice: 20,
        snf: 70,
        expired: 40,
        other: 10
      },
      laparoscopic: {
        total_encounters: 200,
        home: 20,
        home_health: 40,
        hospice_or_home_hospice: 60,
        snf: 30,
        expired: 40,
        other: 10
      },
      robotic: {
        total_encounters: 200,
        home: 20,
        home_health: 40,
        hospice_or_home_hospice: 60,
        snf: 30,
        expired: 40,
        other: 10
      },
    },
    p_value: 0.121342
  },
  comorbidities: [
    {
      site_name: 'hospital_1',
      weights: [
        { key: 'ascites', value: 0.12232 },
        { key: 'copd', value: 0.333 },
        { key: 'hypertension', value: 0.111 },
        { key: 'cardiac', value: 0.221 },
        { key: 'obesity', value: 0.352 },
        { key: 'diabetes', value: 0.232 },
        { key: 'peripheral vascular disease', value: 0.342 },
        { key: 'congestive heart failure', value: 0.232 }
      ]
    },
    {
      site_name: 'Lutheran General Hospital',
      weights: [
        { key: 'ascites', value: 0.100 },
        { key: 'copd', value: 0.111 },
        { key: 'hypertension', value: 0.222 },
        { key: 'cardiac', value: 0.187 },
        { key: 'obesity', value: 0.393 },
        { key: 'diabetes', value: 0.400 },
        { key: 'peripheral vascular disease', value: 0.432 },
        { key: 'congestive heart failure', value: 0.232 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 },
        { key: 'Uncaptured', value: 0.342 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 },
        { key: 'Uncaptured', value: 0.342 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 },
        { key: 'Uncaptured', value: 0.342 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 },
        { key: 'Uncaptured', value: 0.342 }
      ]
    },
    {
      site_name: 'Advocate Condell Medical Center',
      weights: [
        { key: 'ascites', value: 0.2178 },
        { key: 'copd', value: 0.348 },
        { key: 'hypertension', value: 0.2347 },
        { key: 'cardiac', value: 0.3472 },
        { key: 'obesity', value: 0.4326 },
        { key: 'diabetes', value: 0.342 },
        { key: 'peripheral vascular disease', value: 0.142 },
        { key: 'congestive heart failure', value: 0.232 },
        { key: 'Uncaptured', value: 0.342 }
      ]
    },
  ],
  contributing_risk: {
    individual: [
      { key: 'Procedure Time', value: .921321 },
      { key: 'Age', value: 0.321 },
      { key: 'Port clutch use duration', value: -0.0121 },
      { key: 'Primary Diagnosis', value: 0.111 },
      { key: 'Instrument 1 use', value: 0.2121 },
      { key: 'Gender', value: 0.1021 },
      { key: 'Camera control on/off', value: -0.0121 },
      { key: 'ASA Score', value: -0.4231 },
      { key: 'Head in / Head out', value: -0.2112 },
      { key: 'Secondary CPT', value: -0.3 },
    ],
    population: [
      { key: 'Procedure duration', value: 0.121321 },
      { key: 'Age', value: 0.321 },
      { key: 'Port clutch use duration', value: -0.0121 },
      { key: 'Primary Diagnosis', value: 0.111 },
      { key: 'Instrument 1 use', value: 0.2121 },
      { key: 'Gender', value: 0.1021 },
      { key: 'Camera control on/off', value: -0.0121 },
      { key: 'ASA Score', value: -0.4231 },
      { key: 'Head in / Head out', value: -0.2112 },
      { key: 'Secondary CPT', value: -0.3 },
    ],
  },
  timeseries: {
    population: [
      {
        "date": "2018-01-01",
        "procedure_time_mins_avg": 99.6,
        "length_of_stay_days_avg": 4.2,
      },
      {
        "date": "2018-07-01",
        "procedure_time_mins_avg": 99.6,
        "length_of_stay_days_avg": 4.2,
      },
      {
        "date": "2022-11-01",
        "procedure_time_mins_avg": 99.6,
        "length_of_stay_days_avg": 4.2,
      },
      {
        "date": "2021-05-01",
        "procedure_time_mins_avg": 199.6,
        "length_of_stay_days_avg": 14.2,
      },
      {
        "date": "2021-06-01",
        "procedure_time_mins_avg": 201.8,
        "length_of_stay_days_avg": 12.1,
      },
      {
        "date": "2021-07-01",
        "procedure_time_mins_avg": 198.6,
        "length_of_stay_days_avg": 11.1
      },
      {
        "date": "2021-01-30",
        "procedure_time_mins_avg": 11.1,
        "length_of_stay_days_avg": 20.6
      },
      {
        "date": "2021-01-10",
        "procedure_time_mins_avg": 21,
        "length_of_stay_days_avg": 40.6
      },
      {
        "date": "2020-10-10",
        "procedure_time_mins_avg": 20.6,
        "length_of_stay_days_avg": 21.1
      },
      {
        "date": "2020-11-03",
        "procedure_time_mins_avg": 10.6,
        "length_of_stay_days_avg": 21.1
      },
      {
        "date": "2020-12-03",
        "procedure_time_mins_avg": 10.6,
        "length_of_stay_days_avg": 21.1
      },
    ],
    individual: [
      {
        "date": "2018-01-01",
        "procedure_time_mins_avg": 39.6,
        "length_of_stay_days_avg": 4.2,
      },
      {
        "date": "2018-07-01",
        "procedure_time_mins_avg": 49.6,
        "length_of_stay_days_avg": 4.2,
      },
      {
        "date": "2022-11-01",
        "procedure_time_mins_avg": 199.6,
        "length_of_stay_days_avg": 14.2,
      },
      {
        "date": "2021-07-01",
        "procedure_time_mins_avg": 99.6,
        "length_of_stay_days_avg": 14.2,
      },
      {
        "date": "2021-06-01",
        "procedure_time_mins_avg": 201.8,
        "length_of_stay_days_avg": 12.1,
      },
      {
        "date": "2021-05-01",
        "procedure_time_mins_avg": 198.6,
        "length_of_stay_days_avg": 11.1
      },
      {
        "date": "2021-01-30",
        "procedure_time_mins_avg": 20.6,
        "length_of_stay_days_avg": 11.1
      },
      {
        "date": "2021-01-10",
        "procedure_time_mins_avg": 40.6,
        "length_of_stay_days_avg": 21.1
      },
      {
        "date": "2020-11-03",
        "procedure_time_mins_avg": 40.6,
        "length_of_stay_days_avg": 21.1
      },
      {
        "date": "2020-10-10",
        "procedure_time_mins_avg": 40.6,
        "length_of_stay_days_avg": 21.1
      },
      {
        "date": "2020-12-03",
        "procedure_time_mins_avg": 10.6,
        "length_of_stay_days_avg": 21.1
      },
    ]
  },
  overview_aggregation: [
    {
      site_uuid: '0e5023d5-c355-459d-8c9c-9eaeaf48a6ee',
      site_name: 'Advocate South Suburban',
      values: {
        open: {
          total_encounters: 10,
          length_of_stay_days_avg: 3,
          procedure_time_avg: 50,
          readmissions_percentage: 0.6666666666666666
        },
        laparoscopic: {
          total_encounters: 40,
          length_of_stay_days_avg: 2.3333333333333335,
          procedure_time_avg: 210,
          readmissions_percentage: 0.4
        },
        robotic: {
          total_encounters: 200,
          length_of_stay_days_avg: 4.3,
          procedure_time_avg: 112,
          readmissions_percentage: 0.7
        },
      }
    },
    {
      site_uuid: '0f37171e-71f6-4058-a2a9-5ff97e07275a',
      site_name: 'Advocate Condell Medical Center',
      values: {
        open: {
          total_encounters: 8,
          length_of_stay_days_avg: 3.2,
          procedure_time_avg: 80,
          readmissions_percentage: 0.6666666666666666
        },
        laparoscopic: {
          total_encounters: 39,
          length_of_stay_days_avg: 5,
          procedure_time_avg: 78,
          readmissions_percentage: 0.4
        },
        robotic: {
          total_encounters: 125,
          length_of_stay_days_avg: 4.3,
          procedure_time_avg: 232,
          readmissions_percentage: 0.7
        },
      }
    },
    {
      site_uuid: '795e6e14-6e77-4a64-872f-23cb390247a5',
      site_name: 'Illinois Masonic Medical Center',
      values: {
        open: {
          total_encounters: 20,
          length_of_stay_days_avg: 2.3333333333333335,
          procedure_time_avg: 111,
          readmissions_percentage: 0.6666666666666666
        },
        laparoscopic: {
          total_encounters: 86,
          length_of_stay_days_avg: 2.3333333333333335,
          procedure_time_avg: 120,
          readmissions_percentage: 0.4
        },
        robotic: {
          total_encounters: 90,
          length_of_stay_days_avg: 4.3,
          procedure_time_avg: 91,
          readmissions_percentage: 0.7
        },
      }
    },
  ]
}