
export default function Flex ({
  f,

  column = false,

  jcc,
  jcsb,
  jcsa,
  jcfe,
  jcse,

  aic,
  aife,
  aibl,

  alss,
  alsc,

  children,
  build,

  bg,
  color,  
  fontSize,
  bold,
  
  style,
  
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  reference
}) {

  return <div
    ref={reference}
    className={className || ''}
    style={{
      display: 'flex',
      flex: f || null,
      flexDirection: 
        column ? 'column' : 
        'row',
      justifyContent: 
        jcc ? 'center' :
        jcsb ? 'space-between' :
        jcsa ? 'space-around' :
        jcse ? 'space-evenly' :
        jcfe ? 'flex-end':
        'flex-start',
      alignItems: 
        aic ? 'center' :
        aife ? 'flex-end':
        aibl ? 'baseline':
        'flex-start',
      alignSelf: 
        alss ? 'stretch' :
        alsc ? 'center' :
        null,

      border: 
        build ? `1px solid ${build === true  ? 'red' : build}` : 
        null,
      backgroundColor: bg || null,
      color: color || null,
      fontSize: fontSize || null,
      fontWeight: bold ? 700 : null,
      ...style
    }}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    >{children}</div>
}