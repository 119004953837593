import { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import Flex from '../_global/Flex.js';
import Characteristics from "./Characteristics.js";
import Outcomes from "./Outcomes.js";
import ComorbidityRadar from "./ComorbidityRadar.js";
import api from '../_api/api.js';
import { upperCaseFirst } from "../helpers.js";
import { useAppContext } from '../AppContext';


export default function(){
  const { session, appliedTargetSelection: ats, sites, handleSetPDFReferences } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const pdfRef1 = useRef();
  const pdfRef2 = useRef();
  useEffect(() => { handleSetPDFReferences([pdfRef1, pdfRef2]) }, []);
  
  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const demographics = await api.getDemographicsV2(ats, sites, session.role_access);
        setData({ 
          demographics, 
          comorbidities_weights: [
            { 
              site_name: ats.target_name, 
              weights: demographics.individual.characteristics.comorbidities.map(({ key, value }) => ({ 
                key, 
                value: value > 0 ? (value / demographics.individual.characteristics.total_count).toFixed(2) : 0, 
              }))
            },
            { 
              site_name: upperCaseFirst(ats.comparison_group_name) || 'Other Hospitals', 
              weights: demographics.population.characteristics.comorbidities.map(({ key, value }) => ({ 
                key, 
                value: value > 0 ? (value / demographics.population.characteristics.total_count).toFixed(2) : 0, 
              }))
            },
          ]
        });
      } catch(err) {
        console.log(err);
        setData(null);
      }
      setLoading(false);
    }());
  }, [ats]);

  return (
    <Box sx={{ marginTop: { xs: '100px', md: '70px', xl: '70px' }, marginLeft: { xs: 0, md: '18.5rem', xl: '18.5rem' }, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1, paddingBottom: 1 }}>
      <Flex reference={pdfRef1} f={1} column jcc aic alss style={{ maxWidth: '650px' }}>
        <Characteristics
          loading={loading} 
          appliedTargetSelection={ats}
          data={{
            individual: data?.demographics?.individual?.characteristics,
            population: data?.demographics?.population?.characteristics,
            p_value: data?.demographics?.p_value?.characteristics
          }}
        />
      </Flex>
      <Flex reference={pdfRef2} column jcc f={1} style={{ maxWidth: '650px' }}>
        <ComorbidityRadar
          loading={loading} 
          appliedTargetSelection={ats}
          data={data?.comorbidities_weights} 
        />
        <Outcomes
          loading={loading}
          appliedTargetSelection={ats}
          data={{
            individual: data?.demographics?.individual?.surgical_method_outcomes,
            population: data?.demographics?.population?.surgical_method_outcomes
          }}
        />
      </Flex>
    </Box>
  );
}
