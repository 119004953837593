import Flex from "../_global/Flex";
import { surgicalMethodAlias } from "../helpers";
import { colorMap } from '../theme.js';
import { upperCaseFirst } from "../helpers";


export default function ({ loading, volumes, appliedTargetSelection: ats }) {
  return (
      <Flex jcsb>
          <Flex column jcsb style={{}}>
              {loading ? <Flex style={{ height: 12, width: 100}} className={'load-placeholder'} /> :  <Flex>{ats?.target_name} = {volumes.individual.robotic + volumes.individual.laparoscopic + volumes.individual.open + volumes.individual.other}</Flex>}
              <div style={{ height: 10 }}/>
              <Flex>
                    <Flex>
                        <Flex column>
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Robotic'}
                                color={colorMap.individual.robotic}
                                value={volumes.individual.robotic}
                            />
                            <KeyValuePair 
                                loading={loading} 
                                nicename={surgicalMethodAlias(ats?.service_line, 'Laparoscopic', { Laparoscopic: 'Lap' })}
                                color={colorMap.individual.laparoscopic}
                                value={volumes.individual.laparoscopic}
                            />
                        </Flex>
                        <Flex column>
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Open'}
                                color={colorMap.individual.open}
                                value={volumes.individual.open}
                            />
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Other'}
                                color={colorMap.individual.other}
                                value={volumes.individual.other}
                            />
                        </Flex>
                    </Flex>
              </Flex>
          </Flex>
          <Flex column jcsb style={{}}>
              {loading ? <Flex style={{ height: 12, width: 100}} className={'load-placeholder'} /> :  <Flex>{ats?.comparison_group_name ? upperCaseFirst(ats.comparison_group_name) : 'Other Hospitals'} = {volumes.population.robotic + volumes.population.laparoscopic + volumes.population.open + volumes.population.other}</Flex>}
              <div style={{ height: 10 }}/>
              <Flex>
                    <Flex>
                        <Flex column>
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Robotic'}
                                color={colorMap.population.robotic}
                                value={volumes.population.robotic}
                            />
                            <KeyValuePair 
                                loading={loading} 
                                nicename={surgicalMethodAlias(ats?.service_line, 'Laparoscopic', { Laparoscopic: 'Lap' })}
                                color={colorMap.population.laparoscopic}
                                value={volumes.population.laparoscopic}
                            />
                        </Flex>
                        <Flex column>
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Open'}
                                color={colorMap.population.open}
                                value={volumes.population.open}
                            />
                            <KeyValuePair 
                                loading={loading} 
                                nicename={'Other'}
                                color={colorMap.population.other}
                                value={volumes.population.other}
                            />
                        </Flex>
                    </Flex>
              </Flex>
          </Flex>
      </Flex>
  )
}

function KeyValuePair ({ loading, nicename, value, color }) {
    return (
        <Flex style={{ marginRight: 10, width: 100, marginTop: 5 }}>
            <Flex style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: color, marginRight: 4 }}/>
            {loading ? <Flex style={{ height: 12, width: 100}} className={'load-placeholder'} /> :  <Flex style={{ fontSize: 12, transform: 'translateY(-2px)'}}>{nicename} = {value}</Flex>}
        </Flex>
    );
}
