import { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { Box, Card, CardContent } from '@mui/material';
import Flex from "../_global/Flex.js";
import Legend from './Legend.js';
import { upperCaseFirst } from '../helpers.js';

const surgicalMethodNicenames = {
    'open': 'Open',
    'laparoscopic': 'Laparoscopic',
    'robotic': 'Robotic'
}

const modelNicename = {
    thirty_day_readmission: 'Readmission',
    thirty_day_readmission_robotic: 'Readmission',
    non_home_discharge: 'Discharge Disposition',
    non_home_discharge_robotic: 'Discharge Disposition'
};

const defaultMinMax = { min: Infinity, max: 0 };

export default function({ loading, data, appliedTargetSelection: ats }) {
    const [chartData, setChartData] = useState({ individual: [], population: [] });
    const [minMaxState, setMinMax] = useState(defaultMinMax);

    useEffect(() => {
        const individualResponse = formatChartData(data?.individual);
        const populationResponse = formatChartData(data?.population);
        const minMax = {
            min: individualResponse?.minMax?.min < populationResponse?.minMax?.min ? individualResponse?.minMax?.min : populationResponse?.minMax?.min,
            max: individualResponse?.minMax?.max > populationResponse?.minMax?.max ? individualResponse?.minMax?.max : populationResponse?.minMax?.max,
        }
        setMinMax(minMax);
        setChartData(prev => ({
            ...prev,
            individual: individualResponse?.chartData,
            population: populationResponse?.chartData
        }));
    }, [data]);

    function formatChartData(data_raw = []) {
        const minMax = { ...defaultMinMax };
        if (!data_raw || data_raw.length === 0) return;
        const sorted = data_raw
            .map(d => {
                if (d.value < 0 && d.value < minMax.min) minMax.min = d.value; 
                if (d.value > 0 && d.value > minMax.max) minMax.max = d.value; 
                return { 
                    ...d, 
                    value_abs: Math.abs(d.value),
                    color: d.value >= 0 ? 
                        d.feature_type === 'robotic' ? '#FFD7A1' : '#FF9A13' : 
                        d.feature_type === 'robotic' ? '#92CFFF' : '#4A8EC3'
                }
            })
            .sort((a, b) => a.value_abs > b.value_abs ? -1 : 1)
            .slice(0, 10)
            .reverse();
        
        return {
            minMax,
            chartData: [
                {
                    x: sorted.map(({ value }) => value),
                    y: sorted.map(({ key }) => key === 'Procedure Time' ? 'OR Time' : key), /* REMOVE LATER */
                    type: 'bar',
                    orientation: 'h',
                    marker: { color: sorted.map(({ color }) => color) }
                }
            ]
        }
    }

    return (
        <Card style={styles.card}>
            <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '.5vw' }}>
                <Flex jcsb style={{ flexWrap: 'wrap', marginBottom: '20px'  }}>
                    <div style={{ height: '20px' }}><b>Top Contributing Risk Factors for {modelNicename[ats?.model_name]}</b></div>
                    {/* <div>{service_line} {dateString}</div> */}
                </Flex>
                {/*MOBILE*/}
                <Box sx={{ display: { xs: 'flex', md: 'none', xl: 'none' }, flexWrap: 'wrap', alignSelf: 'stretch', alignItems: 'center' }} >
                    <Chart loading={loading} data={chartData.individual} targetName={ats?.target_name} isMobile surgical_method={ats?.surgical_method} range={ [minMaxState.min - .01, minMaxState.max]}/>
                    <Chart loading={loading} data={chartData.population} targetName={upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'} isMobile surgical_method={ats?.surgical_method} range={ [minMaxState.min - .01, minMaxState.max]}/>
                </Box>
                {/*WEB*/}
                <Box sx={{  display: { xs: 'none', md: 'flex', xl: 'flex' }, flexWrap: 'wrap', alignSelf: 'stretch', flex: 1, alignItems: 'center' }}>
                    <Chart loading={loading} data={chartData.individual} targetName={ats?.target_name} surgical_method={ats?.surgical_method} range={ [minMaxState.min - .01, minMaxState.max]}/>
                    <Chart loading={loading} data={chartData.population} targetName={upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'} surgical_method={ats?.surgical_method} range={ [minMaxState.min - .01, minMaxState.max]}/>
                </Box>
                <Legend surgical_method={ats?.surgical_method}/>
            </CardContent>
        </Card>
    )
}


function Chart({ data, isMobile, targetName, loading, surgical_method, range }) {
    if (loading) return (
        <Flex alss style={{ transform: 'translate(20px, 10px)'}}>
            <Flex column jcc aic style={{ width: isMobile ? 400 : 500, height: isMobile ? 400 : 400 }}>
                <Flex className={'load-placeholder'} style={{ width: isMobile ? 120 : 200, height: isMobile ? 10 : 20, transform: 'translate(-20px, -10px)' }}></Flex>
                <Flex>
                    <Flex column jcsb alss style={{ paddingRight: '20px', paddingTop: '15px', paddingBottom: '35px' }}>
                        {[...Array(10)].map((_, i) => (
                            <Flex className={'load-placeholder'} key={i} style={{ width: 110, height: 10 }}></Flex> 
                        ))}
                    </Flex>
                    <Flex column style={{ width: 300, height: 300 }}>
                        <Flex column  style={{ transform: 'translateX(110px)'}}>
                            {[80, 60, 40, 30, 20].map((offset, i) => (
                                <Flex key={i} style={{ 
                                    width: 10 + Math.abs(offset), 
                                    height: 22,
                                    marginTop: 2,
                                    transform: `translateY(${i + 1 * 10}px)`, 
                                }} className={'load-placeholder'}></Flex>
                            ))}
                        </Flex>
                        <Flex column aife >
                            {[-100, -65, -40, -30, -10].map((offset, i) => (
                                <Flex key={i} style={{ 
                                    width: 10 + Math.abs(offset), 
                                    height: 22,
                                    marginTop: 2,
                                    transform: `translateY(${i + 1 * 10}px)`, 
                                }} className={'load-placeholder'}></Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
    if (!data) return (
        <Flex column aic style={{ 
            width: isMobile ? 400 : 500, 
            height: isMobile ? 400 : 400,
            paddingTop: 20
        }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${targetName} - ${surgicalMethodNicenames[surgical_method]}`}</div>
            <div style={{ fontSize: '16px', marginTop: 100 }}>No data found for current configuration</div>
        </Flex>
    );
    return (
        <Plot 
            data={data}
            layout={{
                width: isMobile ? 400 : 500,
                height: isMobile ? 400 : 400,
                hovermode: false, 
                title: `${targetName} - ${surgicalMethodNicenames[surgical_method]}`, 
                font: {
                    size: isMobile ? 8 : undefined,
                    family: 'Roboto, sans-serif',
                    color: '#000000',
                },  
                xaxis: {
                    range,
                    automargin: true,
                },
                yaxis: {
                    automargin: 'left',
                }, 
                showlegend: false,
            }}
            config={{ displayModeBar: false }} 
        />
    );
}

const styles = {
    card: { 
      flex: 1,
      display: 'flex', 
      alignSelf: 'stretch',
      margin: '.5vw', 
      fontSize: '12px', 
      flexWrap: 'wrap', 
      overflowWrap: 'normal'
    }
}
