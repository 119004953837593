import { useEffect, useState } from 'react'
import Login from './Login.js'
import Report from './Report';
import authAdmin from './_api/api.js';
import * as sessions from './_api/sessions.js';
import ResetPassword from './ResetPassword.js';
import './index.css';

function App() {
  const [isLoggedIn, toggleLoginState] = useState(false);
  const [isLoginError, toggleLoginError] = useState(false);
  const [initalDataIsReady, toggleInitialDataIsReady] = useState(false);
  const [session, setSession] = useState({});
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/pwreset') setResetPassword(true);
    else setResetPassword(false);
    (async function () {
      const sessionRes = sessions.getSession();
      if (!sessionRes.user_identifier || !sessionRes.portal) return logout();
      setSession(sessionRes);
      toggleLoginState(true);
      toggleInitialDataIsReady(false);
    }());
  }, []);

  // Watch initial data readiness
  useEffect(() => {}, [initalDataIsReady])

  function resetLoginError() {
    toggleLoginError(false)
  }

  async function logout() {
    sessions.closeSession();
    setSession({});
    toggleLoginState(false);
  }
  
  async function login({ email, password }) {
    try {
      await authAdmin.login({ email, password });
      toggleLoginState(true);
      toggleLoginError(false);
      toggleInitialDataIsReady(false);
      setSession(sessions.getSession());
    } catch(err) {
      console.log(err.message);
      toggleLoginError(true);
    }
  }

  function handleToggleDataReady(isReady) {
    toggleInitialDataIsReady(isReady || !initalDataIsReady);
  }

  function handleSetResetPassword(bool = false) {
    setResetPassword(bool);
  }

  if (resetPassword) return (
    <div style={styles.container}>
      <ResetPassword handleSetResetPassword={handleSetResetPassword} />
    </div>
  )

  return (
    <div style={styles.container}>
      {session.portal === 'admin' ? <Report
        session={session}
        logout={logout} 
        handleToggleDataReady={handleToggleDataReady}
      /> : null}
      {(!isLoggedIn || !initalDataIsReady) ? <Login
        session={session}
        login={login}
        isLoginError={isLoginError}
        isLoggedIn={isLoggedIn}
        resetLoginError={resetLoginError}
      /> : null}
    </div>
  );
}

export default App;

const styles = {
  container: { display: 'flex', flexDirection: 'column', flex: 1, width: '100vw', minHeight: '100vh', backgroundColor: '#ebebeb' }
}