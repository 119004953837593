import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Flex from '../_global/Flex.js';
import ScatterPlot from './ScatterPlot.js';
import BarChart from './BarChart.js';
import LineGraph from './LineGraph.js';
import api from '../_api/api.js';
import { useAppContext } from '../AppContext';


export default function() {
  const { session, appliedTargetSelection: ats, sites, handleSetPDFReferences } = useAppContext();

  const [data, setData] = useState(null);
  const [appliedTargetSelectionLocal, setAppliedTargetSelectionLocal] = useState({});
  const [loadingDemographics, setLoadingDemographics] = useState(false);
  const [loadingOutcomesOverTime, setLoadingOutcomesOverTime] = useState(false);
  const [volumes, setVolumes] = useState({ individual: { robotic: 0, laparoscopic: 0, open: 0, other: 0 }, population: { robotic: 0, laparoscopic: 0, open: 0, other: 0 }});

  const pdfRef1 = useRef();
  useEffect(() => { handleSetPDFReferences([pdfRef1]) }, []);

  useEffect(() => {
    // Decide if updating data is necessary for this page
    // The following two endpoints return all surgical methods to populate the default "all"
    // shouldRequestData will check if only the relevant parameters have changed - If they haven't abort extra requests
    if (!shouldRequestData()) return;
    handleDemographicsRequest();
    handleOutcomesOverTimeRequest();
  }, [ats]);

  function shouldRequestData() {
    setAppliedTargetSelectionLocal(ats);
    return createAtsHash(ats) !== createAtsHash(appliedTargetSelectionLocal);
  }

  function createAtsHash(a) {
    return `
      ${a.service_line}
      ${a.target_uuid}
      ${a.encounter_type}
      ${a.admit_type}
      ${a.comparison_group_name}
      ${a.comparison_group_uuids}
      ${a.procedure_identifiers}
      ${a.start_date}
      ${a.end_date}
    `;
  }

  async function handleDemographicsRequest() {
    setLoadingDemographics(true);
    try {
      const dataPrep = {}; // tacks on response data depending on config
      const demographics = await api.getDemographicsV2(ats, sites, session.role_access);
      const ets = { 
        individual: {
            robotic: demographics?.individual?.surgical_method_outcomes?.robotic,
            laparoscopic: demographics?.individual?.surgical_method_outcomes?.laparoscopic,
            open: demographics?.individual?.surgical_method_outcomes?.open,
            other: demographics?.individual?.surgical_method_outcomes?.other
        },
        population: {
            robotic: demographics?.population?.surgical_method_outcomes?.robotic,
            laparoscopic: demographics?.population?.surgical_method_outcomes?.laparoscopic,
            open: demographics?.population?.surgical_method_outcomes?.open,
            other: demographics?.population?.surgical_method_outcomes?.other
        }
      }
      // procedure time is the only one that will incorporate outpatient counts
      setVolumes({
        individual: {
            robotic: ets.individual.robotic.total_count,
            laparoscopic: ets.individual.laparoscopic.total_count,
            open: ets.individual.open.total_count,
            other: ets.individual.other.total_count
        },
        population: {
            robotic: ets.population.robotic.total_count,
            laparoscopic: ets.population.laparoscopic.total_count,
            open: ets.population.open.total_count,
            other: ets.population.other.total_count
        }
      });
      if (ats.comparison_group_uuids.length > 0 && ats.target_type === 'site') {
        dataPrep.overview_aggregation = await api.getOverviewAggregation({ ...ats, site_uuids: [ats.target_uuid, ...ats.comparison_group_uuids] });
      } else {
        dataPrep.ets = ets;
      }
      setData(prev => ({ ...prev, ...dataPrep }));
    } catch(err) {
      console.log(err);
      setData(null);
    } finally {
      setLoadingDemographics(false);
    }
  }

  async function handleOutcomesOverTimeRequest() {
      setLoadingOutcomesOverTime(true);
      try {
        const outcomes_over_time = await api.getOutcomesOverTime(ats, sites, session.role_access);
        setData(prev => ({ ...prev, outcomes_over_time }));
      } catch(err) {
        console.log(err);
        setData(prev => ({ ...prev, outcomes_over_time: null }));
      } finally {
        setLoadingOutcomesOverTime(false);
      }
  }

  return (
    <Box sx={{ marginTop: { xs: '100px', md: '70px', xl: '70px' }, marginLeft: { xs: 0, md: '18.5rem', xl: '18.5rem' }, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1, paddingBottom: 1 }}>

      <Flex reference={pdfRef1} style={{ flexWrap: 'wrap' }}>
        <ScatterPlot
          loading={loadingDemographics}
          appliedTargetSelection={ats}
          data={data}
          volumes={volumes}
        />
        <BarChart
          loading={loadingDemographics}
          appliedTargetSelection={ats}
          data={data}
          volumes={volumes}
        />
        <LineGraph 
          loading={loadingOutcomesOverTime}
          appliedTargetSelection={ats}
          data={data?.outcomes_over_time}
          volumes={volumes}
        />
        <Flex style={{ minWidth: 650, height: 1, margin: '.5vw' }} f={1}/> {/*Aids alignment*/}
      </Flex>
    </Box>
  );
};