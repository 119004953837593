import { useEffect, useState} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import Flex from '../_global/Flex.js';
import api from '../_api/api.js';
const dayjs = require('dayjs');


export default function CustomTimeframeSelector({ isCustomTimeframeOpen, handleSetStagedSelection, handleToggleCustomTimeframeOpen, maxRangeDates }) {
    const [selectedDateRange, setSelectedDateRange] = useState({ start_date: null, end_date: null });
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!maxRangeDates) return;
        setSelectedDateRange({
            start_date: dayjs(new Date(maxRangeDates.end_date)).subtract(1, 'month').format('YYYY-MM-DD'), 
            end_date: maxRangeDates.end_date
        });
    }, []);

  function handleChangeSelected({ start_date, end_date }) {
      setSelectedDateRange({start_date, end_date });
      setError(null);
      if (start_date > end_date || start_date === end_date) return setError('Start date must be before end date');
      setError(null);
      setSelectedDateRange({ start_date, end_date });
  }

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={isCustomTimeframeOpen} onClose={_ => handleToggleCustomTimeframeOpen(false)}>
          <DialogTitle sx={{display: 'flex', flexDirection: { xs: 'column', md: 'row'}, justifyContent: 'space-between'}}>
              <Box sx={{fontSize: { xs: 15 }, display: 'flex'}} >Custom Time Period</Box>
              <Box sx={{fontSize: 13, display: 'flex'}}><b>Last known record: </b> &nbsp; {dayjs(new Date(maxRangeDates.end_date)).format('MM/DD/YYYY')}</Box>
          </DialogTitle>
          <DialogContent sx={{minHeight: 300, maxWidth: { xs: 500 }}}>  
              <Box sx={{display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row'}}}>
                  <DatePicker label="Start Date" 
                      value={dayjs(selectedDateRange?.start_date)}
                      minDate={dayjs(maxRangeDates?.start_date)}
                      maxDate={dayjs(maxRangeDates.end_date).subtract(1, 'day')}
                      onChange={start_date => handleChangeSelected({ ...selectedDateRange, start_date: start_date.format('YYYY-MM-DD') })}
                      sx={{margin:1}}/>
                  <DatePicker label="End Date"
                      value={dayjs(selectedDateRange?.end_date)}
                      minDate={dayjs(maxRangeDates?.start_date).add(1, 'day')}
                      maxDate={dayjs(maxRangeDates.end_date)}
                      onChange={end_date => handleChangeSelected({ ...selectedDateRange, end_date: end_date.format('YYYY-MM-DD') })}
                      sx={{ margin:1 }}/>
              </Box>
          </DialogContent>
          <Flex jcsb alss style={{flexDirection: 'row-reverse'}}>
          <DialogActions>
              <Box sx={{display: 'flex', gap: 2, margin: '1vw'}}>
                  <Button sx={styles.regularButton} onClick={_ => handleToggleCustomTimeframeOpen(false)} >Cancel</Button>
                  <Button disabled={!!error} onClick={_ => {
                      handleSetStagedSelection(selectedDateRange);
                      handleToggleCustomTimeframeOpen(false);
                  }} sx={styles.popupApplyButton}>Done</Button>
              </Box>
          </DialogActions>
          {error ? <Box sx={{ color: 'red', display: 'flex', justifyContent: 'center', alignSelf: 'center', fontSize: 12, fontWeight: 700, marginLeft: { xs: 2, md: 4 }, marginBottom: 4, marginTop: '3vw'}}>{error}</Box> : null}
          </Flex>
      </Dialog>
      </LocalizationProvider>
  );
}

const styles = {
  regularButton: {
      background: '#FFFFFF',
      color: '#707070',
      justifyContent:'center', 
      maxWidth: '5rem', 
      border: '1px solid #707070', 
      borderRadius: '4.8px', 
      textTransform: 'none',
  },
  popupApplyButton: { 
      background: '#098BEF', 
      color: '#FFFFFF', 
      justifyContent:'center', 
      border: '1px solid #098BEF', 
      borderRadius: '4px', 
      textTransform: 'none',
      '&:hover': { background: '#0D6EFD' },
      '&.Mui-disabled': {
          background: '#CECECE',
          color: '#FFFFFF',
          border: '1px solid #CECECE'
      },
  }
};
