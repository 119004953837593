import { styled } from '@mui/material/styles';
import { ListItem, Accordion, AccordionDetails, AccordionSummary, Typography, Menu, Button } from '@mui/material/';
import { ExpandMore as ExpandMoreIcon, Close as CloseIcon, AddCircleOutline as AddIcon } from '@mui/icons-material';
import Flex from '../_global/Flex.js';

import { useEffect, useState } from 'react';

const colorsByChipType = {
  site: '#0367B4',
  service_line: '#FF9A13'
};

const stateMap = {
  IL: 'Illinois',
  WI: 'Wisconsin'
}

export default function ({ surgeons, expanded, handleExpansion, surgeon_uuid_selected, sitesByState = [], specialtyNicenameMap = {}, handleSetStagedSelection, appliedValueText, appliedServiceLine, appliedTargetSelection: ats }) {
  const [surgeonsFiltered, setSurgeonsFiltered] = useState([]);
  const [selectionMenuAnchor, setSelectionMenuAnchor] = useState(null);
  const [filters, setFilters] = useState([]);

  // Respect previous service_line decision on mount with filter selection(
  useEffect(() => {
    if (!appliedServiceLine) return;
    setFilters([{ type: 'service_line', nicename: specialtyNicenameMap[appliedServiceLine], value: appliedServiceLine }]);
  }, []);

  useEffect(() => {
    if (filters.length === 0) return setSurgeonsFiltered(surgeons);

    const filterMap = filters.reduce((acc, { type, value }) => {
      acc[type][value] = 1;
      return acc;
    }, { service_line: {}, site: {} });

    const filteredSurgeons = surgeons.filter(({ site_uuids, service_line }) => {
      if (Object.keys(filterMap.service_line).length > 0 && !filterMap.service_line[service_line]) return false;
      if (Object.keys(filterMap.site).length > 0 && !site_uuids.some(uuid => filterMap.site[uuid])) return false;
      return true;
    });
    setSurgeonsFiltered(filteredSurgeons);
    const [initialSurgeon] = filteredSurgeons;
    if (ats.target_type !== 'surgeon') handleSetStagedSelection({
      target_uuid: initialSurgeon.uuid,
      target_name: initialSurgeon.name,
      surgeon_sites: initialSurgeon.site_uuids,
      surgeon_states: initialSurgeon.site_states
    });
  }, [surgeons, filters]);

  function handleToggleSelectionOpen(anchorEl) {
    setSelectionMenuAnchor(anchorEl);
  }

  function handleSetFilter(newFilter) {
    setFilters(newFilter);
  }

  return (
    <ListItem divider sx={{ position: 'relative', flexDirection: 'column', alignItems: 'flex-start', marginTop: 0, paddingLeft: 2, paddingBottom: .5, marginBottom: 0, paddingTop: 0 }}>
      <Accordion expanded={expanded} sx={{ width: '100%' }} disableGutters={true} elevation={0}>
        <AccordionSummary onClick={handleExpansion} expandIcon={<ExpandMoreIcon />}>
        <Typography typography={'accordion'}>Target</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ paddingRight: 0, maxHeight: 500, overflow: 'scroll' }}>

          <Flex column alss style={{ transform: 'translateY(-5px)', width: '100%' }}>
            <Flex alss jcsb aic style={{ paddingRight: 15 }}>
              <span style={{ fontSize: '10px', color: '#6e6e6e', fontStyle: 'italic', marginBottom: 5 }}>Applied Filters</span>
              <AddIcon onClick={(e) => handleToggleSelectionOpen(e.currentTarget)} style={{ color: '#0367B4', fontSize: 26, cursor: 'pointer', transform: 'translateY(-4px)' }} />
            </Flex>

             {/* CHIPS */}
            {filters.length > 0 ? <Flex f={1} aic style={{ flexWrap: 'wrap', color: 'white' }}>
              {filters.map(({ type, nicename, value }, i) => 
                <Flex key={i} aic style={{ ...styles.chip, backgroundColor: colorsByChipType[type] }} onClick={() => {
                  const newFilter = [...filters];
                  newFilter.splice(filters.indexOf(filters.find(({ value: val }) => val === value)), 1);
                  handleSetFilter(newFilter);
                }}>
                  <Flex column f={1}>
                    <span style={{ fontSize: 8 }}>{type === 'site' ? 'Hospital' : 'Specialty'}</span>
                    <span style={{ fontSize: 8 }}>{nicename}</span>
                  </Flex>
                  <Flex style={{ width: 2 }}/>
                  <CloseIcon color='white' fontSize='10px' />
                </Flex>
              )}
              <Flex style={{ width: 6 }}/>
            </Flex> : 
            <Flex aic>
              <div style={{ fontSize: 10, color: '#6e6e6e', fontStyle: 'italic' }}>No filters selected</div>
            </Flex>}
            
            <Menu
              id="simple-menu"
              anchorEl={selectionMenuAnchor}
              keepMounted
              open={Boolean(selectionMenuAnchor)}
              onClose={() => handleToggleSelectionOpen(null)}
            >
              <MenuContent 
                filters={filters}
                sitesByState={sitesByState} 
                specialtyNicenameMap={specialtyNicenameMap}
                handleToggleSelectionOpen={handleToggleSelectionOpen}
                handleSetFilter={handleSetFilter}
              />
            </Menu>
          </Flex>

          <Flex column>
            {surgeonsFiltered.map(({ uuid, name, service_line, site_states, site_uuids }, index) => (
              <Flex column key={index} alss jcsb
              onClick={() => handleSetStagedSelection({ 
                target_uuid: uuid, 
                target_name: name,
                surgeon_sites: site_uuids,
                surgeon_states: site_states,
                service_line: service_line || 'general_surgery',
                test: service_line
              })}>
                {uuid !== surgeon_uuid_selected ? <Typography style={styles.available}>{name}</Typography> : null}
                {uuid === surgeon_uuid_selected ? <Typography style={styles.selected}>{name}</Typography> : null}
              </Flex>
            ))}
          </Flex>
        </AccordionDetails>
      </Accordion>
      <div style={{ marginLeft: 15, transform: 'translateY(-6px)', fontSize: 12, fontStyle: 'italic', color: '#6e6e6e', opacity: .7 }}>{appliedValueText}</div>
    </ListItem>
  );
}

function MenuContent({ filters = [], sitesByState = {}, specialtyNicenameMap = {}, handleToggleSelectionOpen, handleSetFilter }) {
  const [listType, setListType] = useState('site');
  const [list, setList] = useState({ sitesByState: [], service_line: [] });

  useEffect(() => {
    setList(Object.entries(sitesByState).reduce((acc, [state, sites]) => {
      acc.sitesByState.push({ state, sites });
      return acc;
    }, { sitesByState: [], service_line: [] }));
  }, [sitesByState]);

  return (
    <Flex column style={{ width: 200, overflow: 'hidden', padding: 10, paddingTop: 4, fontSize: 12, fontWeight: 'bold' }}>
      <Flex alss column>
        <Flex alss aic jcsb>
          <span style={{ fontSize: 14 }}>Add New Filter</span>
          <span style={{ padding: 5, cursor: 'pointer' }} onClick={() => handleToggleSelectionOpen(null)}>Close</span>
        </Flex>

        <Flex style={{ height: 10 }} />

        <Flex alss jcc style={{ transform: 'translateY(-5px)'}}>
          <BootstrapButton onClick={() => setListType('site')} style={{ backgroundColor: listType === 'site' ? '#2198F3' : '#D6DBE0', width: 75, height: 24, fontSize: 10, fontFamily: 'Roboto', color: listType === 'site' ? 'white' : 'black', borderColor: 'white', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} size="small" variant='contained'>Hospitals</BootstrapButton>
          <BootstrapButton onClick={() => setListType('service_line')} style={{ backgroundColor: listType === 'service_line' ? '#2198F3' : '#D6DBE0', width: 75, height: 24, fontSize: 10, fontFamily: 'Roboto', color: listType === 'service_line' ? 'white' : 'black', borderColor: 'white', borderTopRightRadius: 20, borderBottomRightRadius: 20, transform: 'translateX(-4px)'}} size="small" variant='contained'>Specialties</BootstrapButton>
        </Flex>
      </Flex>
      
      <Flex style={{ height: 10 }} />

      <Flex alss column style={{ maxHeight: 300, overflow: 'scroll', borderTop: '1px solid #e0e0e0' }}>

        {listType === 'site' ? list.sitesByState.map(({ state, sites }, i) => (
          <Flex column alss key={i}>
            <span style={{ color: '#6e6e6e', fontStyle: 'italic', padding: '5px', paddingLeft: '0px', marginTop: '10px' }}>{stateMap[state]}</span>
            <Flex alss column key={i}>
              {sites?.filter(({ uuid }) => !filters.some(({ type, value }) => type === 'site' && value === uuid))
                .map((site, i) => {
                const name = (site.name || '').length > 18 ? site.name.replace('Hospital', '').replace('Medical Center', '') : site.name;
                return <Flex key={i} alss aic jcsb>
                  <Flex f={1} alss style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => {
                    handleSetFilter([ ...filters, { type: 'site', nicename: name, value: site.uuid }]);
                    handleToggleSelectionOpen(null);
                  }}>
                    <Typography typography={'accordionsub'} sx={{ padding: 1, color: '#333333', fontSize: '13px', fontWeight: 400}}>
                      {name}
                    </Typography>
                  </Flex>
                </Flex>
              })}
            </Flex>
          </Flex>
        )) : null }


        {listType === 'service_line' ? Object.entries(specialtyNicenameMap)
          .filter(([service_line]) => !filters.some(({ type, value }) => type === 'service_line' && value === service_line))
          .map(([value, nicename], i) => (
          <Flex key={i} alss aic jcsb>
            <Flex f={1} alss style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => {
              handleSetFilter([ ...filters, { type: 'service_line', nicename, value }]);
              handleToggleSelectionOpen(null);
            }}>
              <Typography typography={'accordionsub'} sx={{ padding: 1, color: '#333333', fontSize: '13px', fontWeight: 400}}>
                {nicename}
              </Typography>
            </Flex>
          </Flex>
        )) : null}
      </Flex>
    </Flex>
  )
}


const styles = {
  chip: {
    margin: 1,
    marginBottom: 2,
    borderRadius: 15, 
    padding: 5, 
    paddingLeft: 10, 
    paddingRight: 5,
    cursor: 'pointer'
  },
  available: {
    padding: 6, 
    display: 'flex',
    cursor: 'pointer',
    color: '#333333',
    fontWeight: 400, 
    fontSize: '14px',
    width: '100%'
  },
  selected: { 
    padding: 6, 
    display: 'flex',
    cursor: 'pointer',
    color: '#0084ff',
    fontWeight: 700, 
    fontSize: '14px',
    width: '100%'

  },
  disabled: {
    padding: 6, 
    display: 'flex',
    color: '#D3D3D3',
    fontWeight: 400, 
    fontSize: '14px',
    width: '100%'
  }
}

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  // backgroundColor: 'grey',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
  available: {
    padding: 6, 
    display: 'flex',
    cursor: 'pointer',
    color: '#333333',
    fontWeight: 400, 
    fontSize: '14px',
    width: '100%'
  },
  selected: { 
    padding: 6, 
    display: 'flex',
    cursor: 'pointer',
    color: '#0084ff',
    fontWeight: 700, 
    fontSize: '14px',
    width: '100%'
  },
});
