import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';
import { Card, CardContent, Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Flex from '../_global/Flex.js';
import { upperCaseFirst, surgicalMethodAlias } from '../helpers.js';
import { colorMap } from '../theme.js';


export default function({ loading, data, appliedTargetSelection: ats }) {
    const [population, setPopulation] = useState(null);
    const [individualCount, setIndividualCount] = useState({ robotic: 0, laparoscopic: 0, open: 0, other: 0 });
    const [yMax, setYMax] = useState({ procedure_time_mins_avg: 0, los_days_avg: 0, readmission_percentage: 0, non_home_discharge_percentage: 0 });

    useEffect(() => {
        if (!data) return;
        let newYMax = { ...yMax };
        setIndividualCount(data.individual.reduce((acc, p) => {
            acc[p.identifier] = { ...p.count_total };
            newYMax = handleSetYMax(newYMax, p);
            return acc;
        }, {}));
        setPopulation(data.population.reduce((acc, p) => {
            acc[p.identifier] = { ...p };
            newYMax = handleSetYMax(newYMax, p);
            return acc;
        }, {}));
        setYMax(newYMax);
    }, [data]);


    function handleSetYMax(newYMax, outcomes) {
        ['procedure_time_mins_avg', 'los_days_avg', 'readmission_percentage', 'non_home_discharge_percentage'].forEach(key => {
            Object.values(outcomes[key]).forEach(value => {
                if (value > newYMax[key]) {
                    newYMax[key] = value;
                }
            });
        });
        return newYMax;
    }

    function buildChartData(procedureIdentifier, outcomeName, { robotic, laparoscopic, open, other }) {
        if (!population) return;
        const pop = population?.[procedureIdentifier]?.[outcomeName];
        if (!pop) return;

        const fixing = outcomeName === 'procedure_time_mins_avg' ? 0 : 1;
        const yIndividual = [
            robotic.toFixed(fixing), 
            laparoscopic.toFixed(fixing), 
            open.toFixed(fixing),
            other.toFixed(fixing)
        ];
        const yPopulation = [
            pop.robotic.toFixed(fixing), 
            pop.laparoscopic.toFixed(fixing), 
            pop.open.toFixed(fixing),
            pop.other.toFixed(fixing)
        ];
        let maxRangeY = yMax[outcomeName];
        [...yIndividual, ...yPopulation].forEach(n => {
            if (n > maxRangeY) maxRangeY = n;
        });

        return {
            chartData: [
                {
                    x: ['Robotic', surgicalMethodAlias(ats?.service_line, 'laparoscopic'), 'Open', 'Other'],
                    y: yPopulation,
                    type: 'bar',
                    name: '',
                    marker: { color: [colorMap.population.robotic, colorMap.population.laparoscopic, colorMap.population.open, colorMap.population.other] }, // array of colors for each bar
                    width: .6,
                    text: yPopulation.map((yp, i) => parseFloat(yp) + parseFloat(yIndividual[i]) > 0 && parseFloat(yp) >= parseFloat(yIndividual[i]) ? `<b>${yIndividual[i]}</b><br>${yp}` : undefined),
                    textposition: 'outside',
                    textfont: { size: 10, color: 'black' }       
                },
                {
                    x: ['Robotic', surgicalMethodAlias(ats?.service_line, 'laparoscopic'), 'Open', 'Other'],
                    y: yIndividual,
                    type: 'bar',
                    name: '',
                    marker:  { color: [colorMap.individual.robotic, colorMap.individual.laparoscopic, colorMap.individual.open, colorMap.individual.other] }, // array of colors for each bar
                    width: .3,
                    offset: -.15,
                    text: yIndividual.map((yi, i) => parseFloat(yi) > parseFloat(yPopulation[i]) ? `<b>${yi}</b><br>${yPopulation[i]}` : undefined),  // displayed on the bar
                    textposition: 'outside',
                    textfont: { size: 10, color: 'black' }   
                },
            ],
            maxRangeY
        }
    } 

    if (!data) return (
        <Flex column alss>
        {[1,2,3].map((_, i) =>
            <Card key={i} style={styles.card}>
                <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
                    {loading && <Loading />}
                </CardContent>
            </Card>
        )}
        </Flex>
    );
    return (
        <div>
            {data?.individual
                .filter(p => ats?.procedure_identifiers.length === 0 || ats?.procedure_identifiers.includes(p.identifier))
                .sort((a, b) => a.identifier < b.individual ? -1 : 1)
                .map((procedure, i) =>
                <Card key={i} style={styles.card}>
                    <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
                        {loading && <Loading />}
                        {!loading && <Flex column alss>
                            <Flex aibl><span style={{ fontWeight: 'bold', fontSize: 15 }}>{procedure?.nicename}</span><span style={{ width: 10 }}/></Flex>
                            <div style={{ height: 20 }}/>
                            <Flex alss jcsb style={{ transform: 'translateX(40px)'}}>
                                {[
                                    { nicename: 'Avg. Procedure Time', value: 'procedure_time_mins_avg', yLabel: 'min' }, 
                                    { nicename: 'Avg. Length of Stay', value: 'los_days_avg', yLabel: 'days' }, 
                                    { nicename: 'Avg. Readmission', value: 'readmission_percentage', yLabel: '%' }, 
                                    { nicename: 'Avg. Non-Home Discharge', value: 'non_home_discharge_percentage', yLabel: '%' }
                                ]
                                .map(({ nicename, value, yLabel }, i) => {
                                    const { chartData, maxRangeY } = buildChartData(procedure.identifier, value, procedure[value]) || { chartData: null, maxRangeY: null };
                                    return (
                                        <Flex column key={i} style={{ 
                                            /* Maintain length, but hide if outpatient and not procedure_time */
                                            opacity: ats?.encounter_type !== 'outpatient' || value === 'procedure_time_mins_avg' ? 1 : 0,
                                            maxWidth: 300,
                                            marginLeft: 40
                                        }}>
                                            <Flex style={{ marginLeft: 40 }}>
                                              <div style={{ fontWeight: 'bold' }}>{nicename}</div>
                                              {value === 'procedure_time_mins_avg' && <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
                                            </Flex>
                                            <Chart data={chartData} maxRangeY={maxRangeY} yTitle={yLabel} />
                                        </Flex>
                                    )
                                })}
                            </Flex>
                            {/* LEGEND */}
                            <Flex>
                                <Flex column jcsb style={{ transform: 'translate(0px, -110px)' }}>
                                    <Flex>{ats?.target_name} = {procedure?.count_total?.all}</Flex>
                                    <div style={{ height: 10 }}/>
                                    <Flex>
                                        {[
                                            { nicename: 'Robotic', value: 'robotic' },
                                            { nicename: 'Lap', value: 'laparoscopic' },
                                            { nicename: 'Open', value: 'open' },
                                            { nicename: 'Other', value: 'other' },
                                        ].map(({ nicename, value }, i) => (
                                            <Flex key={i} style={{ marginRight: 20, width: 100 }}>
                                                <Flex style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: colorMap.individual[value], marginRight: 6 }}/>
                                                <Flex style={{ fontSize: 12, transform: 'translateY(-2px)'}}>{surgicalMethodAlias(ats?.service_line, value, { Laparoscopic: 'Lap' })} = {individualCount?.[procedure.identifier]?.[value]}</Flex>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                                <Flex column jcsb style={{ transform: 'translate(0px, -110px)' }}>
                                    <Flex>{upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'} = {population?.[procedure.identifier]?.count_total?.all}</Flex>
                                    <div style={{ height: 10 }}/>
                                    <Flex>
                                        {[
                                            { nicename: 'Robotic', value: 'robotic' },
                                            { nicename: 'Lap', value: 'laparoscopic' },
                                            { nicename: 'Open', value: 'open' },
                                            { nicename: 'Other', value: 'other' }
                                        ].map(({ nicename, value }, i) => (
                                            <Flex key={i} style={{ marginRight: 20, width: 100 }}>
                                                <Flex style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: colorMap.population[value], marginRight: 6 }}/>
                                                <Flex style={{ fontSize: 12, transform: 'translateY(-2px)'}}>{surgicalMethodAlias(ats?.service_line, value, { Laparoscopic: 'Lap' })} = {population?.[procedure.identifier]?.count_total?.[value]}</Flex>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Flex>
                            
                        </Flex>}
                    </CardContent>
                </Card>
             )}
        </div>
    );
}

function Loading() {
    return (
        <Flex style={{ position: 'relative' }}>
            <Flex className={'load-placeholder'} style={{ width: 220, height: 20, position: 'absolute', left: 10, top: 10 }}/>
            {[1,2,3,4].map((_, i) => 
            <Flex key={i} f={1} alss aic style={{ width: 300, height: 320, marginTop: 80, transform: 'translateY(-60px)' }}>
                <Flex style={{ width: 20, height: 180, marginRight: 10, marginBottom: 20 }} />
                <Flex column aic style={{ width: 600, height: 250 }}>
                    <Flex className={'load-placeholder'} f={1} alss/>
                    <Flex style={{ width: 200, height: 20, marginTop: 10 }} />
                </Flex>
            </Flex>)}
        </Flex>
    )
}

function Chart({ data, isMobile, yTitle, maxRangeY }) {
    return (
        <Plot 
            data={data} 
            layout={{   
                barmode: 'group',
                bargap: 0,
                bargroupgap: 0,
                font: {
                    size: isMobile ? 9 : 10
                },
                width: 400,
                height: 320,
                tickfont: {
                    family: 'Roboto',
                    size: isMobile ? 8 : 10,
                },
                xaxis: {
                    showticklabels: false
                },
                yaxis: {
                    title: yTitle,
                    range: [0, maxRangeY + (maxRangeY * .25) || 5],
                },
                hovermode: false,
                showlegend: false,
                plot_bgcolor: 'rgba(0,0,0,0)',
                paper_bgcolor: 'rgba(0,0,0,0)',
            }} 
            style={{ transform: 'translate(-90px, -70px)'}}
            config={{ displayModeBar: false }} 
        />
    );
    
}

const styles = {
    card: { 
        flex: 1,
        margin: 10, 
        fontSize: '12px', 
        flexWrap: 'wrap', 
        padding: 5,
        height: 330
    },
    circle: {
        width: '11px',
        height: '11px',
        borderRadius: '50%'
    },
    legendText: {
        marginLeft: 3
    }
}