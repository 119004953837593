import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Flex from '../_global/Flex.js';
import { Card, CardContent, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { visuallyHidden } from '@mui/utils';

const { REACT_APP_SURGEON_THRESHOLD = '0.85,0.1' } = process.env
const [thresholdUpper, thresholdLower] = REACT_APP_SURGEON_THRESHOLD.split(',').map(v => parseFloat(v));

const cellColors = {
  blue: '#B3D7F3',
  orange: '#ECC79C',
  blue_washed: '#D4E2EE',
  orange_washed: '#EBD9C3'
};

export default function ({ procedure_name, rows = [], selected_surgeon_uuid, loading, encounter_type, handleNavChange }) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('count_total');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [emptyRows, setEmptyRows] = React.useState([]);

  React.useEffect(() => {
    const empty = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(empty);
    const comparator = getComparator(order, orderBy);
    const visRows = stableSort(rows, comparator).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setVisibleRows(visRows);
  }, [rows, order, orderBy, page, rowsPerPage]);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].outcomes, b[0].outcomes);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy])  return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card style={styles.card}>
      <CardContent style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
        {loading && <Loading />}
        {!loading && <Box sx={{ width: '100%' }}>
          <EnhancedTableToolbar procedure_name={procedure_name} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                procedure_name={procedure_name}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map(({ name, uuid, outcomes: o }, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer', border: selected_surgeon_uuid  === uuid ? `2px solid #589acc` : null }}>
                      <TableCell sx={{ width: '1%' }} style={{ fontWeight: 'bold', backgroundColor: null }} padding="checkbox"></TableCell>
                      <TableCell sx={{ width: '5%' }} style={{ fontSize: 12, backgroundColor: null }} component="th" id={labelId} scope="row" padding="none">
                        {`${name.split(' ').pop().charAt(0).toUpperCase() + name.split(' ').pop().slice(1).toLowerCase()}, ${name.split(' ').slice(0, -1).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}`}
                      </TableCell>
                      <TableCell sx={{ width: '10%' }} style={{ fontSize: 12, backgroundColor: cellColor(o.count_total_rank) }} align="right">{o.count_total}</TableCell>
                      <TableCell sx={{ width: '14%'}} style={{ fontSize: 12, backgroundColor: cellColor((o.procedure_time_mins_avg_rank - 1) * -1, true) }} align="right">{o.procedure_time_mins_avg.toFixed(1)}</TableCell>
                      <TableCell sx={{ width: '10%', opacity: encounter_type === 'outpatient' ? 0 : 1 }} style={{ fontSize: 12, backgroundColor: cellColor((o.los_days_avg_rank - 1) * -1, true) }} align="right">{o.los_days_avg.toFixed(1)}</TableCell>
                      <TableCell sx={{ width: '10%', opacity: encounter_type === 'outpatient' ? 0 : 1 }} style={{ fontSize: 12, backgroundColor: cellColor((o.readmission_percentage_rank - 1) * -1, true) }} align="right">{(o.readmission_percentage * 100).toFixed(1)}</TableCell>
                      <TableCell sx={{ width: '14%', opacity: encounter_type === 'outpatient' ? 0 : 1 }} style={{ fontSize: 12, backgroundColor: cellColor((o.non_home_discharge_percentage_rank - 1) * -1, true) }} align="right">{(o.non_home_discharge_percentage * 100).toFixed(1)}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>}
      </CardContent>
      <Flex jcsb style={{ width: 300, height: 30, position: 'absolute', bottom: 20, left: 20}}>
        <Flex aic>
          <Flex style={{ height: 20, width: 20, backgroundColor: cellColors.blue }}/>
          <span style={{ width: 5 }}/>
          <span style={{ fontSize: 12 }}>Top {100 - (parseFloat(REACT_APP_SURGEON_THRESHOLD.split(',')[0]) * 100)}th Percentile</span>
        </Flex>
        <Flex aic>
          <Flex style={{ height: 20, width: 20, backgroundColor: cellColors.orange }}/>
          <span style={{ width: 5 }}/>
          <span style={{ fontSize: 12 }}>Bottom {parseFloat(REACT_APP_SURGEON_THRESHOLD.split(',')[1]) * 100}th Percentile</span>
        </Flex>
      </Flex>
      <Flex style={{ fontSize: 16, color: 'black', textWrap: 'nowrap', position: 'absolute', top: 25, right: 20, fontSize: 14 }}><div to='/surgeons' style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleNavChange('Surgeon Overview')}>See All Procedures</div></Flex>
    </Card>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {[
          {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Surgeon',
          },
          {
            id: 'count_total',
            numeric: true,
            disablePadding: false,
            label: 'Volume',
          },
          {
            id: 'procedure_time_mins_avg',
            numeric: true,
            disablePadding: false,
            label: 'Avg. Procedure Time (mins)',
          },
          {
            id: 'los_days_avg',
            numeric: true,
            disablePadding: false,
            label: 'Avg. LoS (days)',
          },
          {
            id: 'readmission_percentage',
            numeric: true,
            disablePadding: false,
            label: 'Readmission (%)',
          },
          {
            id: 'non_home_discharge_percentage',
            numeric: true,
            disablePadding: false,
            label: 'Non-Home Discharge (%)',
          },
        ].map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ position: 'relative' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
              {headCell.id === 'procedure_time_mins_avg' && <Box sx={{ position: 'absolute', right: 0, bottom: 0, transform: 'translateX(10px)', zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
    <Typography
      sx={{ flex: '1 1 100%' }}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      {props.procedure_name}
    </Typography>
    </Toolbar>
  );
}

function cellColor(pct, wash = false) {
  if (wash) return pct >= thresholdUpper ? cellColors.blue_washed : pct <= thresholdLower ? cellColors.orange_washed : null;
  return pct >= thresholdUpper ? cellColors.blue : pct <= thresholdLower ? cellColors.orange : null;
}

function Loading() {
  return (
      <Flex f={1} column style={{ position: 'relative', height: 800 }}>
          <Flex className={'load-placeholder'} style={{ width: 220, height: 30, position: 'absolute', left: 10, top: 10 }}/>
          <Flex style={{ height: 50 }}/>
          {[1,2,3,4,5,6,7,8,9,10].map((_, i) => 
            <Flex key={i} alss aic style={{ width: 1000, marginTop: 5, transform: 'translate(10px, 50px)' }}>
                <Flex f={1} alss column aic style={{ height: 40 }}>
                    <Flex className={'load-placeholder'} f={1} alss/>
                </Flex>
            </Flex>
          )}
          <Flex style={{ height: 110 }}/>
      </Flex>
  )
}

const styles = {
  card:{
    position: 'relative',
    maxWidth: 1200,
    display: 'flex',
    alignSelf: 'stretch',
    margin: '8px 10px 0 0',
    padding: 15
  }
};
