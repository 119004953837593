import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SurgeonOverview from './SurgeonOverview.js';
import api from '../_api/api.js';
import { useAppContext } from '../AppContext';


export default function () {
  const { session, appliedTargetSelection: ats, sites } = useAppContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {        
        setLoading(true);
        const data = await api.getSurgeonOverview(ats, sites, session.role_access);
        setLoading(false);
        setData(data);
      } catch(err) {
        console.log(err);
        setData(null);
      }
    }());
  }, [ats]);

  return (
    <Box sx={{ marginTop: { xs: '100px', md: '70px', xl: '70px' }, marginLeft: { xs: 0, md: '18.5rem', xl: '18.5rem' }, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'stretch', flex: 1, paddingBottom: 1 }}>
      <SurgeonOverview
        loading={loading}
        data={data}
        appliedTargetSelection={ats}
      />
    </Box>
  )
}