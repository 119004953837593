import { useEffect, useState} from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Typography, colors } from '@mui/material/';
import { Tune as TuneIcon, Close as CloseIcon, CheckBoxOutlineBlank } from '@mui/icons-material';
import Content from './Content.js';
import CustomTimeframeSelector from './CustomTimeframe.js';
import api from '../_api/api.js';


export default function({ role_access, page, sites, appliedTargetSelection, handleSetAppliedTargetSelection, specialtyNicenameMap, maxRangeDates }) {
    const [applyAvailable, setApplyAvailable] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(false);
    const [sitesByState, setSitesByState] = useState({});
    const [stagedSite, setStagedSite] = useState(null);
    const [population, setPopulation] = useState({ selectedPopulation: [] });
    const [isCustomTimeframeOpen, setIsCustomTimeframeOpen] = useState(false);
    const [stagedSelection, setStagedSelection] = useState({});
    const [surgeons, setSurgeons] = useState([]);

    useEffect(() => {
        const initialSite = sites.find(({ uuid }) => appliedTargetSelection.target_uuid === uuid);
        setStagedSite(initialSite);
    }, []);

    useEffect(() => {
        setSitesByState(sites.reduce((acc, site) => {
            if (!acc[site.state_region_province]) acc[site.state_region_province] = [];
            acc[site.state_region_province].push(site);
            return acc;
        }, {}));
        setPopulation({ selectedPopulation: appliedTargetSelection.comparison_group_uuids });
    }, [sites, appliedTargetSelection]);

    useEffect(() => {
        if (Object.keys(stagedSelection).length > 0) setApplyAvailable(true);
        else setApplyAvailable(false);
    }, [stagedSelection]);

    function handleSetStagedSelection(selection) {
        if (selection.target_type === 'surgeon') { // When Surgeon radio button is first hit - set default comparsion_group_name
            selection.comparison_group_name = 'department';
        }
        if (selection.target_type === 'site') { // When Site radio button is hit - set default the inital site as selected
            selection.target_uuid = sites[0].uuid;
            selection.target_name = sites[0].name;
            selection.comparison_group_name = null;
        }
        // If new target selection was in the comparison group, select and remove from comparison group 
        // set the staged site selection
        if ((selection.target_type || stagedSelection.target_type || appliedTargetSelection.target_type) === 'site') {
            if (selection.target_uuid) setStagedSite(sites.find(s => s.uuid === selection.target_uuid));
            if (selection.target_type && population.selectedPopulation.includes(selection.target_uuid)) handlePopulationChange(selection.target_uuid, false);
        }
        if ( // Whipe out the procedure identifiers if there is a change in service_line - protects from no procedures in other sl bug
            (selection.target_type && selection.target_type !== appliedTargetSelection.target_type) ||
            (selection.service_line && selection.service_line !== appliedTargetSelection.service_line) // ||
            // (selection.surgeon_service_line && selection.surgeon_service_line !== appliedTargetSelection.surgeon_service_line) - refactor
        ) {
            selection.procedure_identifiers = [];
        }
        setStagedSelection(prev => ({ ...prev, ...selection}));
    }

    async function apply() {
        setActiveDropdown(false);
        handleSetAppliedTargetSelection(stagedSelection);
        setStagedSelection({}); // reset staged selection after apply
    }

    function handleToggleCustomTimeframeOpen(value) { 
        setIsCustomTimeframeOpen(value || !isCustomTimeframeOpen);
     };
  
    function resetSelectedPopulation() {
        setPopulation({ selectedPopulation: [] });
        handleSetStagedSelection({ comparison_group_uuids: [] });
    }

    function handlePopulationChange(uuid, is_checked) {
        const newArr = [...population.selectedPopulation];
        if (is_checked) newArr.push(uuid);
        else newArr.splice(newArr.indexOf(uuid), 1);
        handleSetStagedSelection({ comparison_group_uuids: newArr });
        setPopulation((prev) => {
            const newArr = [...prev.selectedPopulation];
            if (is_checked) newArr.push(uuid);
            else newArr.splice(newArr.indexOf(uuid), 1);
            return { ...prev, selectedPopulation: newArr }
        });
    }

    function handleSetComparisonGroup(comparison_group_name) {
        handleSetStagedSelection({ comparison_group_name });
    }

    async function fetchSurgeons() {
        try {
            const response = await api.getSurgeons();
            setSurgeons(response.filter(({ service_line }) => service_line));
            handleSetStagedSelection({ target_uuid: appliedTargetSelection.target_uuid || response[0].uuid })
        } catch(err) {
            console.log(err);
        }
    }

    return (
        <Box sx={{ marginRight: { xs: null, md: '20px' }, zIndex: 1, alignSelf: 'stretch', position: 'fixed', top: 0, left: 0, height: '100%' }}>
            {/*Mobile Bar*/}
            <Box sx={{ display: {xs: 'flex', md: 'none'}, position: 'fixed', top: 0, marginTop: '50px', width: '100%', flexDirection: 'column', background:'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px'}}>
                <List sx={{ margin: 0 }}>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {activeDropdown && <CloseIcon sx={{ alignSelf: 'center', '&:hover': { cursor: 'pointer' }}} onClick={() => setActiveDropdown(false)}/>}
                            {!activeDropdown && <CheckBoxOutlineBlank  sx={{ color: 'white' }}/>}
                            <Box onClick={() => setActiveDropdown(true)} sx={{ display: 'flex', '&:hover': { cursor: 'pointer' }}}>
                                <ListItemText sx={{ alignSelf: 'center', mr: '1rem', ml: '1rem', fontWeight: 600 }} primary={<Typography typography={'accordion'}>Filters</Typography>}/>
                                <ListItemIcon sx={{ alignSelf: 'center' }}>
                                    <TuneIcon style={{ color: 'black', transform: 'rotate(90deg)' }}></TuneIcon>
                                </ListItemIcon>
                            </Box>
                            {!activeDropdown && <CheckBoxOutlineBlank  sx={{color: 'white'}}/>}
                            {activeDropdown && 
                            <ListItemButton sx={{ ...(applyAvailable ? styles.applyButton : styles.applyButtonDisabled) }} onClick={() => { 
                                if (applyAvailable) apply() 
                            }}>Apply</ListItemButton>}
                        </Box>
                    </ListItem>
                </List>
                {activeDropdown && 
                    <Content
                        role_access={role_access} 
                        page={page} 
                        sitesByState={sitesByState} 
                        surgeons={surgeons}
                        specialtyNicenameMap={specialtyNicenameMap} 
                        appliedTargetSelection={appliedTargetSelection}
                        stagedSite={stagedSite} 
                        stagedSelection={stagedSelection} 
                        selectedPopulation={population.selectedPopulation} 
                        isCustomTimeframeOpen={isCustomTimeframeOpen}
                        handleSetStagedSelection={handleSetStagedSelection} 
                        handlePopulationChange={handlePopulationChange} 
                        resetSelectedPopulation={resetSelectedPopulation}
                        handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen}
                        handleSetComparisonGroup={handleSetComparisonGroup}
                        fetchSurgeons={fetchSurgeons}
                    />}
            </Box>
            {/*Web Bar*/}
            <Box sx={{ display: { xs: 'none', md: 'flex'}, height: '100%', width: '18rem', transform: 'translateY(60px)', flexDirection: 'column', background:'white' }}>
                <List sx={{ display: 'flex', width:'100%', flexDirection: 'column' }} component="nav">
                    <ListItem divider>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Box sx={{display: 'flex'}}>
                                <ListItemText sx={{ alignSelf: 'center', mr: '1rem', ml: '1rem', fontWeight: 600 }} primary={<Typography typography={'accordion'}>Filters</Typography>}/>
                                <ListItemIcon sx={{ alignSelf: 'center'}}>
                                    <TuneIcon style={{ color: 'black', transform: 'rotate(90deg)' }}></TuneIcon>
                                </ListItemIcon>
                            </Box>
                            <ListItemButton sx={{ ...(applyAvailable ? styles.applyButton : styles.applyButtonDisabled) }} onClick={() => { 
                                if (applyAvailable) apply() 
                            }}>Apply</ListItemButton>
                        </Box>
                    </ListItem>
                </List>
                <Content 
                    role_access={role_access}
                    page={page}
                    sitesByState={sitesByState} 
                    surgeons={surgeons}
                    specialtyNicenameMap={specialtyNicenameMap} 
                    appliedTargetSelection={appliedTargetSelection}
                    stagedSite={stagedSite} 
                    stagedSelection={stagedSelection} 
                    selectedPopulation={population.selectedPopulation} 
                    isCustomTimeframeOpen={isCustomTimeframeOpen}
                    handleSetStagedSelection={handleSetStagedSelection} 
                    handlePopulationChange={handlePopulationChange} 
                    resetSelectedPopulation={resetSelectedPopulation}
                    handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen}
                    handleSetComparisonGroup={handleSetComparisonGroup}
                    fetchSurgeons={fetchSurgeons}
                />
            </Box>
            <CustomTimeframeSelector   
                isCustomTimeframeOpen={isCustomTimeframeOpen} 
                handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen} 
                stagedSelection={stagedSelection} 
                handleSetStagedSelection={handleSetStagedSelection}
                maxRangeDates={maxRangeDates}
            />
        </Box>
    )
}

const styles = {
    applyButton: { 
        background: '#098BEF', 
        color: '#FFFFFF', 
        justifyContent:'center', 
        padding: 1.5, 
        maxWidth: '6rem', 
        border: '1px solid #098BEF', 
        borderRadius: '4.8px', 
        '&:hover': { background: '#0D6EFD' }
    },
    applyButtonDisabled: { 
        background: '#098BEF', 
        opacity: .3,
        color: '#FFFFFF', 
        justifyContent:'center', 
        padding: 1.5, 
        maxWidth: '6rem', 
        border: '1px solid black', 
        borderRadius: '4.8px', 
        '&:hover': { background: '#098BEF'},
        cursor: 'default'
    },
};
