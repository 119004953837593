import React, { useEffect } from 'react';
import analytics from '../_api/analytics';
import { AppBar, Box, Toolbar, Button, Menu, IconButton, MenuItem, Popover } from '@mui/material/';
import  MenuIcon from '@mui/icons-material/Menu';
import  ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Flex from './Flex';
import logo from '../_assets/logo-white.png'
import { CardMedia } from '@mui/material';

const defaultMenuItems = [
    { 
        name: 'Home'
    },
    { 
        name: 'Demographics'
    },
    { 
        name: 'Practice Overview', 
        popup: [
            'General Analysis',
            'Procedure Tables'
        ],
        access_locked: {
            global: [
                'Surgeon Overview'
            ],
            personal: []
        }
    },
    { name: 'Risk Analysis' }
];

function Header({ handleLogout, handleNavChange, page, role_access }) {
    const [anchorElMobile, setAnchorElMobile] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuItem, setMenuItem] = React.useState(defaultMenuItems);

    function handleMenu(event) {
        setAnchorElMobile(event.currentTarget);
    };

    function handleClose() {
        setAnchorElMobile(null);
    };

    const handleMouseOver = (event) => {
        setAnchorEl(event.currentTarget);
    };

  return (

    <Box style={{ position: 'fixed', top: 0, alignSelf: 'stretch', zIndex: 100, width: '100%' }}>
        {/*Mobile Header*/}
        <Box sx={{ display: {xs: 'flex', md: 'none'}, width: '100%' }}>
            <AppBar position='sticky'>
                <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={handleMenu} 
                    sx={{ mr: .5 }}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorElMobile}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: -50,
                    horizontal: 0,
                    }}
                    open={Boolean(anchorElMobile)}
                    onClose={handleClose}
                >
                    {[
                        ...menuItem.map(({ name }) => name), 
                        ...menuItem[2].popup,
                        ...menuItem[2].access_locked[role_access]
                    ]
                        .sort((a, b) => a.name < b.name ? -1 : 1)
                        .map((name, i) => {
                        return <MenuItem key={i} onClick={() => handleNavChange(name)}>{name}</MenuItem>
                    })}
                </Menu>
                <CardMedia component='img' image={logo} sx={{maxHeight: 30, objectFit: 'contain'}}></CardMedia>
                <ExitToAppIcon
                    sx={{'&:hover': { cursor: 'pointer'}}}     
                    onClick={handleLogout}
                    size='large'> 
                </ExitToAppIcon>
                </Toolbar>
            </AppBar>
        </Box>

        {/*Larger Header*/}
        <Box sx={{ display: {xs: 'none', md: 'flex'}, flex: 1 }}>
            <AppBar position='static'>
                <Toolbar>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Box sx={{display: 'flex'}}>
                            <MenuItem sx={{'&:hover': { backgroundColor: 'transparent'}}}><CardMedia component='img' image={logo} sx={{height: 30, objectFit: 'contain', maxWidth: 195}}></CardMedia></MenuItem>
                            <MenuItem sx={{typography: 'subtitle1', '&:hover': { backgroundColor: 'transparent', cursor: 'default' }}}>Reporting Portal</MenuItem>
                        </Box>
                        <Box sx={{ display: 'flex'}}>
                            {[
                                ...menuItem 
                            ].map(({ name, popup, access_locked }, i) => {
                                if (popup) {
                                    return (
                                        <Flex style={{ transform: 'translateY(2px)'}} key={i} onMouseEnter={handleMouseOver} onMouseLeave={() => setAnchorEl(null)}>
                                            <MenuItem onClick={() => handleNavChange(name)} key={i} sx={{typography: name === page || popup?.includes(page) ? 'subtitle2' : 'subtitle3', ml: '3%', '&:hover': { backgroundColor: 'transparent' }}}>{name}</MenuItem>
                                            <Menu
                                                style={{ marginLeft: 14, marginTop: 10 }}
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onMouseLeave={() => setAnchorEl(null)}
                                                onClose={() => setAnchorEl(null)}
                                                MenuListProps={{ onMouseLeave: () => setAnchorEl(null) }}
                                            >
                                                {[...popup, ...access_locked[role_access]].map((n, i) => <MenuItem style={{ color: n === page ? '#F2C76D' : null }} key={i} onClick={() => {
                                                    handleNavChange(n);
                                                    setAnchorEl(null);
                                                }}>{n}</MenuItem>)}
                                            </Menu>
                                        </Flex>
                                    );
                                }
                                return <MenuItem 
                                    key={i} 
                                    sx={{typography: name === page ? 'subtitle2' : 'subtitle3', ml: '3%', '&:hover': { backgroundColor: 'transparent' }}}
                                    onClick={() => {
                                        handleNavChange(name)
                                    }}>{name}</MenuItem>
                            })}
                            </Box>
                            <Button onClick={() => {
                                analytics.alteredSession({ type: 'logout' });
                                handleLogout()
                            }} sx={{typography: 'button', textTransform: 'none', display: 'flex', mr: '1%', ml: '5%'}} variant='outlined' color='tertiary'>Logout</Button>
                        </Box>
                </Toolbar>
            </AppBar>
        </Box>
    </Box>
  );
}

export default Header;