import { useEffect, useState } from 'react';
import logo from './_assets/logo-white.png';
import analytics from './_api/analytics';
import api from './_api/api.js';

export default function({ handleSetResetPassword }) {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [errorMessage, setError] = useState(false);
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const params = new URLSearchParams(window.location.search);
        const t = params.get('t');
        if (!t) throw Error('Unauthorized');
        await api.validateResetToken({ token: t });
        setToken(t);
      } catch(err) {
        console.log(err);
        window.location = '/';
        handleSetResetPassword(false);
      }
    }());
  }, []);

  function handleSetPassword(pw) {
    setPassword(pw);
  }
  function handleSetConfirm(pw) {
    setConfirm(pw);
  }

  async function handleChangePassword() {
    try {
      if (password !== confirm) throw Error('passwords do not match');
      if (password.length < 6) throw Error('password is too short');
      await api.changePassword({ password, token });
      analytics.newEvent({ category: 'Password Reset', action: 'Completed reset', label: `Token: ${token}` });
      setSuccess(true);
    } catch(err) {
      setError(err.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
      console.log(err);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={{ padding: '20px' }}>
          <img src={logo} style={{ height: 'auto', width: '80%' }} alt="Logo" />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <div style={{ color: 'white', fontWeight: 500, fontStyle: 'italic', fontSize: '20px' }}>Reporting Portal</div>
            <div style={{ color: 'white', fontWeight: 500, fontStyle: 'italic', fontSize: '14px' }}>Reset Password</div>
          </div>
        </div>

        {!success ? 
          <div style={{ height: '80%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: '20px', fontWeight: 700, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', justifyContent: 'space-between' }}>
            <div>
            Enter your new password
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <label style={styles.inputLable} htmlFor="password">Password</label>
                  <div style={{ marginRight: '20px', color: 'red', opacity: errorMessage ? 1 : 0 }}>{errorMessage}</div>
                </div>
                <input 
                  onChange={({ target }) => handleSetPassword(target.value)}
                  value={password}
                  style={styles.input} 
                  type="password" placeholder="password" name="password" id="password"
                ></input>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <input 
                  onChange={({ target }) => handleSetConfirm(target.value)} 
                  value={confirm}
                  style={styles.input} 
                  type="password" placeholder="confirm password" name="confirm" id="confirm"
                ></input>
              </div>
            </div>
            <button onClick={handleChangePassword} style={{ ...styles.button, marginTop: '35px' }}><span>Change Password</span></button>
          </div> 
          :
          <div style={{ height: '80%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: '20px', fontWeight: 700, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', justifyContent: 'space-between' }}>
            <br/>
            <div>You're all set! Feel free to close this window and login with your new password!</div>
            <br/>
            <div></div>
            <button onClick={() => {
              window.location = '/';
            }} style={{ ...styles.button, marginTop: '35px' }}><span>Go To Login</span></button>
          </div> 
        }
      </div> {/* End Card */}
    </div>
  );
}

const styles = {
  container: {
    zIndex: 3,
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0,
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ebebeb',
  },
  topBlueMargin: { 
    display: 'flex', 
    flexDirection: 'column', 
    height: '100%', 
    borderRadius: '8px', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  card: { 
    display: 'flex',
    flexDirection: 'column', 
    // backgroundColor: '#0367B4',
    background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)', 
    width: '400px', 
    height: '500px',
    boxShadow: '5px 5px 5px grey',
    borderRadius: '8px'
  },
  input: {
    width: '95%',
    height:'5vh',
    fontSize: '16px',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    color: '#6C757D',
    textIndent: '1.5vh'
  },
  inputLable: {
    display: 'block',
    color: '#333333',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    paddingTop: '.5rem',
    paddingBottom: '.5rem'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0367B4',
    color: 'white',
    fontSize: '20px',
    fontWeight: 700,
    padding: '10px 60px',
    borderRadius: '5px',
    margin: '10px 0px',
    cursor: 'pointer'
  }
}