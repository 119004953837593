import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { themeSettings } from './theme';
import App from './App';
import { ThemeProvider } from '@emotion/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={themeSettings}>
        <App />
    </ThemeProvider>
);