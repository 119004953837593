import ReactGA from 'react-ga4';

const { REACT_APP_NODE_ENV, REACT_APP_GOOGLE_ANALYTICS_MID } = process.env;


const analytics = new class Analytics {
  constructor() {
    this.sessionTime = new Date();
    this.pageTime = {
      page: 'Demographics',
      entry_date: new Date()
    };
    this.internalSubstrings = ['kelahealth', 'bpike7'];
  }

  initialize(session) {
    if (REACT_APP_NODE_ENV !== 'production') return;
    if (this.internalSubstrings.some(sub => session.email.includes(sub))) return console.log('Internal user - Aborting GA');
    try {
      ReactGA.initialize([{ trackingId: REACT_APP_GOOGLE_ANALYTICS_MID }]);
      ReactGA.send({ 
        hitType: "pageview", 
        page: window.location.pathname, 
        title: this.pageTime.page,
        user_identifier: session.user_identifier,
        isDevelopment: REACT_APP_NODE_ENV !== 'production'
      });
    } catch(err) {
      console.log('Analytics initialization error: ', err);
    }
  }


  changedPage(page) {
    if (REACT_APP_NODE_ENV !== 'production') return;
    try {
      ReactGA.send({ 
        hitType: "pageview", 
        page: window.location.pathname, 
        title: page,
        isDevelopment: REACT_APP_NODE_ENV !== 'production'
      });
    } catch(err) {
      console.log(err);
    }
  }

  alteredSession({ type, email }) {
    if (REACT_APP_NODE_ENV !== 'production') return;
    try {
      if (type === 'login') this.sessionTime = new Date();
      if (!['login', 'logout'].includes(type)) throw Error('Invalid use of altered session analytics: type');
      this.newEvent({
        category: 'Session',
        action: type === 'login' ? `Logged in` : `Logged out`,
        label: email,
        isDevelopment: REACT_APP_NODE_ENV !== 'production'
      });
    } catch(err) {
      console.log(err);
    }
  }

  newEvent({ category, action, value, label }) {
    if (REACT_APP_NODE_ENV !== 'production') return;
    try {
      ReactGA.event({
        category,
        action,
        value,
        label: typeof label === 'object' ? JSON.stringify(label) : label,
        isDevelopment: REACT_APP_NODE_ENV !== 'production'
      });
    } catch(err) {
      console.log(err);
    }
  }
}

export default analytics;