const serviceLineSurgicalMethodAlias = {
  thoracic: { laparoscopic: 'VATS' }
};

export function surgicalMethodAlias(service_line, surgical_method, override) {
  const alias = serviceLineSurgicalMethodAlias[service_line]?.[surgical_method] || upperCaseFirst(surgical_method);
  return override?.[alias] || alias;
}

export function upperCaseFirst(str) {
  if (!str) return '';
  return  str.charAt(0).toUpperCase() + str.slice(1)
}

export function getDateRange(lastEncounterDate, timeframe, customDateRange) {
  if (timeframe === 'custom') return customDateRange;
  const encounterDate = new Date(lastEncounterDate);
  const prevTimeframeDate = new Date(encounterDate);
  switch (timeframe) {
    case 'prev_month': 
      prevTimeframeDate.setMonth(encounterDate.getMonth() - 1);
      return getMonthRange(prevTimeframeDate);
    case 'prev_quarter':
      const isFirstDayOfQuarter = (encounterDate.getMonth() % 3 === 0) && (encounterDate.getDate() === 1);
      prevTimeframeDate.setMonth(encounterDate.getMonth() - 3);
      return getQuarterRange(prevTimeframeDate);
    case 'prev_year':
      if (encounterDate.getMonth() === 0 && encounterDate.getDate() === 1) {
        prevTimeframeDate.setFullYear(encounterDate.getFullYear() - 1);
      } else {
        prevTimeframeDate.setFullYear(encounterDate.getFullYear());
      }
      return getYearRange(prevTimeframeDate);
    default:
      throw new Error("Invalid timeframe value.");
  }
}

export function readableDateRange({ start_date, end_date }) {
  return `${reconstructTime(start_date)} - ${reconstructTime(end_date)}`
}

function getMonthRange(date) {
  return { 
    start_date: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0], 
    end_date: new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0]
  };
}

function getQuarterRange(date) {
  const quarter = Math.floor((date.getMonth() / 3));
  return { 
    start_date: new Date(date.getFullYear(), quarter * 3, 1).toISOString().split('T')[0], 
    end_date: new Date(date.getFullYear(), (quarter * 3) + 3, 0).toISOString().split('T')[0]
  };
}

function getYearRange(date) {
  return { 
    start_date: new Date(date.getFullYear(), 0, 1).toISOString().split('T')[0], 
    end_date: new Date(date.getFullYear(), 12, 0).toISOString().split('T')[0]
  };
}

function reconstructTime(date) {
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}
