import Plot from 'react-plotly.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardContent, Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Flex from '../_global/Flex';
import Legend from './Legend.js';
import { upperCaseFirst, surgicalMethodAlias } from '../helpers';
import { colorMap } from '../theme.js';


const titleMap = {
    procedure_time_avg: 'Average Procedure Time (mins)',
    los_avg: 'Average Length of Stay (days)',
    readmissions_percentage: 'Percentage of Readmission (%)',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge (%)',
};

const cardTitleMap = {
    procedure_time_avg: 'Average Procedure Time & Throughput',
    los_avg: 'Average Length of Stay & Throughput',
    readmissions_percentage: 'Percentage of Readmission & Throughput',
    non_home_discharge_percentage: 'Percentage of Non-Home Discharge & Throughput',
}

const outcomeNicenameMap = {
    procedure_time_avg: 'Procedure Time Avg',
    los_avg: 'LOS Avg',
    readmissions_percentage: 'Readmission',
    non_home_discharge_percentage: 'Non-Home Discharge',
}


export default function ({ 
    loading, 
    appliedTargetSelection: ats, 
    data, 
    volumes
}) {
    const [chartData, setChartData] = useState(null);
    const [maxRange, setMaxRange] = useState({x: 0, y: 0 });

    useEffect(() => {
        if (!data) return;
        if (ats.comparison_group_uuids.length > 0 && ats.target_type === 'site') return buildMultipleChartComparison({ 
            overview_aggregation: data.overview_aggregation, 
            site_uuid_individual: ats?.target_uuid, 
            outcome: ats?.outcome 
        });
        return buildIndividualChartComparison({ 
            ets: data.ets, 
            outcome: ats?.outcome
        });
    }, [data, ats]);
    
    function buildIndividualChartComparison({ ets, outcome }) {
        if (!ets) return;
        const outcomeModifier = ['readmissions_percentage', 'non_home_discharge_percentage'].includes(outcome) ? 100 : 1;
        const y = outcome === 'procedure_time_avg' ? {
            individual: {
                robotic: (ets.individual.robotic[outcome] * outcomeModifier),
                laparoscopic: (ets.individual.laparoscopic[outcome] * outcomeModifier),
                open: (ets.individual.open[outcome] * outcomeModifier),
                other: (ets.individual.other[outcome] * outcomeModifier),
            },
            population: {
                robotic: (ets.population.robotic[outcome] * outcomeModifier),
                laparoscopic: (ets.population.laparoscopic[outcome] * outcomeModifier),
                open: (ets.population.open[outcome] * outcomeModifier),
                other: (ets.population.other[outcome] * outcomeModifier),
            }
        } : {
            individual: {
                robotic: (ets.individual.robotic?.inpatient[outcome] * outcomeModifier),
                laparoscopic: (ets.individual.laparoscopic?.inpatient[outcome] * outcomeModifier),
                open: (ets.individual.open?.inpatient[outcome] * outcomeModifier),
                other: (ets.individual.other?.inpatient[outcome] * outcomeModifier),
            },
            population: {
                robotic: (ets.population.robotic?.inpatient[outcome] * outcomeModifier),
                laparoscopic: (ets.population.laparoscopic?.inpatient[outcome] * outcomeModifier),
                open: (ets.population.open?.inpatient[outcome] * outcomeModifier),
                other: (ets.population.other?.inpatient[outcome] * outcomeModifier),
            }
        }
        const max = { x: 0, y: 0 };
        Object.values(volumes).forEach(scope => 
            Object.values(scope).forEach(sm => {
                if (sm > max.x) max.x = sm;
            })
        );
        Object.values(y).forEach(scope => 
            Object.values(scope).forEach(sm => {
                if (sm > max.y) max.y = sm;
            })
        );
        setMaxRange(max);
        if (ats?.surgical_method === 'all') {
            setChartData([
                buildChartDot({ x: volumes.individual.robotic,        y: y.individual.robotic,        }, ats?.target_name, outcome, 'Robotic', colorMap.individual.robotic       ),
                buildChartDot({ x: volumes.individual.laparoscopic,   y: y.individual.laparoscopic,   }, ats?.target_name, outcome, surgicalMethodAlias(ats?.service_line, 'laparoscopic'), colorMap.individual.laparoscopic  ),
                buildChartDot({ x: volumes.individual.open,           y: y.individual.open,           }, ats?.target_name, outcome, 'Open', colorMap.individual.open          ),
                buildChartDot({ x: volumes.individual.other,           y: y.individual.other,           }, ats?.target_name, outcome, 'Other', colorMap.individual.other          ),
                buildChartDot({ x: volumes.population.robotic,        y: y.population.robotic,        }, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals', outcome, 'Robotic', colorMap.population.robotic       ),
                buildChartDot({ x: volumes.population.laparoscopic,   y: y.population.laparoscopic,   }, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals', outcome, surgicalMethodAlias(ats.service_line, 'laparoscopic'), colorMap.population.laparoscopic  ),
                buildChartDot({ x: volumes.population.open,           y: y.population.open,           }, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals', outcome, 'Open', colorMap.population.open          ),
                buildChartDot({ x: volumes.population.other,           y: y.population.other,           }, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals', outcome, 'Other', colorMap.population.other          )
            ]);
        } else {
            setChartData([
                buildChartDot({ x: volumes.individual[ats?.surgical_method],    y: y.individual[ats?.surgical_method]      }, ats?.target_name, outcome, surgicalMethodAlias(ats?.service_line, ats?.surgical_method), colorMap.individual[ats?.surgical_method] ),
                buildChartDot({ x: volumes.population[ats?.surgical_method],    y: y.population[ats?.surgical_method]      }, upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals', outcome, surgicalMethodAlias(ats?.service_line, ats?.surgical_method), colorMap.population[ats?.surgical_method] ),
            ]);
        }
    }

    function buildMultipleChartComparison({ overview_aggregation, site_uuid_individual, outcome }) {
        if (!overview_aggregation) return;
        const max = { x: 0, y: 0 };
        const chartData = overview_aggregation.map(({ site_name, site_uuid, values }) => {
            const isTarget = site_uuid === site_uuid_individual;
            return Object.entries(values).map(([sm, { total_encounters, ...valueObj/* length_of_stay_days_avg, procedure_time_avg, readmissions_avg */ }]) => {
                if (ats?.surgical_method !== 'all' && ats?.surgical_method !== sm) return;
                const x = total_encounters;
                if (x > max.x) max.x = x;
                const y = valueObj[outcome];
                if (y > max.y) max.y = y;
                setMaxRange(max);
                const colorSet = isTarget ? colorMap.individual : colorMap.population;
                return buildChartDot({ x, y }, site_name, outcome, surgicalMethodAlias(ats?.service_line, sm), colorSet[sm])
            }).filter(d => !!d);
        }).flat();
        setChartData(chartData);
    }

    function buildChartDot({ x, y }, name, outcome, surgical_method, color) {
        return {
            x: [x],
            y: [y],
            type: 'scatter',
            mode: 'markers',
            cliponaxis: false,
            xaxis: { layer: 'below traces' },
            yaxis: { layer: 'below traces' },
            marker: { size: 15, color },
            name,
            text: [`${name}<br>${surgical_method}<br>Volume: ${x}<br>${outcomeNicenameMap[outcome]}: ${y ? y.toFixed(1) : 0}`],
            hoverinfo: 'text' 
        }
    }

    const webLayout = {   
        cliponaxis: true,
        font: {
            size: 10
        },
        xaxis: {
            range: [0, maxRange.x],
            title: 'Number of cases'
        },
        yaxis: {
            range: [0, maxRange.y],
            title: titleMap[ats?.outcome],
        },
        width: 700,
        height: 400,
        margin: {
            pad: 5
        },
        tickfont: {
            family: 'Roboto',
            size: 10,
        },
        showlegend: false,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
    }

    const mobileLayout = {   
        cliponaxis: true,
        font: {
            size: 9
        },
        xaxis: {
            range: [ 0, maxRange.x + maxRange.x*.05],
            title: 'Number of cases',
        },
        yaxis: {
            range: [ 0, maxRange.y + maxRange.y*.05  ],
            title: titleMap[ats?.outcome],
        },
        width: 700,
        height: 400,
        margin: {
            pad: 2
        },
        tickfont: {
            family: 'Roboto',
            size: 8,
        },
        showlegend: false,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
    }

    return (
        <Card style={styles.card}>
            <CardContent style={{ flexDirection: 'column', margin: '.5vw', overflow: 'hidden' }}>
                <Flex jcsb style={{ flexWrap: 'wrap' }}>
                    <Flex>
                        <div style={{ height: '20px', fontWeight: 'bold' }}>{cardTitleMap[ats?.outcome]}</div>
                        {ats?.outcome === 'procedure_time_avg' && <Box sx={{ zIndex: 100, display: { xs: 'none', md: 'flex', xl: 'flex' }}}><Tooltip title="Procedure time is calculated from first incision to last close." placement="right" style={{ transform: 'translate(10px, -2px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip></Box>}
                    </Flex>
                    {/* <div>{ats?.service_line} {dateString}</div> */}
                </Flex>
                {/* WEB LAYOUT */}
                <Box sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '-70px', justifyContent: 'center' }}>
                    {loading ? <LoadingChart /> : <Plot data={chartData} layout={webLayout} config={{displayModeBar: false}} />}
                </Box>
                {/* MOBILE LAYOUT */}
                <Box sx={{display: { xs: 'flex', md: 'none' }, marginTop: '-70px', marginRight: '-100px', justifyContent: 'center' }}>
                    {loading ? <LoadingChart /> : <Plot data={chartData} layout={mobileLayout} config={{displayModeBar: false}} style={{transform: 'translate(-35px, 0px)'}}/>}
                </Box>
                <Legend loading={loading} volumes={volumes} appliedTargetSelection={ats}></Legend>
            </CardContent>
        </Card>
    )
};

function LoadingChart() {
    return (
        <Flex f={1} alss aic style={{ width: 700, height: 320, marginTop: 80 }}>
            <Flex className={'load-placeholder'} style={{ width: 20, height: 180, marginRight: 10, marginBottom: 20 }} />
            <Flex column aic style={{ width: 600, height: 250 }}>
                <Flex className={'load-placeholder'} f={1} alss/>
                <Flex className={'load-placeholder'} style={{ width: 200, height: 20, marginTop: 10 }} />
            </Flex>
        </Flex>
    );
}

const styles = {
    card: { 
        flex: 1,
        margin: '.5vw', 
        fontSize: '12px', 
        flexWrap: 'wrap', 
        overflowWrap: 'normal',
        minWidth: 650,
        maxWidth: 750    
    },
    circle: {
        width: '11px',
        height: '11px',
        borderRadius: '50%'
    },
    legendText: {
        marginLeft: 3
    }
}