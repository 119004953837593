import axios from 'axios';
import decodeJWT from "jwt-decode";
import { setSession, closeSession } from './sessions.js';
import mockData from './mockData.js';

const { 
  REACT_APP_EXTERNAL_API_URL,
  REACT_APP_AUTHENTICATION_SERVICE_URL
} = process.env;

class Api {
  constructor() {
    this.client_api = axios.create({ baseURL: REACT_APP_EXTERNAL_API_URL });
    this.client_auth = axios.create({ baseURL: REACT_APP_AUTHENTICATION_SERVICE_URL });
    this.client_api.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      return config;
    }, e => Promise.reject(e));
    this.client_api.interceptors.response.use(res => res,
      async res => {
        if (res.response?.status !== 401) return Promise.reject(res);
        const freshToken = await this.refresh();
        res.config.headers.Authorization = `Bearer ${freshToken}`;
        return axios.request(res.config);
    });
    this.client_auth.interceptors.response.use(res => res,
      res => {
        if (res.config?.url?.includes('/login')) return Promise.reject(res);
        console.log('Unauthorized');
        closeSession();
        location.reload();
        return Promise.reject(res);
      })
    
    this.stateSites = [];
  }

  async login({ email, password }) {
    const { data } = await this.client_auth.post('/login', { email, password });
    if (!data.access_token || !data.refresh_token) throw Error('Unauthorized');
    const decodedToken = decodeJWT(data.access_token);
    setSession({
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      organization_identifier: decodedToken.organization_identifier,
      user_identifier: decodedToken.name,
      email,
      portal: 'admin',
      role_access: decodedToken.role_access
    });
  }

  async refresh() {
    const refreshToken = localStorage.getItem('refresh_token');
    const { data } = await this.client_auth.post('/refresh', null, { headers: { Authorization: `Bearer ${refreshToken}`}});
    if (!data.access_token) throw Error('Unauthorized');
    localStorage.setItem('access_token', data.access_token);
    // localStorage.setItem('refresh_token', data.refresh_token);
    return data.access_token;
  }

  async logout() {
    const refresh_token = localStorage.getItem('refresh_token');
    await this.client_auth.post('/logout', null, { headers: { Authorization: `Bearer ${refresh_token}`}});
  }

  async getMaxRangeDates() {
    try {
      const { data } = await this.client_api.get('/rwa/max-date-ranges');
      return data;
    } catch(err) {
      console.log('API error getMaxRangeDates: ', err);
      throw Error(err);
    }
  }

  async getSurgeons() {
    const { data } = await this.client_api.get('/rwa/surgeons');
    return data.sort((a, b) => a < b ? -1 : 1) // alphabetical
  }

  async sendResetToken({ email }) {
    const { data } = await this.client_auth.post('/password-reset/initialize', { email });
    return data;
  }

  async changePassword({ password, token }) {
    const { data } = await this.client_auth.post('/password-reset/complete', { password }, { headers: { Authorization: `Bearer ${token}` }});
    return data;
  }

  async validateResetToken({ token }) {
    const { data } = await this.client_auth.post('/password-reset/validate', null, { headers: { Authorization: `Bearer ${token}` }});
    return data;
  }

  async getOverviewAggregation({ site_uuids, service_line, procedure_identifiers, start_date, end_date }) {
    const { data } = await this.client_api.get('/rwa/overview-aggregations', { params: {
      site_uuids: site_uuids ? site_uuids.join(',') : undefined, 
      service_line,
      procedure_identifiers: procedure_identifiers ? procedure_identifiers.join(',') : undefined, 
      start_date, 
      end_date     
    }});
    return data;
  }

  async getPersonalInfo() {
    const { data } = await this.client_api.get('/rwa/personal-info');
    return data
  }

  async getProcedures() {
    const { data } = await this.client_api.get('/rwa/procedures');
    return data
  }

  async getSitesV2() {
    const { data } = await this.client_api.get('/rwa/sites');
    return data
    .sort((a, b) => {
      if (a.state_region_province === 'IL' && b.state_region_province !== 'IL') {
        return -1; // Move 'IL' to the beginning
      } else if (a.state_region_province !== 'IL' && b.state_region_province === 'IL') {
        return 1; // Move 'IL' to the end
      } else {
        if (a.state_region_province === 'IL' && b.state_region_province === 'IL') {
          if (a.nicename < b.nicename) return -1; // Compare 'nicename' alphabetically for 'IL' items
          if (a.nicename > b.nicename) return 1;
        } else {
          return a.id - b.id; // Sort by 'id' for non-'IL' items
        }
      }
      return 0; // Default case: preserve the original order
    })
      .map((d => {
        return {
          ...d,
          name: d.nicename || d.name
        }
      }));
  }

  async getDemographicsV2({ target_type, service_line, start_date, end_date, procedure_identifiers, ...params }, sites, role_access) {
    const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const { data } = await this.client_api.get('/rwa/demographics', { params: {
      service_line,
      uuid_type: target_type,
      uuids_individual: params.target_uuid,
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      uuids_population: comparison_group_uuids ? comparison_group_uuids.join(',') : undefined,
      procedure_identifiers: procedure_identifiers ? procedure_identifiers.join(',') : undefined, 
      start_date,
      end_date
    }});
    return data;
  }

  async getShapAggregationsV2({ target_type, target_uuid, service_line, procedure_identifiers, start_date, end_date, model_name: modelNameRaw, surgical_method, ...params }, sites, role_access) {
    const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const { data } = await this.client_api.get('/rwa/shap-aggregations', { params: {
      service_line,
      uuid_type: target_type,
      uuids_individual: target_uuid,
      uuids_population: comparison_group_uuids ? comparison_group_uuids.join(',') : undefined,
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      procedure_identifiers: procedure_identifiers ? procedure_identifiers.join(',') : undefined, 
      start_date,
      end_date,
      model_name: surgical_method === 'robotic' ? `${modelNameRaw}_robotics` : modelNameRaw, 
      surgical_method
    }});
    return data;
  }

  async getProcedureOverview(params, sites, role_access) {
    const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const { data } = await this.client_api.get('/rwa/procedure-overview', { params: {
      service_line: params.service_line,
      uuid_type: params.target_type,
      uuids_individual: params.target_uuid,
      uuids_population: comparison_group_uuids ? comparison_group_uuids.join(',') : undefined,
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      start_date: params.start_date,
      end_date: params.end_date
    }});
    return data;
  }

  async getProceduresTopThree(params, sites, role_access) {
    // const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const { data } = await this.client_api.get('/rwa/procedures-top-three', { params: {
      service_line: params.service_line,
      uuid_type: params.target_type,
      uuids: [params.target_uuid].join(','),
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      start_date: params.start_date,
      end_date: params.end_date
    }});
    return data;
  }

  async getProcedureCounts(params,  sites, role_access) {
    const { data } = await this.client_api.get('/rwa/procedure-counts', { params: {
      service_line: params.service_line,
      uuid_type: params.target_type,
      uuids: [params.target_uuid].join(','),
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      start_date: params.start_date,
      end_date: params.end_date
    }});
    return data;
  }

  async getProcedureOutcomesComparison(params, procedure_identifiers, date_ranges, sites, role_access) {
    const { data } = await this.client_api.post('/rwa/procedure-outcomes-comparison',{
      service_line: params.service_line,
      uuid_type: params.target_type,
      uuids: [params.target_uuid],
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      procedure_identifiers: procedure_identifiers,
      date_ranges
    });
    return data;
  }

  async getSurgeonOverview(params, sites = [], role_access) {
    const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const { data } = await this.client_api.get('/rwa/surgeon-overview', { params: {
      service_line: params.service_line,
      uuid_type: params.target_type,
      uuids_individual: params.target_uuid,
      uuids_population: comparison_group_uuids ? comparison_group_uuids.join(',') : undefined,
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      surgical_method: params.surgical_method === 'all' ? undefined : params.surgical_method,
      start_date: params.start_date,
      end_date: params.end_date
    }});
    return data;
  }

  async getOutcomesOverTime(params, sites = [], role_access) {
    const comparison_group_uuids = params.comparison_group_name ? await this.resolveComparisonGroup(params, sites, role_access) : params.comparison_group_uuids; 
    const paramsBody = {
      service_line: params.service_line,
      uuid_type: params.target_type,
      procedure_identifiers: params.procedure_identifiers.join(','),
      encounter_type: params.encounter_type,
      admit_type: params.admit_type,
      surgical_method: params.surgical_method,
      start_date: params.start_date,
      end_date: params.end_date
    };
    if (role_access === 'personal' || params.target_type === 'surgeon' || comparison_group_uuids.length === 0) {
      paramsBody.uuids_individual = params.target_uuid;
      paramsBody.uuids_population = comparison_group_uuids.filter(uuid => uuid !== params.target_uuid).join();
    } else {
      paramsBody.uuids = [params.target_uuid, ...comparison_group_uuids].join(',')
    }
    const { data } = await this.client_api.get('/rwa/outcomes-over-time', { params: paramsBody });
    return data;
  }

  async resolveComparisonGroup({ comparison_group_name, surgeon_states, surgeon_sites }, sites, role_access) {
    if (!sites) throw Error('Missing required param: ', sites);
    switch(comparison_group_name) {
      case 'enterprise': return [];
      case 'department': return sites.filter(s => surgeon_sites.some(uuid => s.uuid === uuid)).map(({ uuid }) => uuid);
      case 'state': {
        if (role_access === 'personal') {
          if (this.stateSites.length === 0) {
            const { data } = await this.client_api.get('/rwa/hospitals');
            this.stateSites = data.filter(({ state_region_province }) => state_region_province === surgeon_states[0]);
          }
          return this.stateSites.map(({ uuid }) => uuid);
        }
        return sites.filter(s => surgeon_states.some(state => s.state_region_province === state)).map(({ uuid }) => uuid);
      }
      default: throw Error('unknown comparison group');
    }
  }

  async getSurgeonDepartments({ target_uuid }) {
    const { data } = await this.client_api.get('/rwa/surgeon-departments', { params: { surgeon_uuid: target_uuid } });
    return data;
  }
}

const api = new Api();

class MockApi {
  async sleep(ms = 100) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async login({ email, password }) {
    if (!email || !password) throw Error('Unauthorized');
    setSession({
      access_token: 'fake-token-access',
      refresh_token: 'fake-token-refresh',
      organization_identifier: 'demo',
      user_identifier: email.split('@')[0],
      portal: 'admin'
    });
  }
  async logout(){
    //Does nothing
  } 
  async getLatestEncounterDate() {
    await this.sleep();
    return '2021-2-02';
  }
  async getTimeSeries() {
    await this.sleep();
    return mockData.timeseries;
  }
  async getEncounterDateRange() {
    await this.sleep();
    return {
      start_date: '2018-1-02',
      end_date: '2021-12-02'
    }
  }
  async getDemographicsV2() {
    await this.sleep();
    return {};
  }
  async demographics() {
    await this.sleep();
    return {};
  }
  async getSites() {
    await this.sleep();
    return mockData.hospitals;
  }
  async getSurgeons() {
    await this.sleep();
    return mockData.surgeons;
  }
  async getDemographics() {
    await this.sleep();
    return mockData.demographics;
  }
  async getEncounterTimeStatistics() {
    await this.sleep();
    return mockData.encounterTimeStatistics;
  }
  async getDischargeDisposition() {
    await this.sleep();
    return mockData.dischargeDisposition;
  }
  async getComorbidities() {
    await this.sleep();
    return mockData.comorbidities;
  }
  async getContributingRisk() {
    return mockData.contributing_risk;
  }
  async getOverviewAggregation({ organization_id, site_uuids, service_line, start_date, end_date }) {
    return mockData.overview_aggregation;
  }
  async sendResetToken({ email }) {
    return { message: 'sent' };
  }
  async changePassword({ password }) {
    return { message: 'ok' };
  }
  async validateResetToken({ token }) {
    return { message: 'ok' };
  }
}

export default /* REACT_APP_USE_MOCK_DATA === 'true' ? new MockApi() : */api;

const mock = new MockApi();
