import SquareIcon from '@mui/icons-material/Square';
import Flex from '../_global/Flex';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

function Legend({ surgical_method }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Flex column>
                <Flex row aic style={{ margin: 5 }}>
                    <SquareIcon style={{color: '#FF9A13', fontSize: 20, marginRight: 2 }} />
                    <Typography sx={{ fontSize: 12 }}>Increases Risk (Clinical)</Typography>
                </Flex>
                {surgical_method === 'robotic' ? 
                    <Flex row aic style={{ margin: 5 }}>
                        <SquareIcon style={{color: '#FFD7A1', fontSize: 20, marginRight: 2 }}/>
                        <Typography  sx={{ fontSize: 12 }}>Increases Risk (Robotics)</Typography>
                    </Flex>
                : null}
            </Flex>
            <Flex column>
                <Flex row aic style={{ margin: 5 }}>
                    <SquareIcon style={{color: '#4A8EC3', fontSize: 20, marginRight: 2 }}/>
                    <Typography sx={{ fontSize: 12 }}>Decreases Risk (Clinical)</Typography>
                </Flex>

                {surgical_method === 'robotic' ? 
                    <Flex row aic style={{ margin: 5 }}>
                        <SquareIcon style={{color: '#92CFFF', fontSize: 20, marginRight: 2 }}/>
                        <Typography sx={{ fontSize: 12 }}>Decreases Risk (Robotics)</Typography>
                    </Flex>
                : null}
            </Flex>
    </Box>
    )
}

export default Legend;