import React from 'react';
import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import { Card, Box } from '@mui/material';
import { useState } from 'react';
import Flex from '../_global/Flex.js';

const order = [
  'peripheral vascular disease',
  'ascites',
  'copd',
  'hypertension',
  'congestive heart failure',
  'obesity',
  'diabetes'
];

const aliasMobile = {
  'peripheral vascular disease': 'PVD',
  'congestive heart failure': 'CHF',
  'copd': 'COPD'
}

export default function RadarPlot({ appliedTargetSelection: ats, loading, data }) {
  const [maxWeight, setMaxWeight] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartDataMobile, setChartDataMobile] = useState([]);
  let max = 0
  useEffect(() => {
    const buildChartData = data?.map((hospital) => {
      const name = hospital.site_nicename || hospital.site_name;
      const obj = { 
        name,
        r: [], 
        theta: [],
        type: 'scatterpolar',
        fill: 'none'
      };
      order.map(comorbidity_name => hospital?.weights?.find(({ key }) => key === comorbidity_name))
        .filter(c => !!c)
        .forEach(({ key: comorbidity, value: value }) => {
          obj.theta.push(comorbidity.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
          obj.r.push(value);
        });
      
      // Close the gap by copying the first member again as the last member
      obj.r.push(obj.r[0]);
      obj.theta.push(obj.theta[0]);
      
      if (Math.max(...obj.r) > max) {
        max = Math.max(...obj.r)
      }
      return obj;
    });
    setChartData(buildChartData);
    // Apply abbreviations to fields within abbreviation map for mobile view
    setChartDataMobile(buildChartData?.map(siteData => ({
      ...siteData,
      theta: siteData.theta.map(t => aliasMobile[t] ? aliasMobile[t] : t)
    })));
    setMaxWeight(max)
  },[data]);

  const webLayout = {
    width: 700,
    height: 450,
    xaxis: {
      gridwidth: 5,
    },
    polar: {
      angularaxis: {
        rotation: 140,
      },
      radialaxis: {
        angle: 90,
        tickwidth: 1,
        tickcolor: '#000',
        tickfont: {
          size: 10
        },
        nticks: 3,
        visible: true,
        range: [0, maxWeight],
        layer: 'below traces'
      },
      gridshape: 'circular'
    },
    paper_bgcolor:"#FFF3",
    showlegend: true,
    legend: {
      xanchor: 'left',
      yanchor: 'top',
      y: .43,
      x: -.1,
    },
    font: {
      size: 10
    },
    hovermode: false,
    // plot_bgcolor: 'transparent',
    // paper_bgcolor: 'transparent',
  };

  const mobileLayout = {
    width: 400,
    height: 345,
    xaxis: {
      gridwidth: 5,
    },
    polar: {
      angularaxis: {
        rotation: 110,
      },
      radialaxis: {
        angle: 90,
        tickwidth: 1,
        tickcolor: '#000',
        tickfont: {
          size: 10
        },
        nticks: 3,
        visible: true,
        range: [0, maxWeight],
        layer: 'below traces'
      },
      gridshape: 'circular'
    },
    paper_bgcolor:"#FFF3",
    showlegend: true,
    legend: {
      height: 100,
      xanchor: 'left',
      x: -1,
    },
    font: {
      size: 8
    },
    hovermode: false,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
  }
 
  return (
    <>
      {/*MOBILE LAYOUT*/}
        <Card  sx={{display: { xs: 'flex', md: 'none' }}} style={styles.mobileCard}>
          <Flex column f={1} aic jcc style={{ position: 'relative', overflow: 'hidden' }}>
            <div style={{  position: 'absolute', top: '24px', left: '2vw' }}><b>Comorbidity Radar Distribution %</b></div>
            <Flex f={1} aic style={{ transform: 'translate(-30px, 10px)'}}>
              {!loading ? <Plot data={chartDataMobile} layout={mobileLayout} /> : 
                <Flex row f={1} jcsa aic style={{ height: '270px', transform: 'translate(40px, 0px)' }}>
                  <Flex column alss jcc>
                      {[...Array(8)].map((_, i) => <Flex className='load-placeholder' key={i} style={{ height: '12px', width: '120px', marginBottom: '8px' }} />)}
                  </Flex>
                  <Flex className='load-placeholder' style={{ marginLeft: '20px', borderRadius: '100px', height: '150px', width: '150px', transform: 'translate(0px, -10px)' }}/>
                </Flex>
              }
            </Flex>
          </Flex>
        </Card>
        {/*WEB LAYOUT*/}
        <Card sx={{display: { xs: 'none', md: 'flex' }, ...styles.webCard }}>
          <Flex column f={1} aic jcc   style={{ position: 'relative' }}>
            <div style={{  position: 'absolute', top: '24px', left: '1vw' }}><b>Comorbidity Radar Distribution %</b></div>
            {/* <div style={{  position: 'absolute', top: '24px', right: '2vw' }}>{ats.service_line}</div> */}
            <Flex f={1} aic style={{ position: 'absolute', left: '10px', top: '-40px'}}>
              {!loading ? 
                <Plot data={chartData} layout={webLayout} /> : 
                <Flex row f={1} jcc aic style={{ transform: 'translate(70px, 110px)' }}>
                  <Flex column aife jcc>
                      {[...Array(8)].map((_, i) => <Flex className='load-placeholder' key={i} style={{ height: '12px', width: '120px', marginBottom: '8px' }} />)}
                  </Flex>
                  <Flex className='load-placeholder' style={{ marginLeft: '20px', borderRadius: '1000px', height: '275px', width: '275px' }}/>
                </Flex>
              }
            </Flex>
          </Flex>
        </Card>
    </>
  )
};


const styles = {
  webCard: { 
    alignSelf: 'stretch',
    flex: 1, 
    maxHeight: '250px',
    minHeight: '400px',
    minWidth: '580px',
    margin: '.5vw', 
    fontSize: '12px', 
    justifyContent: 'center'
  },
  mobileCard: { 
    alignSelf: 'stretch',
    flex: 1, 
    maxHeight: '250px',
    margin: '.5vw', 
    fontSize: '12px', 
    minWidth: '100vw',
    justifyContent: 'center'
  },
}
