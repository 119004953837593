import { useEffect, useState } from 'react';
import { List, ListItem,Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography, CardMedia, Checkbox, RadioGroup, Radio } from '@mui/material/';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Flex from '../_global/Flex.js';
import targetImg from '../_assets/target.png';
import { stateMap } from '../_api/data.js';
import AccordianItem from './AccordianItem.js';
import Surgeons from './Surgeons.js';
import { ClipLoader } from 'react-spinners'
import InfoIcon from '@mui/icons-material/InfoOutlined';
import api from './../_api/api.js';
import { upperCaseFirst, surgicalMethodAlias } from '../helpers.js';

const dayjs = require('dayjs');

const { REACT_APP_SHOW_PROCEDURE_FILTER = 'true' } = process.env;

const serviceLineSurgicalMethodAlias = {
  laparoscopic: { thoracic: 'VATS' }
};

const encounter_typeMap = {
  'ALL': {
    nicename: 'All',
    /* implicit undefined */
  },
  'INPATIENT_ELECTIVE': {
    nicename: 'Inpatient Elective',
    encounter_type: 'inpatient',
    admit_type: 'elective'
  },
  'INPATIENT_EMERGENCY': {
    nicename: 'Inpatient Emergency',
    encounter_type: 'inpatient',
    admit_type: 'emergency'
  },
  'OUTPATIENT_ELECTIVE': {
    nicename: 'Outpatient Elective',
    encounter_type: 'outpatient',
    admit_type: 'elective'
  },
  'OUTPATIENT_EMERGENCY': {
    nicename: 'Outpatient Emergency',
    encounter_type: 'outpatient',
    admit_type: 'emergency'
  },
}

export default function(props) {
  const [procedures, setProcedures] = useState([]);
  const [proceduresByServiceLine, setProceduresByServiceLine] = useState([]);
  const [accordianExpandedId, setAccordianExpandedId] = useState();

  useEffect(() => {
    (async function() {
      const proceduresByServiceLineRes = await api.getProcedures();
      setProceduresByServiceLine(proceduresByServiceLineRes);
    }());
  }, []);

  useEffect(() => {
    const serviceLineInEffect = props.stagedSelection.service_line || props.appliedTargetSelection.service_line;
    if (!serviceLineInEffect) return;
    setProcedures(proceduresByServiceLine
      .find(({ service_line }) => service_line === serviceLineInEffect)?.procedure_mappings
      .sort((a, b) => a.identifier > b.identifier ? 1 : -1)
    );
  }, [proceduresByServiceLine, props.stagedSelection]);

  function handleAccordianExpansion(clickedExpandedId) {
    if (clickedExpandedId === accordianExpandedId) return setAccordianExpandedId(null);
    setAccordianExpandedId(clickedExpandedId);
  }

  if (props.role_access === 'personal') return <PersonalScope {...props} procedures={procedures} accordianExpandedId={accordianExpandedId} handleAccordianExpansion={handleAccordianExpansion} />
  if (props.role_access === 'global') return <GlobalScope {...props} procedures={procedures} accordianExpandedId={accordianExpandedId} handleAccordianExpansion={handleAccordianExpansion} />
}

function PersonalScope({ 
  page, 
  sitesByState, 
  stagedSelection, 
  appliedTargetSelection: ats,
  procedures,
  isCustomTimeframeOpen, 
  accordianExpandedId,
  handleSetStagedSelection, 
  handleToggleCustomTimeframeOpen,
  handleSetComparisonGroup,
  handleAccordianExpansion
}) {
  const [departmentSites, setDepartmentSites] = useState([]);

  useEffect(() => {
    setDepartmentSites(Object.entries(sitesByState).map(([_, sites]) => sites.map(({ name, nicename }) => nicename || name)).flat());
  }, [sitesByState]);

  return (
    <List>
      <AccordianItem label='My Location'
        expanded={accordianExpandedId === 'MY_LOCATION'}
        handleExpansion={() => handleAccordianExpansion('MY_LOCATION')}
        options={Object.entries(sitesByState)
          .map(([_, sites]) => sites)
          .flat()
          .map(({ nicename, name, uuid }) => ({ 
            value: uuid, 
            nicename: nicename || name,
            selected: (stagedSelection.target_uuid || ats.target_uuid) === uuid 
          }))}
        onClick={(value, nicename) => handleSetStagedSelection({ 
          target_uuid: value,
          target_name: nicename
        })}
        appliedValueText={ats?.target_name}
      />
      <AccordianItem label='Setting'
        expanded={accordianExpandedId === 'SETTING_SITE'}
        handleExpansion={() => handleAccordianExpansion('SETTING_SITE')}      
        options={Object.entries(encounter_typeMap).map(([value, { nicename }]) => ({ 
            value, nicename,
            selected: (stagedSelection.encounter_type_identifier || ats?.encounter_type_identifier) === value
        }))}
        onClick={value => { 
          const { encounter_type, admit_type } = encounter_typeMap[value];
          handleSetStagedSelection({ 
            encounter_type_identifier: value,
            encounter_type,
            admit_type,
            // If outpatient, set dependant filter options appropriately
            // procedure_time is the only filter available for outpatient
            outcome: encounter_type === 'outpatient' ? 'procedure_time_avg' : stagedSelection.outcome || ats?.outcome,
            model_name: encounter_type === 'outpatient' ? 'procedure_time' : stagedSelection.model_name || ats?.model_name,
          });
        }}
        appliedValueText={encounter_typeMap[ats?.encounter_type_identifier]?.nicename}
      />
      {page !== 'Home' && <ComparisonGroup 
        expanded={accordianExpandedId === 'COMPARISON_GROUP'}
        handleExpansion={() => handleAccordianExpansion('COMPARISON_GROUP')}
        comparison_group_name={stagedSelection.comparison_group_name || ats.comparison_group_name} 
        handleSetComparisonGroup={handleSetComparisonGroup}
        departmentTooltipText={`Department (${departmentSites.length}): ${departmentSites.join(', ')}`}
        appliedValueText={upperCaseFirst(ats?.comparison_group_name)}
      />}
      <Procedures
        expanded={accordianExpandedId === 'PROCEDURES'}
        handleExpansion={() => handleAccordianExpansion('PROCEDURES')}
        procedures={procedures}
        stagedSelection={{
          procedure_identifiers: stagedSelection.procedure_identifiers || ats.procedure_identifiers
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedProcedureIdentifiers={ats?.procedure_identifiers}
      />
      {page !== 'Home' && <TimePeriods
        expanded={accordianExpandedId === 'TIME_PERIODS'}
        handleExpansion={() => handleAccordianExpansion('TIME_PERIODS')}
        stagedSelection={{
          timeframe: stagedSelection.timeframe || ats.timeframe,
          start_date: stagedSelection.start_date || ats.start_date,
          end_date: stagedSelection.end_date || ats.end_date
        }}
        isCustomTimeframeOpen={isCustomTimeframeOpen} 
        handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedDateRange={`${dayjs(ats.start_date).format('MM/DD/YYYY')} - ${dayjs(ats.end_date).format('MM/DD/YYYY')}`}
      />}
      {page === 'Risk Analysis' && <RiskAnalysis 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}
        stagedSelection={{
          service_line: stagedSelection.service_line || ats.service_line,
          model_name: stagedSelection.model_name || ats.model_name,
          surgical_method: stagedSelection.surgical_method || ats.surgical_method
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
      {['General Analysis', 'Practice Overview'].includes(page) && <PracticeOverview 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}
        stagedSelection={{
          surgical_method: stagedSelection.surgical_method || ats.surgical_method,
          outcome: stagedSelection.outcome || ats.outcome
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
    </List>
  )
}

function GlobalScope({ ...props }) {
  const [loadingSurgeons, setLoadingSurgeons] = useState(false);
  const [targetType, setTargetType] = useState('site');

  useEffect(() => {
    if (props.appliedTargetSelection.target_type === 'surgeon') handleSetTargetType('surgeon');
  }, []);

  async function handleSetTargetType(value) {
    try {
      if (value === 'surgeon' && props.surgeons?.length === 0) {
        setLoadingSurgeons(true);
        await props.fetchSurgeons();
      }
      setTargetType(value);
      props.handleSetStagedSelection({ target_type: value });
    } catch(err) {
      console.log(err);
    } finally {
      setLoadingSurgeons(false);
    }
  }

  return (
    <Flex column style={{ width: '100%' }}>
      <ListItem divider style={{ position: 'relative' }}>
        <RadioGroup row aria-label="position" name="position" value={targetType} onChange={({ target }) => handleSetTargetType(target.value)}>
          <span style={{ fontSize: 14, fontWeight: 400 }}>
            <Radio value="site" color="primary" />
            By Hospital
          </span>
          <span style={{ fontSize: 14, fontWeight: 400 }}>
            <Radio value="surgeon" color="primary" />
            By Surgeon
          </span>
        </RadioGroup>
        <span style={{ marginLeft: 10, display: loadingSurgeons ? null : 'none' }}><ClipLoader color='grey' size={12} /></span>
      </ListItem>
      {targetType === 'site' ? <GlobalScopeSite {...props}/> : null}
      {targetType === 'surgeon' ? <GlobalScopeSurgeon {...props}/> : null}
    </Flex>
  );
}

function GlobalScopeSite({
  page, 
  sitesByState, 
  stagedSite,
  procedures,
  stagedSelection, 
  appliedTargetSelection: ats,
  isCustomTimeframeOpen, 
  handleSetStagedSelection, 
  specialtyNicenameMap, 
  selectedPopulation, 
  handlePopulationChange, 
  resetSelectedPopulation, 
  handleToggleCustomTimeframeOpen,
  accordianExpandedId,
  handleAccordianExpansion
}) {
  return (
    <List style={{ width: '100%' }}>
      <TargetHospitals
        hideComparison={page === 'Home'}
        label='Hospitals'
        expanded={accordianExpandedId === 'HOSPITAL'}
        handleExpansion={() => handleAccordianExpansion('HOSPITAL')}
        sitesByState={sitesByState} 
        stagedSite={stagedSite} 
        specialtyNicenameMap={specialtyNicenameMap} 
        selectedPopulation={selectedPopulation} 
        appliedTargetSelection={ats}
        handleSetStagedSelection={handleSetStagedSelection} 
        handlePopulationChange={handlePopulationChange} 
        resetSelectedPopulation={resetSelectedPopulation}
      />
      <AccordianItem label='Setting'
          expanded={accordianExpandedId === 'SETTING_SITE'}
          handleExpansion={() => handleAccordianExpansion('SETTING_SITE')}      
          options={Object.entries(encounter_typeMap).map(([value, { nicename }]) => ({ 
              value, nicename,
              selected: (stagedSelection.encounter_type_identifier || ats?.encounter_type_identifier) === value
          }))}
          onClick={value => { 
            const { encounter_type, admit_type } = encounter_typeMap[value];
            handleSetStagedSelection({ 
              encounter_type_identifier: value,
              encounter_type,
              admit_type,
              // If outpatient, set dependant filter options appropriately
              // procedure_time is the only filter available for outpatient
              outcome: encounter_type === 'outpatient' ? 'procedure_time_avg' : stagedSelection.outcome || ats?.outcome,
              model_name: encounter_type === 'outpatient' ? 'procedure_time' : stagedSelection.model_name || ats?.model_name,
            });
          }}
          appliedValueText={encounter_typeMap[ats?.encounter_type_identifier]?.nicename}
      />
      <AccordianItem label='Specialties'
          expanded={accordianExpandedId === 'SERVICE_LINE'}
          handleExpansion={() => handleAccordianExpansion('SERVICE_LINE')}      
          options={Object.entries(specialtyNicenameMap).map(([value, nicename]) => ({ 
              value, nicename,
              disabled: !stagedSite?.service_lines?.includes(value),
              selected: (stagedSelection.service_line || ats?.service_line) === value
          }))}
          onClick={value => { 
              if (stagedSite?.service_lines?.includes(value)) handleSetStagedSelection({ service_line: value })
          }}
          appliedValueText={specialtyNicenameMap[ats?.service_line]}
      />
      {page !== 'Home' && <Procedures
        expanded={accordianExpandedId === 'PROCEDURE_SITE'}
        handleExpansion={() => handleAccordianExpansion('PROCEDURE_SITE')}      
        procedures={procedures}
        stagedSelection={{
          procedure_identifiers: stagedSelection.procedure_identifiers || ats.procedure_identifiers
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedProcedureIdentifiers={ats?.procedure_identifiers}
      />}
      {page !== 'Home' && <TimePeriods
        expanded={accordianExpandedId === 'TIME_PERIOD_SITE'}
        handleExpansion={() => handleAccordianExpansion('TIME_PERIOD_SITE')}            
        stagedSelection={{
          timeframe: stagedSelection.timeframe || ats.timeframe,
          start_date: stagedSelection.start_date || ats.start_date,
          end_date: stagedSelection.end_date || ats.end_date
        }}
        isCustomTimeframeOpen={isCustomTimeframeOpen} 
        handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedDateRange={`${dayjs(ats.start_date).format('MM/DD/YYYY')} - ${dayjs(ats.end_date).format('MM/DD/YYYY')}`}
      />}
      {page === 'Risk Analysis' && <RiskAnalysis 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}      
        stagedSelection={{
          service_line: stagedSelection.service_line || ats.service_line,
          model_name: stagedSelection.model_name || ats.model_name,
          surgical_method: stagedSelection.surgical_method || ats.surgical_method
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
      {['General Analysis', 'Practice Overview'].includes(page) && <PracticeOverview 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}      
        stagedSelection={{
          surgical_method: stagedSelection.surgical_method || ats.surgical_method,
          outcome: stagedSelection.outcome || ats.outcome
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
      {['Surgeon Overview'].includes(page) &&<AccordianItem label='Approach'
        expanded={accordianExpandedId === 'APPROACH_SO'}
        handleExpansion={() => handleAccordianExpansion('APPROACH_SO')}            
        options={[
          { nicename: 'All', value: undefined },
          { nicename: 'Robotic', value: 'robotic' },
          { nicename: surgicalMethodAlias(stagedSelection?.service_line || ats?.service_line, 'laparoscopic'), value: 'laparoscopic' },
          { nicename: 'Open', value: 'open' },
          { nicename: 'Other', value: 'other' }
        ].map(({ nicename, value }) => ({ 
          value, 
          nicename, 
          selected: (stagedSelection.surgical_method || ats.surgical_method) === value
        }))}
        onClick={value => handleSetStagedSelection({ surgical_method: value })}
        appliedValueText={serviceLineSurgicalMethodAlias?.[ats.surgical_method]?.[ats.service_line] || (ats?.surgical_method ? upperCaseFirst(ats?.surgical_method) : 'All')}
      />}
    </List>
  )
}

function GlobalScopeSurgeon({
  page, 
  sitesByState, 
  surgeons,
  stagedSelection, 
  appliedTargetSelection: ats,
  procedures,
  isCustomTimeframeOpen, 
  handleSetStagedSelection, 
  specialtyNicenameMap, 
  handleToggleCustomTimeframeOpen,
  handleSetComparisonGroup,
  accordianExpandedId,
  handleAccordianExpansion
}) {
  const [departmentSites, setDepartmentSites] = useState([]);

  useEffect(() => {
    if (!stagedSelection.surgeon_uuid) return;
    (async function () {
      const departments = await api.getSurgeonDepartments({ target_uuid: stagedSelection.target_uuid });
      setDepartmentSites(departments.map(({ name }) => name));
    }());
  }, [stagedSelection.surgeon_uuid]);

  return (
    <List style={{ width: '100%' }}>
      <Surgeons
        expanded={accordianExpandedId === 'SURGEON'}
        handleExpansion={() => handleAccordianExpansion('SURGEON')}
        appliedServiceLine={ats?.service_line}      
        surgeon_uuid_selected={stagedSelection.target_uuid || ats.target_uuid}
        sitesByState={sitesByState}
        surgeons={surgeons}
        specialtyNicenameMap={specialtyNicenameMap}
        handleSetStagedSelection={handleSetStagedSelection}
        appliedValueText={ats?.target_name}
        appliedTargetSelection={ats}
      />
      {page !== 'Home' && <ComparisonGroup 
        expanded={accordianExpandedId === 'COMPARISON_GROUP'}
        handleExpansion={() => handleAccordianExpansion('COMPARISON_GROUP')}            
        comparison_group_name={stagedSelection.comparison_group_name || ats.comparison_group_name} 
        handleSetComparisonGroup={handleSetComparisonGroup}
        departmentTooltipText={`Department (${departmentSites.length}): ${departmentSites.join(', ')}`}
        appliedValueText={upperCaseFirst(ats?.comparison_group_name) || 'Other Hospitals'}
      />}
      <AccordianItem label='Setting'
        expanded={accordianExpandedId === 'SETTING_SITE'}
        handleExpansion={() => handleAccordianExpansion('SETTING_SITE')}      
        options={Object.entries(encounter_typeMap).map(([value, { nicename }]) => ({ 
            value, nicename,
            selected: (stagedSelection.encounter_type_identifier || ats?.encounter_type_identifier) === value
        }))}
        onClick={value => {
          const { encounter_type, admit_type } = encounter_typeMap[value];
          handleSetStagedSelection({ 
            encounter_type_identifier: value,
            encounter_type,
            admit_type,
            // If outpatient, set dependant filter options appropriately
            // procedure_time is the only filter available for outpatient
            outcome: encounter_type === 'outpatient' ? 'procedure_time_avg' : stagedSelection.outcome || ats?.outcome,
            model_name: encounter_type === 'outpatient' ? 'procedure_time' : stagedSelection.model_name || ats?.model_name,
          });
        }}
        appliedValueText={encounter_typeMap[ats?.encounter_type_identifier]?.nicename}
      />
      {page !== 'Home' && <Procedures
        expanded={accordianExpandedId === 'PROCEDURE_SURGEON'}
        handleExpansion={() => handleAccordianExpansion('PROCEDURE_SURGEON')}            
        procedures={procedures}
        stagedSelection={{
          procedure_identifiers: stagedSelection.procedure_identifiers || ats.procedure_identifiers
        }}         
        handleSetStagedSelection={handleSetStagedSelection}
        appliedProcedureIdentifiers={ats?.procedure_identifiers}
      />}
      {page !== 'Home' && <TimePeriods 
        expanded={accordianExpandedId === 'TIME_PERIOD_SURGEON'}
        handleExpansion={() => handleAccordianExpansion('TIME_PERIOD_SURGEON')}            
        stagedSelection={{
          timeframe: stagedSelection.timeframe || ats.timeframe,
          start_date: stagedSelection.start_date || ats.start_date,
          end_date: stagedSelection.end_date || ats.end_date
        }}        
        isCustomTimeframeOpen={isCustomTimeframeOpen} 
        handleToggleCustomTimeframeOpen={handleToggleCustomTimeframeOpen} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedDateRange={`${dayjs(ats.start_date).format('MM/DD/YYYY')} - ${dayjs(ats.end_date).format('MM/DD/YYYY')}`}
      />}
      {page === 'Risk Analysis' && <RiskAnalysis 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}      
        stagedSelection={{
          model_name: stagedSelection.model_name || ats.model_name,
          surgical_method: stagedSelection.surgical_method || ats.surgical_method
        }} 
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
      {['General Analysis', 'Practice Overview'].includes(page) && <PracticeOverview 
        accordianExpandedId={accordianExpandedId}
        handleAccordianExpansion={handleAccordianExpansion}      
        stagedSelection={{
          surgical_method: stagedSelection.surgical_method || ats.surgical_method,
          outcome: stagedSelection.outcome || ats.outcome
        }}         
        handleSetStagedSelection={handleSetStagedSelection}
        appliedTargetSelection={ats}
      />}
      {['Surgeon Overview'].includes(page) &&<AccordianItem label='Approach'
        expanded={accordianExpandedId === 'APPROACH_SO_SURGEON'}
        handleExpansion={() => handleAccordianExpansion('APPROACH_SO_SURGEON')}            
        options={[
          { nicename: 'All', value: undefined },
          { nicename: 'Robotic', value: 'robotic' },
          { nicename: surgicalMethodAlias(stagedSelection?.service_line || ats?.service_line, 'laparoscopic'), value: 'laparoscopic' },
          { nicename: 'Open', value: 'open' },
          { nicename: 'Other', value: 'other' }
        ].map(({ nicename, value }) => ({ 
          value, 
          // serviceLineSurgicalMethodAlias - this helps convert "laparoscopic" to "VATS" for thoracic
          nicename, 
          selected: (stagedSelection.surgical_method || ats.surgical_method) === value
        }))}
        onClick={value => handleSetStagedSelection({ surgical_method: value })}
        appliedValueText={serviceLineSurgicalMethodAlias?.[ats.surgical_method]?.[ats.surgeon_service_line] || (upperCaseFirst(ats?.surgical_method) || 'All')}
      />}
    </List>
  )
}

function TimePeriods({ expanded, handleExpansion, stagedSelection, isCustomTimeframeOpen, handleToggleCustomTimeframeOpen, handleSetStagedSelection, appliedDateRange }) {
  return (
    <AccordianItem label='Time Periods'
      expanded={expanded}
      handleExpansion={handleExpansion}
      options={[
        { nicename: 'Last month', value: 'prev_month' },
        { nicename: 'Last quarter', value: 'prev_quarter' },
        { nicename: 'Last year', value: 'prev_year' },
        { nicename: 'Custom', value: 'custom' }
      ].map(({ nicename, value }) => ({ 
      value, 
      nicename, 
      selected: value === stagedSelection.timeframe,
      itemSubText: value === 'custom' && stagedSelection.timeframe === 'custom' && stagedSelection.start_date && stagedSelection.end_date && !isCustomTimeframeOpen ? 
          `${dayjs(stagedSelection.start_date).format('MM/DD/YYYY')} - ${dayjs(stagedSelection.end_date).format('MM/DD/YYYY')}` : null
      }))}
      onClick={value => {
          if (value === 'custom') handleToggleCustomTimeframeOpen(true);
          handleSetStagedSelection({ timeframe: value });
      }}
      appliedValueText={appliedDateRange}
    />  
  )
}

function TargetHospitals({ label, expanded, appliedTargetSelection: ats, handleExpansion, sitesByState, stagedSite, handleSetStagedSelection, specialtyNicenameMap, selectedPopulation, handlePopulationChange, resetSelectedPopulation, hideComparison }) {
  useEffect(() => {}, [selectedPopulation]);
  return (
    <ListItem divider sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 2, paddingBottom: 0, marginBottom: 0, paddingTop: 0}} >
      <Accordion expanded={expanded} sx={{ width:'100%' }} disableGutters={true} elevation={0}>
          <AccordionSummary onClick={handleExpansion} expandIcon={<ExpandMoreIcon />}>
              <Typography typography={'accordion'}>{label}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '400px', overflowY: 'auto', transform: 'translateY(-10px)', position: 'relative' }}>
              {!hideComparison && 
                <Flex f={1} column alss style={{ position: 'sticky', top: -10, left: 0, right: 0, backgroundColor: 'white', paddingBottom: '2px', zIndex: 10 }}>
                  <Flex aic jcsb f={1}>
                      <span style={{ fontSize: '10px', color: '#6e6e6e' }}>Comparison group includes ({selectedPopulation.length === 0 ? 'all' : selectedPopulation.length}) hospitals</span>
                      <span onClick={resetSelectedPopulation} style={{ fontSize: '10px', color: '#6e6e6e', textDecoration: 'underline', color: '#0084ff', cursor: 'pointer', padding: '5px' }}>Reset</span>
                  </Flex>
                  <Flex jcsb f={1}>
                      { selectedPopulation.length >= 8 ? <span style={{ fontSize: '10px', color: '#6e6e6e' }}>Maximium specified!</span> : null }
                  </Flex>
                </Flex>
              }
              <Flex alss column>
                  {Object.entries(sitesByState || {}).map(([state, sites], i) => (
                      <Flex column alss key={i}>
                          <span style={{ color: '#6e6e6e', fontStyle: 'italic', padding: '5px', paddingLeft: '0px', marginTop: '10px' }}>{stateMap[state]}</span>
                          <Flex alss column key={i}>
                              {sites?.map((site, i) => {
                                  const selected = stagedSite?.uuid === site.uuid;
                                  return <Flex key={i} alss aic jcsb>
                                      <Flex f={1} alss style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={_ => {
                                        handlePopulationChange(site.uuid, false);
                                        handleSetStagedSelection({ 
                                          target_uuid: site.uuid, 
                                          target_name: site.name,
                                          service_line: Object.keys(specialtyNicenameMap).find(k => site.service_lines.includes(k))
                                        })
                                      }}>
                                          <Typography typography={'accordionsub'} sx={{ padding: 1, color: selected ? '#0084ff' : '#333333', fontSize: selected ? '14px' : '13px', fontWeight: selected ? 700 : 400}}>
                                                  {(site.name || '').length > 18 ? site.name.replace('Hospital', '').replace('Medical Center', '') : site.name}
                                          </Typography>
                                      </Flex>
                                      {!hideComparison && <Flex jcc aic alss style={{ width: '40px' }}>
                                          {selected ? <CardMedia component='img' image={targetImg} sx={{maxHeight: 20, objectFit: 'contain'}}></CardMedia> : 
                                          <Checkbox
                                              checked={selectedPopulation.includes(site.uuid)}
                                              disabled={!selectedPopulation.includes(site.uuid) && selectedPopulation.length >= 8}
                                              onChange={e => handlePopulationChange(site.uuid, e.target.checked)} 
                                              size="small" 
                                              color="default" 
                                              name={site.uuid}
                                          />}
                                      </Flex>}
                                  </Flex>
                              })}
                          </Flex>
                      </Flex>
                  ))}
              </Flex>
          </AccordionDetails>
      </Accordion>
      <div style={{ marginLeft: 15, transform: 'translateY(-6px)', fontSize: 12, fontStyle: 'italic', color: '#6e6e6e', opacity: .7 }}>{ats?.target_name}</div>
    </ListItem>
  );
}

function RiskAnalysis({ accordianExpandedId, stagedSelection, appliedTargetSelection: ats, handleSetStagedSelection, handleAccordianExpansion }) {
  const [models, _] = useState([
    { nicename: '30-day Readmission', model_name: 'thirty_day_readmission' },
    { nicename: 'Non-Home Discharge', model_name: 'non_home_discharge' },
    { nicename: 'Length of Stay', model_name: 'length_of_stay' },
    { nicename: 'Procedure Time', model_name: 'procedure_time' }
  ]);
  const [appliedModelNicename, setAppliedModelNicename] = useState('');

  useEffect(() => {
    const appliedModel = models.find(({ model_name }) => model_name === ats.model_name);
    setAppliedModelNicename(appliedModel?.nicename); 
  }, [ats.model_name]);

  return (
    <>
      <Flex aic style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <span style={{ color: '#6e6e6e', fontSize: '14px', opacity: .8, padding: '5px', paddingBottom: '8px', paddingLeft: '0px', fontStyle: 'italic' }}>Risk Analysis</span>              
      </Flex>
      <AccordianItem label='Outcome'
        expanded={accordianExpandedId === 'MODEL_NAME'}
        handleExpansion={() => handleAccordianExpansion('MODEL_NAME')}
        options={models.map(({ nicename, model_name: value }) => ({ 
          value, 
          nicename, 
          disabled: !value || (ats?.encounter_type === 'outpatient' && ['thirty_day_readmission', 'non_home_discharge', 'length_of_stay'].includes(value)), 
          selected: value === stagedSelection.model_name
        }))}
        onClick={value =>  handleSetStagedSelection({ model_name: value })}
        appliedValueText={appliedModelNicename}
      />
      <AccordianItem label='Approach'
        expanded={accordianExpandedId === 'APPROACH_RA'}
        handleExpansion={() => handleAccordianExpansion('APPROACH_RA')}
        options={[
          { nicename: 'Robotic', value: 'robotic' },
          { nicename: surgicalMethodAlias(stagedSelection?.service_line, 'laparoscopic'), value: 'laparoscopic' },
          { nicename: 'Open', value: 'open' },
          { nicename: 'Other', value: 'other' }
        ].map(({ nicename, value }) => ({ 
          value, 
          nicename, 
          selected: value === stagedSelection.surgical_method
        }))}
        onClick={value => handleSetStagedSelection({ surgical_method: value })}
        appliedValueText={upperCaseFirst(ats?.surgical_method)}
      />
    </>
  )
}

function PracticeOverview({ accordianExpandedId, stagedSelection, appliedTargetSelection: ats, handleSetStagedSelection, handleAccordianExpansion }) {
  const [outcomes, _] = useState([
    { nicename: 'Procedure Time', value: 'procedure_time_avg' },
    { nicename: 'Length of Stay', value: 'los_avg' },
    { nicename: '30-day Readmission', value: 'readmissions_percentage' },
    { nicename: 'Non-Home discharge', value: 'non_home_discharge_percentage' },
  ]);
  const [appliedOutcomeNicename, setAppliedOutcomeNicename] = useState('');

  useEffect(() => {
    const appliedOutcome = outcomes.find(({ value }) => value === ats.outcome);
    setAppliedOutcomeNicename(appliedOutcome.nicename); 
  }, [ats.outcome]);

  return (
    <>
      <Flex aic style={{ paddingLeft: '30px', marginTop: '20px' }}>
        <span style={{ color: '#6e6e6e', fontSize: '14px', opacity: .8, padding: '5px', paddingBottom: '8px', paddingLeft: '0px', fontStyle: 'italic' }}>Practice Overview</span>              
      </Flex>
      <AccordianItem label='Outcome'
        expanded={accordianExpandedId === 'OUTCOME'}
        handleExpansion={() => handleAccordianExpansion('OUTCOME')}      
        options={outcomes.map(({ nicename, value }) => ({ 
          value, 
          nicename, 
          selected: value === stagedSelection.outcome,
          disabled: ats?.encounter_type === 'outpatient' && ['los_avg', 'readmissions_percentage', 'non_home_discharge_percentage'].includes(value)
        }))}
        onClick={value => handleSetStagedSelection({ outcome: value })}
        appliedValueText={appliedOutcomeNicename}
      />
      <AccordianItem label='Approach'
        expanded={accordianExpandedId === 'APPROACH_PO'}
        handleExpansion={() => handleAccordianExpansion('APPROACH_PO')}            
        options={[
          { nicename: 'All', value: 'all' },
          { nicename: 'Robotic', value: 'robotic' },
          { nicename: 'Laparoscopic', value: 'laparoscopic' },
          { nicename: 'Open', value: 'open' },
          { nicename: 'Other', value: 'other' }
        ].map(({ nicename, value }) => ({
          value,
          nicename: serviceLineSurgicalMethodAlias?.[value]?.[
            stagedSelection.target_type === 'site' ? (stagedSelection.service_line || ats.service_line) : (stagedSelection.surgeon_service_line || ats.surgeon_service_line)] || nicename, 
          selected: (stagedSelection.surgical_method || ats.surgical_method) === value  
        }))}
        onClick={value => handleSetStagedSelection({ surgical_method: value })}
        appliedValueText={serviceLineSurgicalMethodAlias?.[stagedSelection.surgical_method === ats.surgical_method ? stagedSelection.surgical_method : ats.surgical_method]?.[ats.service_line] || 
          (ats?.surgical_method ? upperCaseFirst(ats?.surgical_method) : 'All')}
      />    
    </>
  )
}

function ComparisonGroup({ expanded, handleExpansion, comparison_group_name, handleSetComparisonGroup, departmentTooltipText, appliedValueText }) {
  return (
    <AccordianItem label='Comparison Group'
      expanded={expanded}
      handleExpansion={handleExpansion}
      options={[
        { nicename: 'Department', value: 'department' },
        { nicename: 'State', value: 'state' },
        { nicename: 'Enterprise', value: 'enterprise' }
      ].map(({ nicename, value }) => ({ 
        value, 
        nicename, 
        selected: value === comparison_group_name,
        tooltip: value === 'department' && departmentTooltipText ? <Tooltip title={departmentTooltipText} placement="right" style={{ transform: 'translate(0px, 5px)', color: '#666666', fontSize: '18px'}}><InfoIcon /></Tooltip> : undefined,
      }))}
      onClick={value => handleSetComparisonGroup(value)}
      appliedValueText={appliedValueText}
    />
  )
}

function Procedures({ expanded, handleExpansion, stagedSelection = { procedure_identifiers: [] }, procedures = [], handleSetStagedSelection, appliedProcedureIdentifiers = [] }) {
  const [appliedValueText, setAppliedValueText] = useState('');

  useEffect(() => {
    if (appliedProcedureIdentifiers.length === 0) return setAppliedValueText(null);
    const appliedProcedure = procedures.find(({ identifier }) => appliedProcedureIdentifiers[0] === identifier);
    if (!appliedProcedure) return setAppliedValueText(null);
    setAppliedValueText(`${appliedProcedure.nicename} ${appliedProcedureIdentifiers.length > 1 ? `+ ${appliedProcedureIdentifiers.length - 1}` : ''}`);
  }, [appliedProcedureIdentifiers]);

  if (REACT_APP_SHOW_PROCEDURE_FILTER !== 'true') return;
  return (
    <AccordianItem label='Procedures'
      multiselect={true}
      expanded={expanded}
      handleExpansion={handleExpansion}
      noCollapseOnSelection={true}
      listItemStyles={{ fontSize: 12 }}
      preListJSX={<Flex alss jcfe>
        <span style={{ 
          fontSize: '10px', color: '#6e6e6e', textDecoration: 'underline', color: '#0084ff', cursor: 'pointer', padding: '5px',           
          opacity: (stagedSelection.procedure_identifiers || []) .length > 0 ? 1 : 0
        }} onClick={() => handleSetStagedSelection({ procedure_identifiers: [] })}>Reset</span>
      </Flex>}
      options={procedures.map(({ nicename, identifier }) => ({ 
        value: identifier,
        nicename: nicename,
        selected: (stagedSelection.procedure_identifiers || []) .includes(identifier)
      }))}
      emptyStateJSX={<Flex style={{ fontSize: 10, fontStyle: 'italic', color: '#6e6e6e', opacity: .7 }}>No procedures currently available for scoping with current specialty</Flex>}
      onClick={value => {
        const existingIndex = (stagedSelection.procedure_identifiers || []) .indexOf(value);
        if (existingIndex < 0) return handleSetStagedSelection({ procedure_identifiers: [...(stagedSelection.procedure_identifiers || []) , value] });
        const mutatableProcedureIdentifiers = [...(stagedSelection.procedure_identifiers || []) ];
        mutatableProcedureIdentifiers.splice(existingIndex, 1);
        handleSetStagedSelection({ procedure_identifiers: mutatableProcedureIdentifiers });
      }}
      appliedValueText={appliedValueText}
    />
  )
}
