import { createTheme } from '@mui/material/styles'

export const themeSettings = createTheme({
    palette: {
        primary: {
            main: '#0367B4',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FF9A13',
            light: '#F2C76D'
        },
        tertiary: {
            main: '#FFFFFF',
        },
        active: {
            main: '#F2C76D'
        },
        button: {
            main: '#098BEF',
        }
    },
    typography: {
        subtitle1: {
            fontWeight: 400,
            fontSize: 18,
            fontStyle: 'italic',
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: 18,
            color: '#F2C76D'
        },
        subtitle3: {
            fontWeight: 200,
            fontSize: 18,
            color: '#FFFFFF'
        },
        button: {
            fontWeight: 400,
            fontSize: 18,
        },
        accordion: {
            fontWeight: 500,
            color: '#333333',
            fontSize: 14,
        },
        accordionsub: {
            fontWeight: 400,
            color: '#333333',
            fontSize: 14,
        },
        accordiontitle: {
            fontWeight: 500,
            color: '#707070',
            fontSize: 14,
        },
    }
});

export const colorMap = {
    individual: {
        robotic:        '#C34A84',
        laparoscopic:   '#DB86AA',
        open:           '#EFBFD1',
        other:          '#DBC3CC'
    },
    population: {
        robotic:        '#003F5C',
        laparoscopic:   '#668EAA',
        open:           '#BACDD9',
        other:          '#CAE1F0'
    }
};